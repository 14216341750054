import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useGetProductsQuery } from "../../api/ecosternApi";
import { selectCurrentClientId } from "../../store/slices/userSlice";
import { RelatedProductCard } from "./related-product-card";
import { useAppSelector } from "../../store/hooks";

interface IProps {
    relatedProducts: number[];
}

export const RelatedProducts = ({ relatedProducts }: IProps) => {
    const selectedClientId = useAppSelector(selectCurrentClientId);
    const { data = [] } = useGetProductsQuery(selectedClientId ?? skipToken);
    const products = data.filter((p) => relatedProducts.includes(p.id));

    if (!products.length) {
        return <></>;
    }

    return (
        <div className={"px-3 fs-sm"}>
            <h3 className={"product-title fs-sm mt-3"}>
                <FormattedMessage id={"product.related"} />
            </h3>
            <Row className={"justify-content-start g-0 gap-3"}>
                {products.map((rp) => (
                    <RelatedProductCard key={rp.id} product={rp} />
                ))}
            </Row>
        </div>
    );
};
