import { Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

interface IProps {
    isLoading: boolean;
    isError?: boolean;
    children: React.ReactNode;
    className?: string;
    errorMsg?: string;
}

export const LoadingWrapper = ({ isLoading, children, className, isError, errorMsg }: IProps) => {
    if (isLoading) {
        return (
            <div className={`d-flex justify-content-center align-items-center flex-grow-1 py-5 ${className}`}>
                <Spinner animation={"border"} />
            </div>
        );
    }
    if (!isLoading && isError) {
        return (
            <div className={`d-flex justify-content-center align-items-center flex-grow-1 py-5 ${className}`}>
                {errorMsg ?? <FormattedMessage id={"error.errorText"} />}
            </div>
        );
    }
    return <>{children}</>;
};
