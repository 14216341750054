import { getPageItems } from "components/pagination/helpers";
import { PageBottomPagination } from "components/pagination/page-bottom-pagination";
import { IPageableState } from "models/content/pagination/IPageableState";
import { useEffect, useState } from "react";
import { Tabs, Row, Col, Nav } from "react-bootstrap";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";
import { generatePath, Link } from "react-router-dom";
import { routes } from "router";
import { useGetAllClientsQuery, useGetAllUsersQuery } from "api/adminApi";
import { LoadingWrapper } from "components/layout/loading-wrapper";
import { IUserWithAppRole } from "models/api/IUserWithAppRole";
import { APPLICATION_ROLES, ApplicationRole } from "models/api/types/ApplicationRole";
import { searchItems } from "utils/utils";
import Select, { SingleValue } from "react-select";
import { useSearch } from "components/header/navigation/search-bar";

type Tabs = "ALL" | ApplicationRole;
interface SettingsUsersState extends IPageableState {
    viewUsers: IUserWithAppRole[];
    viewRole: Tabs;
    clientId: number | null;
}

export const SettingsUsers = () => {
    const userSearchProps: (keyof IUserWithAppRole)[] = ["fullName", "username", "email", "phone"];
    const { query } = useSearch();
    const clients = useGetAllClientsQuery();

    const [state, setState] = useState<SettingsUsersState>({
        currentPage: 1,
        pageSize: 30,
        totalItems: 0,
        viewUsers: [],
        viewRole: "ALL",
        clientId: null,
    });

    const users = useGetAllUsersQuery(state.clientId ? [state.clientId] : undefined);

    useEffect(() => {
        const data = users.data ?? [];
        let viewUsers = state.viewRole === "ALL" ? data : data.filter((u) => u.applicationRole === state.viewRole);
        viewUsers = searchItems<IUserWithAppRole>(userSearchProps, viewUsers, query);
        setState({ ...state, totalItems: viewUsers.length, viewUsers });
    }, [users.data, state.viewRole, query]);

    const onTabClick = (viewRole: Tabs) => {
        setState({ ...state, viewRole, currentPage: 1 });
    };

    const onPageClick = (pageNumber: number) => {
        setState({ ...state, currentPage: pageNumber });
    };

    const onClientChange = (option: SingleValue<{ value: number; label: string }>) => {
        setState({ ...state, clientId: option?.value ?? null });
    };

    return (
        <>
            <div className="d-flex justify-content-center justify-content-md-between align-items-center pt-lg-3 pb-4 pb-lg-5 mb-lg-3">
                <h4 className="d-none d-md-block fs-base text-light mb-0">Rakenduse kasutajad</h4>
                <div className="d-flex align-items-center flex-nowrap">
                    <span className={"me-2 fs-sm text-light opacity-7"}>Klient:</span>
                    <Select
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? "#ffb4b5" : "#dae1e7",
                                width: "15rem",
                                overflowX: "hidden",
                            }),
                            valueContainer: (baseStyles) => ({
                                ...baseStyles,
                                overflowX: "hidden",
                                height: "2rem",
                            }),
                        }}
                        className={"fs-sm"}
                        options={clients.data?.map((c) => {
                            return { value: c.clientId, label: `${c.clientName} (${c.clientId})` };
                        })}
                        isSearchable={true}
                        isClearable={true}
                        isLoading={clients.isLoading}
                        onChange={onClientChange}
                    />
                </div>
            </div>
            <Nav variant="tabs" activeKey={state.viewRole} className={"overflow-auto-x overflow-hidden-y d-flex flex-nowrap"}>
                <Nav.Item onClick={() => onTabClick("ALL")}>
                    <Nav.Link eventKey={"ALL"}>
                        <FormattedMessage id={"user.role.all"} />
                    </Nav.Link>
                </Nav.Item>
                {Object.values(APPLICATION_ROLES).map((role) => (
                    <Nav.Item key={role} onClick={() => onTabClick(role)}>
                        <Nav.Link eventKey={role}>
                            <FormattedMessage id={`user.role.${role.toLowerCase()}`} />
                        </Nav.Link>
                    </Nav.Item>
                ))}
            </Nav>
            <LoadingWrapper isLoading={users.isLoading} isError={users.isError}>
                <div className={"px-3"}>
                    <Row className={"mb-3 align-items-center fw-bold d-none d-md-flex"}>
                        <Col xs={8} md={10}>
                            <Row>
                                <Col md={3}>Nimi</Col>
                                <Col md={3}>Kasutajanimi</Col>
                                <Col>Roll</Col>
                                <Col>Viimati aktiivne</Col>
                            </Row>
                        </Col>
                        <Col md={1}></Col>
                        <Col md={1}></Col>
                    </Row>
                    {!state.viewUsers.length ? (
                        <div>Kasutajaid ei leitud</div>
                    ) : (
                        getPageItems(state.viewUsers, state.currentPage, state.pageSize).map((user: IUserWithAppRole) => (
                            <Row key={user.webId} className={"d-flex align-items-center py-3"}>
                                <Col xs={8} md={10}>
                                    <Row>
                                        <Col md={3} className={"fw-bold fw-md-normal"}>
                                            {user.fullName}
                                        </Col>
                                        <Col md={3}>{user.username}</Col>
                                        <Col>
                                            <Row>
                                                <Col xs={12} md={6}>
                                                    <FormattedMessage id={`user.role.${user.applicationRole.toLowerCase()}`} />
                                                </Col>
                                                <Col xs={12} md={6} className={"d-flex gap-1 align-items-center flex-wrap"}>
                                                    {user.lastLoginAt ? (
                                                        <div>
                                                            <FormattedDate value={user.lastLoginAt} /> <FormattedTime value={user.lastLoginAt} />
                                                        </div>
                                                    ) : (
                                                        "n/a"
                                                    )}
                                                    {!user.enabled && <span className={"text-muted fs-xs"}>(deaktiveeritud)</span>}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={4} md={2}>
                                    <Row>
                                        <Col md={6}>
                                            <Link
                                                to={routes.settingsUserClients.path}
                                                state={{
                                                    userId: user.webId,
                                                    clientId: state.clientId,
                                                }}
                                            >
                                                Kliendid
                                            </Link>
                                        </Col>
                                        <Col md={6}>
                                            <Link to={generatePath(routes.settingsEditUser.path, { id: user.webId })}>Muuda</Link>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        ))
                    )}
                </div>
                <hr className="my-3" />
                <PageBottomPagination
                    totalItems={state.totalItems}
                    pageSize={state.pageSize}
                    currentPage={state.currentPage}
                    onPageClick={onPageClick}
                    isVisible={true}
                />
            </LoadingWrapper>
        </>
    );
};
