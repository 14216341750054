import { CartzillaIcon, CartzillaIconName } from "../../icons/cartzilla-icon";
import "./no-image.scss";

export type NoImageVariant = "dark" | "light";
interface IProps {
    size?: "sm" | "md" | "lg" | "auto";
    variant?: NoImageVariant;
}

export const NoImage = ({ size = "auto", variant = "dark" }: IProps) => {
    return (
        <div className={`bg-${variant} bg-opacity-25 rounded me-1 d-flex text-center align-items-center img-placeholder-${size}`}>
            <CartzillaIcon icon={CartzillaIconName.Image} className={"d-flex justify-content-center align-items-center w-100 fs-lg"} />
        </div>
    );
};
