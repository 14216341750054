import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CartzillaIcon, CartzillaIconName } from "../../icons/cartzilla-icon";

interface IProps {
    message?: string;
    variant?: "image" | "text";
}

export const ArchivedProductIndicator = ({ message = "", variant = "image" }: IProps) => {
    
    return (
        <OverlayTrigger overlay={
                <Tooltip>
                    Toode on arhiveeritud. Tellimuse sisu ostukorvi lisamise järel tuleb toode ostukorvist eemaldada või asendada enne uue tellimuse kinnitamist.
                </Tooltip>
            }
            >
        <div className={`${variant === "image" && "bg-dark bg-opacity-75 rounded w-100 h-100 position-absolute top-0 left-0 justify-content-center" } d-flex align-items-center text-danger`}>
                <CartzillaIcon icon={CartzillaIconName.Announcement} className={"text-primary"}/>
                {message && <span className={"ms-2"}>&nbsp; {message}</span>}
            </div>
        </OverlayTrigger>
    );
}