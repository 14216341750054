import { Button } from "react-bootstrap";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { Link } from "react-router-dom";
import { routes } from "../../router";
import { useAppSelector } from "../../store/hooks";
import { selectCartProducts, selectCartProductsSum, selectCartTransportSum } from "../../store/slices/cartSlice";
import { useClearCartMutation } from "../../api/ecosternApi";
import { CartzillaIcon, CartzillaIconName } from "../ui/icons/cartzilla-icon";
import { CartWidgetProduct } from "./cart-widget-product";

export const CartWidget = () => {
    const productsSum = useAppSelector(selectCartProductsSum);
    const cartProducts = useAppSelector(selectCartProducts);
    const cartTransportSum = useAppSelector(selectCartTransportSum);
    const [clearCart] = useClearCartMutation();

    return (
        <div className={"p-3 d-flex flex-column"} style={{ minWidth: "20rem", maxHeight: "55vh" }}>
            <div className={"d-flex flex-column flex-shrink-1"} style={{ overflowY: "auto", overflowX: "hidden" }}>
                {cartProducts.map((cp) => (
                    <CartWidgetProduct key={cp.productId} cartProduct={cp} />
                ))}
            </div>
            <div className={"d-flex py-3 justify-content-between align-items-center flex-shrink-0"}>
                <div className={"py-2"}>
                    <div className="fs-sm me-2 d-flex align-items-center">
                        <span className="text-muted">
                            <FormattedMessage id={"shoppingCart.total"} />:
                        </span>
                        <div className={"d-flex flex-wrap"}>
                            <div className="text-accent fs-base ms-1">
                                <FormattedNumber value={productsSum} currency={"EUR"} style={"currency"} />
                            </div>
                        </div>
                    </div>
                    {cartTransportSum > 0 && (
                        <div className={"d-flex align-items-center text-primary fs-sm"}>
                            +&nbsp;
                            <FormattedNumber value={cartTransportSum} currency={"EUR"} style={"currency"} />
                            <CartzillaIcon icon={CartzillaIconName.Truck} className={"ms-2"} />
                        </div>
                    )}
                </div>
                <Button variant={"outline-secondary"} size={"sm"} onClick={() => clearCart()}>
                    <FormattedMessage id={"shoppingCart.clearCart"} />
                </Button>
            </div>
            <Link to={routes.cart.path}>
                <Button size={"sm"} className={"w-100"}>
                    <CartzillaIcon icon={CartzillaIconName.Card} className={"fs-base me-2"} />
                    <FormattedMessage id={"shoppingCart.goToOrder"} />
                </Button>
            </Link>
        </div>
    );
};
