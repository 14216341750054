import { FormattedMessage } from "react-intl";
import { RouteName, routes } from "../../router";
import { Breadcrumbs } from "../breadcrumbs/breadcrumbs";
import { CartzillaIcon, CartzillaIconName } from "components/ui/icons/cartzilla-icon";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

interface IProps {
    routeName: RouteName;
    hasOverlap?: boolean;
    hasLinkToStore?: boolean;
}

export const PageTitleBlock = ({ routeName, hasOverlap, hasLinkToStore }: IProps) => {
    return (
        <div className={`${hasOverlap && "page-title-overlap"} bg-dark pt-4`}>
            <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
                <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                    <Breadcrumbs routeName={routeName}></Breadcrumbs>
                </div>
                <div
                    className={`order-lg-1 pe-lg-4 text-center text-lg-start d-flex flex-row justify-content-${
                        hasLinkToStore ? "between" : "center"
                    } align-items-center`}
                >
                    <h1 className="h3 text-light mb-0">
                        <FormattedMessage id={routes[routeName].labelKey} />
                    </h1>
                    {hasLinkToStore && (
                        <Link to={routes.products.path} className={"d-block d-lg-none"}>
                            <Button variant={"outline-primary"} size={"sm"}>
                                <CartzillaIcon icon={CartzillaIconName.ArrowRight} className={"me-2"} />
                                <FormattedMessage id={"nav.products"} />
                            </Button>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};
