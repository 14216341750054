import { Accept, useDropzone } from "react-dropzone";

import "./file-dropzone.scss";

type Props = {
    onDrop: (acceptedFiles: File[]) => void;
    className?: string;
};

export const FileDropzone = ({ onDrop, className }: Props) => {
    const accept: Accept = {
        "image/jpeg": [],
        "image/png": [],
        "image/gif": [],
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept });

    return (
        <div {...getRootProps()} className={`${className} d-flex flex-column align-items-center justify-content-center h-100 rounded dropzone cursor-pointer`}>
            <input {...getInputProps()} />
            {isDragActive ? (
                <div>Drop file here...</div>
            ) : (
                <>
                    <div>Drag files here</div>
                    <div>Or click to upload</div>
                    <div className={"fs-sm text-muted text-center"}>Only .jpg, .png and .gif types are allowed.</div>
                </>
            )}
        </div>
    );
};
