import { useState, useMemo, useEffect, useRef } from "react";
import { CartzillaIcon, CartzillaIconName } from "components/ui/icons/cartzilla-icon";
import { Row, Col, Form, Button, ButtonGroup, Badge, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useGetAllUsersQuery, useGetAllUserClientsQuery, useAddUserClientMutation, useRemoveUserClientMutation } from "api/adminApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { LoadingWrapper } from "components/layout/loading-wrapper";
import { IUserClient } from "models/api/IUserClient";
import { IUserClientRemove } from "models/api/IUserClientRemove";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList, VariableSizeList } from "react-window";
import { IUserWithAppRole } from "models/api/IUserWithAppRole";
import { LogoIcon } from "components/header/navigation";
import { WithPrefix } from "types/TWithPrefix";
import { searchItems } from "utils/utils";
import { CMSCard, CMSCardRow } from "components/cms-card";
import { SubTitleBlock } from "components/layout/sub-title-block";

type ClientBusinessLocation = IUserClient & { normalizedLocationName: string };

type ClientBusiness = {
    normalizedName: string;
    locations: ClientBusinessLocation[];
};

type ClientGroups = {
    [groupName: string]: ClientBusiness & IUserClient;
};

type ViewGroup = ClientBusiness & IUserClient & { groupName: string };
type ViewGroupLocationSearchProps = keyof ViewGroup | keyof WithPrefix<ClientBusinessLocation, "locations.">;

interface SettingsUserClientsState {
    clientsView: "all" | "client";
    currentUserId: string | undefined;
    viewUsers: IUserWithAppRole[];
    viewGroups: ViewGroup[];
    userSearchQuery: string;
    clientSearchQuery: string;
}

export const SettingsUserClients = () => {
    const DEFAULT_HEADER_ROW_HEIGHT = 64;
    const CLIENT_LOCATION_ROW_HEIGHT = 48;

    const userSearchProps: (keyof IUserWithAppRole)[] = ["fullName", "username", "email", "phone"];
    const clientSearchProps: (keyof ViewGroup | ViewGroupLocationSearchProps)[] = [
        "groupName",
        "normalizedName",
        "clientName",
        "clientId",
        "locations.normalizedLocationName",
        "locations.clientName",
        "locations.clientId",
    ];
    const { userId, clientId } = (useLocation().state as { userId?: string; clientId?: string } | null) ?? {};
    const users = useGetAllUsersQuery();

    const [state, setState] = useState<SettingsUserClientsState>({
        clientsView: clientId ? "client" : "all",
        currentUserId: userId,
        viewUsers: [],
        viewGroups: [],
        userSearchQuery: "",
        clientSearchQuery: clientId?.toString() ?? "",
    });
    const user = users.data?.find((u) => u.webId === state.currentUserId);
    const isEcostern = user?.applicationRole !== "REGULAR_USER";
    const clients = useGetAllUserClientsQuery(state.currentUserId ?? skipToken);
    const [addUserClient] = useAddUserClientMutation();
    const [removeUserClient] = useRemoveUserClientMutation();
    // In order to differentiate between fetching for a different user or updating cache of current user
    const [isClientsLoading, setClientsLoading] = useState(false);

    const collapseGroups = useRef<{ [key: string]: boolean }>({});
    const listRef = useRef<VariableSizeList>(null);

    /**
     * Backend provides a list of IUserClient typed objects
     * where each has a clientName property
     * which is a string in the format of "Client Name / Location Name"
     *
     * For the view, we group the clients by the "Client Name" and
     * display the "Location Name" as a sub-item.
     *
     * E.g. if we have the following clients:
     * - Company Name OÜ
     * - Company Name OÜ / Location 1
     * - Company Name OÜ / Location 2
     *
     * Then the grouped object will look like this:
     * ```
     * {
     *  "Company Name OÜ": {
     *    clientId: 1234,
     *    clientWebId: "uuid",
     *    clientName: "Company Name  OÜ",
     *    locations: [{...}, {...}],            // Locations follow the same structure excluding the `locations` property.
     *    userClientRole: null,                 // or role "ADMIN", "USER" etc.
     *    normalizedName: "Company Name OÜ"
     *  }
     * }
     * ```
     */
    const clientGroups = useMemo(() => {
        const groups: ClientGroups = {};
        [...(clients.data ?? [])]
            .sort(function (a, b) {
                if (a.clientName > b.clientName) {
                    return 1;
                }
                if (a.clientName < b.clientName) {
                    return -1;
                }
                return 0;
            })
            .map((client) => {
                const [normalizedName, normalizedLocationName] = client.clientName
                    .replace(/\s+/g, " ")
                    .split(/\/(.*)/g)
                    .map((s) => s.trim());
                const group = groups[normalizedName];
                if (group) {
                    const newClient = {
                        normalizedLocationName,
                        ...client,
                    };
                    group.locations.push(newClient);
                } else {
                    groups[normalizedName] = {
                        ...client,
                        normalizedName,
                        locations: [],
                    };
                }
            });

        return groups;
    }, [clients.data]);

    const getViewGroups = () => {
        const entries =
            state.clientsView === "all"
                ? Object.entries(clientGroups)
                : Object.entries(clientGroups).filter(([, cg]) => cg.userClientRole || cg.locations.filter((l) => l.userClientRole).length > 0);
        const groupsList = entries.map(([groupName, cg]) => {
            return { ...cg, groupName };
        });
        return searchItems<ViewGroup, ViewGroupLocationSearchProps>(clientSearchProps, groupsList, state.clientSearchQuery);
    };

    const getViewUsers = () => {
        return searchItems<IUserWithAppRole>(userSearchProps, users.data ?? [], state.userSearchQuery);
    };

    useEffect(() => {
        if (state.currentUserId) {
            setClientsLoading(true);
            return;
        }
    }, [state.currentUserId]);

    useEffect(() => {
        if (!clients.isFetching && isClientsLoading) {
            setClientsLoading(false);
        }
    }, [clients.isFetching]);

    useEffect(() => {
        setState({ ...state, viewGroups: getViewGroups(), viewUsers: getViewUsers() });
        listRef.current?.resetAfterIndex(0);
    }, [clientGroups, state.clientsView, state.clientSearchQuery, users.data, state.userSearchQuery]);

    const onUserClick = (userId: string) => {
        if (userId === state.currentUserId) return;
        setState({ ...state, currentUserId: userId });
    };

    const setClientsView = (view: "all" | "client") => {
        setState({
            ...state,
            clientsView: view,
        });
    };

    const onUserSearch = (value: string) => {
        setState({
            ...state,
            userSearchQuery: value,
        });
    };

    const onClientSearch = (value: string) => {
        setState({
            ...state,
            clientSearchQuery: value,
        });
    };

    const toggleClientGroup = (e: React.ChangeEvent<HTMLInputElement>, groupName: string) => {
        if (!state.currentUserId) return;

        const group = clientGroups[groupName];
        const userClients = [
            ...group.locations.map((c) => ({ clientWebId: c.clientWebId, userClientRole: c.userClientRole })),
            { clientWebId: group.clientWebId, userClientRole: group.userClientRole },
        ];
        if (e.target.checked) {
            addUserClient({ userId: state.currentUserId, userClients });
        } else {
            const removeUserClients = userClients.filter((c) => c.userClientRole) as IUserClientRemove[];
            removeUserClient({ userId: state.currentUserId, userClients: removeUserClients });
        }
    };

    const toggleGroup = (groupId: string, index: number) => {
        collapseGroups.current = { ...collapseGroups.current, [groupId]: !collapseGroups.current[groupId] };
        const prevIndex = index === 0 ? 0 : index - 1;
        listRef.current?.resetAfterIndex(prevIndex);
    };

    const toggleClientLocation = (e: React.ChangeEvent<HTMLInputElement>, userClient: IUserClient) => {
        if (!state.currentUserId) return;

        const { clientWebId, userClientRole } = userClient;
        if (e.target.checked) {
            addUserClient({ userId: state.currentUserId, userClients: [{ clientWebId, userClientRole }] });
        } else {
            if (!userClientRole) return;
            removeUserClient({ userId: state.currentUserId, userClients: [{ clientWebId, userClientRole }] });
        }
    };

    /**
     *
     * @param groupName
     * @returns Number of clients. If groupName is not provided, returns total number of clients for current user
     */
    const getCurrentUserClientCount = (groupName?: string) => {
        if (!state.currentUserId) return 0;
        if (groupName) {
            return (clientGroups[groupName].userClientRole ? 1 : 0) + clientGroups[groupName]?.locations.filter((c) => c.userClientRole).length ?? 0;
        }
        return clients.data?.filter((c) => c.userClientRole).length;
    };

    const getClientListRow = (wg: ViewGroup, style: object, index: number) => {
        return (
            <div key={wg.clientId} style={style} className={"overflow-hidden client-row"}>
                <Row className={"d-flex align-items-stretch border-bottom cursor-pointer g-0 header"}>
                    {wg.locations.length > 0 && (
                        <Col xs={1} className={"d-flex align-items-center justify-content-center text-primary border-end"}>
                            <Form.Check
                                disabled={isEcostern}
                                type={"checkbox"}
                                id={`group-${wg.clientId}`}
                                checked={!!wg.userClientRole && wg.locations.every((l) => l.userClientRole)}
                                onChange={(e) => toggleClientGroup(e, wg.groupName)}
                            />
                        </Col>
                    )}
                    <Col xs={wg.locations.length > 0 ? 1 : { span: 1, offset: 1 }} className={"d-flex justify-content-center align-items-center"}>
                        <Form.Check
                            disabled={isEcostern}
                            type={"checkbox"}
                            id={`business-${wg.clientId}`}
                            checked={!!wg.userClientRole}
                            onChange={(e) => toggleClientLocation(e, wg)}
                        />
                    </Col>
                    <Col className={"border-start p-3 d-flex align-items-center gap-3  bg-secondary "} onClick={() => toggleGroup(`all-${wg.clientId}`, index)}>
                        <div className={"flex-grow-1"}>
                            <span>{wg.normalizedName}</span>
                            <span className={"text-muted fs-xs ms-2"}>id: {wg.clientId}</span>
                        </div>
                        {wg.locations.length > 0 && (
                            <>
                                <Badge
                                    pill
                                    bg={"light"}
                                    className={`border ${
                                        !!state.currentUserId && !wg.userClientRole && wg.locations.filter((c) => c.userClientRole).length > 0
                                            ? "text-warning"
                                            : ""
                                    }`}
                                >
                                    {`${getCurrentUserClientCount(wg.groupName)} / ${wg.locations.length + 1}`}
                                </Badge>
                                <CartzillaIcon
                                    icon={collapseGroups.current?.[`all-${wg.clientId}`] ? CartzillaIconName.ArrowUp : CartzillaIconName.ArrowDown}
                                    className={"fs-sm text-muted"}
                                />
                            </>
                        )}
                    </Col>
                </Row>
                {wg.locations.map((l) => (
                    <Row key={l.clientId} className={"d-flex align-items-stretch border-bottom bg-light cursor-pointer g-0 location"}>
                        <Col
                            xs={{
                                span: 1,
                                offset: 1,
                            }}
                            className={"d-flex justify-content-center align-items-center"}
                        >
                            <Form.Check
                                disabled={isEcostern}
                                type={"checkbox"}
                                id={`location-${l.clientId}`}
                                checked={!!l.userClientRole}
                                onChange={(e) => toggleClientLocation(e, l)}
                            />
                        </Col>
                        <Col className={"border-start py-2 px-3 d-flex align-items-center"}>
                            <span>{l.normalizedLocationName}</span> <span className={"text-muted fs-xs ms-2"}>id: {l.clientId}</span>
                        </Col>
                    </Row>
                ))}
            </div>
        );
    };

    const UserRow = ({ index, style }: { index: number; style: object }) => {
        const user = state.viewUsers[index];
        if (!user) return <></>;
        return (
            <CMSCardRow
                className={state.currentUserId === user.webId ? "border-primary bg-light" : "bg-secondary"}
                style={style}
                onClick={() => onUserClick(user.webId)}
            >
                <div className={`d-flex align-items-center gap-2 ${state.currentUserId === user.webId ? "fw-bold" : ""}`}>
                    {user.fullName} {user.applicationRole !== "REGULAR_USER" && <LogoIcon className={"icon"} />}
                </div>
                <div className={"fs-sm text-muted"}>
                    <span className={"me-3"}>{user.username}</span>
                    <span>id: {user.id}</span>
                </div>
            </CMSCardRow>
        );
    };

    const ClientRow = ({ index, style }: { index: number; style: object }) => {
        const group = state.viewGroups[index];
        return getClientListRow(group, style, index);
    };

    const getClientRowHeight = (index: number) => {
        const group = state.viewGroups[index];
        if (!collapseGroups.current?.[`all-${group.clientId}`]) return DEFAULT_HEADER_ROW_HEIGHT;
        return DEFAULT_HEADER_ROW_HEIGHT + group.locations.length * CLIENT_LOCATION_ROW_HEIGHT;
    };

    return (
        <>
            <SubTitleBlock title={"Kasutajad"} />
            <Row className={"d-flex flex-column flex-md-row gap-3 g-0"}>
                <CMSCard
                    xs={12}
                    md={4}
                    header={
                        <>
                            <h5 className={"py-3 m-0"}>Kasutajad</h5>
                            <Form.Control
                                placeholder="Otsi..."
                                size={"sm"}
                                onChange={(e) => onUserSearch(e.currentTarget.value)}
                                value={state.userSearchQuery}
                            />
                        </>
                    }
                >
                    <LoadingWrapper isLoading={users.isLoading} isError={users.isError}>
                        <div className={"bg-secondary border-right flex-grow-1 h-100"}>
                            <AutoSizer className={"overflow-scroll-y"}>
                                {({ height, width }) => (
                                    <FixedSizeList
                                        height={height ?? 0}
                                        width={width ?? 0}
                                        itemSize={DEFAULT_HEADER_ROW_HEIGHT}
                                        itemCount={state.viewUsers.length ?? 0}
                                    >
                                        {UserRow}
                                    </FixedSizeList>
                                )}
                            </AutoSizer>
                        </div>
                    </LoadingWrapper>
                </CMSCard>
                <CMSCard
                    xs={12}
                    md={7}
                    header={
                        <>
                            <div className={"d-flex align-items-center gap-2"}>
                                <h5 className={"m-0 py-3"}>Kliendid</h5>
                                {clients.isFetching && !isClientsLoading && <Spinner style={{ height: "1rem", width: "1rem" }} />}
                            </div>
                            <ButtonGroup aria-label="Basic example" size={"sm"}>
                                <Button variant={`${state.clientsView === "all" ? "secondary" : "outline-secondary"}`} onClick={() => setClientsView("all")}>
                                    Kõik <span className={"d-none d-md-inline"}>kliendid</span>
                                </Button>
                                <Button
                                    variant={`${state.clientsView === "client" ? "secondary" : "outline-secondary"}`}
                                    onClick={() => setClientsView("client")}
                                >
                                    Kasutaja <span className={"d-none d-md-inline"}>kliendid</span>
                                    <Badge bg="primary" className={"ms-1"}>
                                        {getCurrentUserClientCount()}
                                    </Badge>
                                </Button>
                            </ButtonGroup>
                            <Form.Control
                                placeholder="Otsi..."
                                size={"sm"}
                                onChange={(e) => onClientSearch(e.currentTarget.value)}
                                value={state.clientSearchQuery}
                            />
                        </>
                    }
                >
                    <Row className={"d-flex align-items-stretch border-bottom cursor-pointer g-0 fs-sm text-muted"}>
                        <Col xs={1} className={"text-center border-end"}>
                            Kõik
                        </Col>
                        <Col xs={1} className={"text-center"}>
                            Klient
                        </Col>
                        <Col className={"ps-3 border-start"}>Grupp / klient</Col>
                    </Row>
                    <LoadingWrapper isLoading={clients.isFetching && isClientsLoading} isError={clients.isError}>
                        {state.currentUserId ? (
                            <div className={"flex-grow-1"}>
                                <AutoSizer className={"overflow-scroll-y"}>
                                    {({ height, width }) => (
                                        <VariableSizeList
                                            height={height ?? 0}
                                            width={width ?? 0}
                                            itemSize={getClientRowHeight}
                                            itemCount={state.viewGroups.length}
                                            ref={listRef}
                                        >
                                            {ClientRow}
                                        </VariableSizeList>
                                    )}
                                </AutoSizer>
                            </div>
                        ) : (
                            <div className={"d-flex h-100 justify-content-center align-items-center"}>Palun vali kasutaja</div>
                        )}
                    </LoadingWrapper>
                    {isEcostern && (
                        <Row className={"border-top cursor-pointer g-0 fs-sm text-muted ps-2"}>
                            <Col xs={12}>Ecosterni kasutajate õigusi hallatakse Joosepis</Col>
                        </Row>
                    )}
                </CMSCard>
            </Row>
        </>
    );
};
