import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Nav, Badge } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useAppSelector } from "../../../store/hooks";
import { useGetPendingInvoicesQuery } from "../../../api/ecosternApi";
import { selectCurrentClientId } from "../../../store/slices/userSlice";

export const PageNav = () => {
    const selectedClientId = useAppSelector(selectCurrentClientId);
    const { data: pendingInvoices = [] } = useGetPendingInvoicesQuery(selectedClientId ?? skipToken);

    return (
        <Nav className="me-auto" style={{ marginLeft: "-1.25rem" }}>
            <Nav.Link href="/">
                <FormattedMessage id={"nav.home"} />
            </Nav.Link>
            <Nav.Link href="/products">
                <FormattedMessage id={"nav.products"} />
            </Nav.Link>
            <Nav.Link href="/orders">
                <FormattedMessage id={"nav.history"} />
            </Nav.Link>
            {pendingInvoices.length > 0 && (
                <Nav.Link href="/invoices" className={"d-flex align-items-center"}>
                    <FormattedMessage id={"nav.pendingInvoices"} />
                    <Badge bg="primary" className={"ms-1"}>
                        {pendingInvoices.length}
                    </Badge>
                </Nav.Link>
            )}
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
            </NavDropdown> */}
        </Nav>
    );
};
