import { Route, Routes, useLocation } from "react-router-dom";
import { ProtectedRoutes } from "./components/authorization/protected-routes";
import { NotFoundView } from "./views/404.view";
import { SettingsView } from "./views/Settings.view";
import { CartView } from "./views/Cart.view";
import { CheckoutView } from "./views/Checkout.view";
import { ClientsView } from "./views/Clients.view";
import { DevicesView } from "./views/Appliances.view";
import { HomeView } from "./views/Home.view";
import { InvoicesView } from "./views/Invoices.view";
import { OrdersView } from "./views/Orders.view";
import { StoreView } from "./views/Store.view";
import { ClientRoutes } from "./components/authorization/client-routes";
import { useEffect, useRef } from "react";
import { SettingsEditUser, SettingsUserClients, SettingsUserProfile, SettingsUsers } from "views/settings";
import { SettingsTranslations } from "views/settings/SettingsTranslations.outlet";
import { BackOfficeRoutes } from "components/authorization/back-office-routes";
import { SettingsCampaigns } from "views/settings/SettingsCampaigns.outlet";
import { HomePrivateView } from "views/home/HomePrivate.outlet";
import { useSearch } from "components/header/navigation/search-bar";
import { useAnalytics } from "hooks/use-analytics";

const rootPath = process.env.PUBLIC_URL;

export interface RouteInfo {
    path: string;
    labelKey: string;
    parent?: RouteName;
    isRoot?: boolean;
    /* defaults to true */
    hasSearch?: boolean;
}

interface RoutesObject {
    home: RouteInfo;
    products: RouteInfo;
    cart: RouteInfo;
    checkout: RouteInfo;
    appliances: RouteInfo;
    orders: RouteInfo;
    invoices: RouteInfo;
    clients: RouteInfo;
    settings: RouteInfo;
    settingsMyAccount: RouteInfo;
    settingsUsers: RouteInfo;
    settingsEditUser: RouteInfo;
    settingsUserClients: RouteInfo;
    settingsProductTranslations: RouteInfo;
    settingsCampaigns: RouteInfo;
    settingsCategoryTranslations: RouteInfo;
    settingsMessageTranslations: RouteInfo;
}

export type RouteName = keyof RoutesObject;

export const routes: RoutesObject = {
    home: {
        path: `${rootPath}/`,
        labelKey: "nav.home",
        isRoot: true,
        hasSearch: false,
    },
    products: {
        path: `${rootPath}/products`,
        labelKey: "nav.products",
    },
    cart: {
        path: `${rootPath}/cart`,
        labelKey: "nav.cart",
        parent: "products",
        hasSearch: false,
    },
    checkout: {
        path: `${rootPath}/checkout`,
        labelKey: "nav.checkout",
        hasSearch: false,
    },
    appliances: {
        path: `${rootPath}/appliances`,
        labelKey: "nav.appliances",
        hasSearch: false,
    },
    orders: {
        path: `${rootPath}/orders`,
        labelKey: "nav.history",
    },
    invoices: {
        path: `${rootPath}/invoices`,
        labelKey: "nav.pendingInvoices",
    },
    clients: {
        path: `${rootPath}/clients`,
        labelKey: "nav.clients",
    },
    settings: {
        path: `${rootPath}/settings`,
        labelKey: "nav.management",
    },
    settingsMyAccount: {
        path: `${rootPath}/settings/my-account`,
        labelKey: "nav.settings.myAccount",
    },
    settingsUsers: {
        path: `${rootPath}/settings/users`,
        labelKey: "nav.settings.users",
    },
    settingsEditUser: {
        path: `${rootPath}/settings/users/:id`,
        labelKey: "nav.settings.editUser",
    },
    settingsUserClients: {
        path: `${rootPath}/settings/users/clients`,
        labelKey: "nav.settings.userClients",
        hasSearch: false,
    },
    settingsProductTranslations: {
        path: `${rootPath}/settings/products/translations`,
        labelKey: "nav.settings.productTranslations",
        hasSearch: false,
    },
    settingsCategoryTranslations: {
        path: `${rootPath}/settings/categories/translations`,
        labelKey: "nav.settings.categoryTranslations",
        hasSearch: false,
    },
    settingsMessageTranslations: {
        path: `${rootPath}/settings/messages/translations`,
        labelKey: "nav.settings.messageTranslations",
        hasSearch: false,
    },
    settingsCampaigns: {
        path: `${rootPath}/settings/campaigns`,
        labelKey: "nav.settings.campaigns",
        hasSearch: false,
    },
};

export const routeHasSearchByPath: { [key: string]: boolean } = Object.entries(routes).reduce((res, [, value]) => {
    return { ...res, [value.path]: value.hasSearch ?? true };
}, {});

export default function Router() {
    const timingStartRef = useRef<number>(Date.now());
    const { pathname } = useLocation();
    const { query } = useSearch();
    const { track } = useAnalytics();

    useEffect(() => {
        window.scroll({
            top: 0,
            behavior: "auto",
        });
    }, [pathname, query]);

    useEffect(() => {
        const timingEnd = Date.now();
        const timingStart = timingStartRef.current;
        const timingDuration = Math.floor((timingEnd - timingStart) / 1000);
        const timing10Seconds = Math.floor(timingDuration / 10);
        const timingMinutes = Math.floor(timingDuration / 60);
        const data = {
            props: {
                timing_duration: timingMinutes ? `> ${timingMinutes} min` : timing10Seconds ? `> ${timing10Seconds * 10} sec` : `< 10 sec`,
                timing_page: pathname,
            },
        };
        track("pageview", data);
        timingStartRef.current = Date.now();
    }, [pathname]);

    return (
        <Routes>
            <Route path="/" element={<HomeView />}>
                <Route path="/" element={<HomePrivateView />} />
            </Route>
            <Route element={<ProtectedRoutes />}>
                <Route element={<ClientRoutes />}>
                    <Route path={routes.products.path} element={<StoreView />} />
                    <Route path={routes.cart.path} element={<CartView />} />
                    <Route path={routes.checkout.path} element={<CheckoutView />} />
                    <Route path={routes.appliances.path} element={<DevicesView />} />
                    <Route path={routes.orders.path} element={<OrdersView />} />
                    <Route path={routes.invoices.path} element={<InvoicesView />} />
                </Route>
                <Route path={routes.clients.path} element={<ClientsView />} />
                <Route element={<BackOfficeRoutes />}>
                    <Route path={routes.settings.path} element={<SettingsView />}>
                        <Route path={routes.settings.path} element={<SettingsUsers />} />
                        <Route path={routes.settingsUsers.path} element={<SettingsUsers />} />
                        <Route path={routes.settingsEditUser.path} element={<SettingsEditUser />} />
                        <Route path={routes.settingsUserClients.path} element={<SettingsUserClients />} />
                        <Route path={routes.settingsMyAccount.path} element={<SettingsUserProfile />} />
                        <Route path={routes.settingsProductTranslations.path} element={<SettingsTranslations type={"PRODUCT"} />} />
                        <Route path={routes.settingsCategoryTranslations.path} element={<SettingsTranslations type={"CATEGORY"} />} />
                        <Route path={routes.settingsMessageTranslations.path} element={<SettingsTranslations type={"MESSAGE"} />} />
                        <Route path={routes.settingsCampaigns.path} element={<SettingsCampaigns />} />
                    </Route>
                </Route>
            </Route>
            <Route path="*" element={<NotFoundView />} />
        </Routes>
    );
}
