import { useAnalytics } from "hooks/use-analytics";
import { useEffect } from "react";
import { Container } from "react-bootstrap";

export const NotFoundView = () => {
    const { track } = useAnalytics();

    useEffect(() => {
        track("not_found");
    }, []);

    return (
        <Container as={"section"} className={"p-5 d-flex justify-content-center flex-grow-1 align-items-center"}>
            <span>Ei leidnud lehte 😕</span>
        </Container>
    );
};
