import { ICartProduct } from "../../models/api/ICartProduct";
import { useRemoveCartProductMutation } from "../../api/ecosternApi";
import { Spinner } from "react-bootstrap";
import { FormattedNumber } from "react-intl";
import { ProductImage } from "components/ui/images/product-image/product-image";

interface IProps {
    cartProduct: ICartProduct;
}

export const CartWidgetProduct = (props: IProps) => {
    const [removeCartProduct, removeResult] = useRemoveCartProductMutation();

    const product = props.cartProduct.product;

    if (!product) return <></>;
    return (
        <div className={"widget-cart-item py-2 border-bottom"}>
            {removeResult.isUninitialized && (
                <button className="btn-close text-danger" type="button" aria-label="Remove" onClick={() => removeCartProduct(props.cartProduct.productId)}>
                    <span aria-hidden="true">×</span>
                </button>
            )}
            <div className={"d-flex align-items-center"}>
                {!removeResult.isUninitialized && (
                    <span className={"p-1"}>
                        <Spinner animation={"border"} size={"sm"} />
                    </span>
                )}
                <a className={"flex-shrink-0 me-2"} href="#">
                    <ProductImage id={product.id} item={product} size={"md"} useZoom={false} />
                </a>
                <div className={"ps-2"}>
                    <h6 className={"widget-product-title"}>
                        {/* <Link to={`/products/${product.id}`}> */}
                        {product.name}
                        {/* </Link> */}
                    </h6>
                    <div className={"widget-product-meta"}>
                        <span className={"text-accent me-2"}>
                            <FormattedNumber value={product.price} style={"currency"} currency={"EUR"} />
                        </span>
                        <span className={"text-muted"}>{props.cartProduct.quantity}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
