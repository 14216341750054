import React, { KeyboardEvent } from "react";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useLocation, useSearchParams } from "react-router-dom";
import { useForwardedRef } from "hooks/use-forwarded-ref";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectIsMenuOpen, toggleMenu } from "store/slices/uiSlice";
import { debounce } from "utils/utils";
import { CartzillaIcon, CartzillaIconName } from "components/ui/icons/cartzilla-icon";
import { routeHasSearchByPath } from "router";
import { selectIsLoggedIn } from "store/slices/userSlice";

interface IProps {
    className?: string;
    isMobile?: boolean;
}

const QUERY_PARAM_NAME = "q";

export const useSearch = () => {
    const [searchParams] = useSearchParams();
    const [prevPath, setPrevPath] = useState("");
    const [query, setQuery] = useState("");
    const q = searchParams.get(QUERY_PARAM_NAME) ?? "";

    const debouncedUpdate = useCallback(
        debounce((q: string) => {
            setQuery(q);
        }, 500),
        []
    );

    useEffect(() => {
        if (prevPath !== location.pathname) {
            setQuery(q);
            setPrevPath(location.pathname);
        } else if (q !== query) {
            debouncedUpdate(q);
        }
    }, [searchParams, location]);

    return { query };
};

export const Searchable = ({ children }: { children: React.ReactNode }) => {
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const { pathname } = useLocation();

    if (!isLoggedIn || !routeHasSearchByPath[pathname]) return <></>;
    return <>{children}</>;
};

export const SearchBar = React.forwardRef<HTMLInputElement | null, IProps>(({ className = "", isMobile = false }: IProps, ref) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const isMenuOpen = useAppSelector(selectIsMenuOpen);
    const keysDown = useRef<{ [key: string]: boolean }>({});
    const [forwardedRef, forwardedRefCallback] = useForwardedRef<HTMLInputElement>(ref);
    const placeholder = intl.formatMessage({ id: "products.search" }) + (isMobile ? "" : "... [CTRL + S]");
    const [searchParams, setSearchParams] = useSearchParams();
    const query = searchParams.get(QUERY_PARAM_NAME) ?? "";

    useEffect(() => {
        addListeners();

        return () => {
            removeListeners();
        };
    }, []);

    const addListeners = () => {
        window.addEventListener("keydown", keyDownGlobalHandler);
        window.addEventListener("keyup", keyUpGlobalHandler);
    };

    const removeListeners = () => {
        window.removeEventListener("keydown", keyDownGlobalHandler);
        window.removeEventListener("keyup", keyUpGlobalHandler);
    };

    const keyUpGlobalHandler = (e: globalThis.KeyboardEvent) => {
        keysDown.current[e.key] = false;
    };

    const keyDownGlobalHandler = (e: globalThis.KeyboardEvent) => {
        keysDown.current[e.key] = true;
        if (e.key.toLowerCase() === "s" && keysDown.current["Control"]) {
            e.preventDefault();
            forwardedRef?.current?.focus();
            return false;
        }
    };

    const onQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newQuery = event.target.value;
        if (newQuery !== query) {
            const params = new URLSearchParams(searchParams);
            if (newQuery === "") {
                params.delete(QUERY_PARAM_NAME);
            } else {
                params.set(QUERY_PARAM_NAME, newQuery);
            }
            setSearchParams(params, { replace: true });
        }
    };

    const onClearQueryClick = () => {
        const params = new URLSearchParams(searchParams);
        params.delete(QUERY_PARAM_NAME);
        setSearchParams(params, { replace: true });
        forwardedRef?.current?.focus();
    };

    const onSearchKeyDown = (e: KeyboardEvent) => {
        if (e.code.toLowerCase() === "enter" && isMenuOpen) {
            dispatch(toggleMenu());
            forwardedRef.current?.blur();
            window.scroll({
                top: 0,
                behavior: "auto",
            });
        }
    };

    return (
        <>
            <InputGroup className={`${className} search-bar`}>
                <FormControl
                    ref={forwardedRefCallback}
                    placeholder={placeholder}
                    className={"rounded-end"}
                    onChange={onQueryChange}
                    value={query}
                    onKeyDown={onSearchKeyDown}
                />
                {query ? (
                    <CartzillaIcon
                        icon={CartzillaIconName.Close}
                        className={"position-absolute top-50 end-0 translate-middle-y text-muted fs-base me-3"}
                        onClick={onClearQueryClick}
                    />
                ) : (
                    <CartzillaIcon icon={CartzillaIconName.Search} className={"position-absolute top-50 end-0 translate-middle-y text-muted fs-base me-3"} />
                )}
            </InputGroup>
        </>
    );
});

SearchBar.displayName = "SearchBar";
