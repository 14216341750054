import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "./assets/cartzilla/css/theme.min.css";
import "./assets/cartzilla/css/theme.overrides.scss";
import { Provider } from "react-redux";
import { store } from "./store";
import { setupMockDevServer } from "./mock-server";
import { setOnlineStatus } from "./store/slices/uiSlice";

if (process.env.REACT_APP_USE_MOCK_API) {
    console.log("Setting up MirageJs Mock API");
    setupMockDevServer({ environment: process.env.NODE_ENV });
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
const STORAGE_KEY = "ecostern/pwa/scroll";
const config = {
    onUpdate: () => {
        window.sessionStorage.setItem(STORAGE_KEY, window.scrollY.toString() ?? "0");
        window.location.reload();
    },
};
serviceWorkerRegistration.register(config);

window.addEventListener("load", () => {
    const scrollY = window.sessionStorage.getItem(STORAGE_KEY);
    if (scrollY) {
        window.scroll({
            top: parseInt(scrollY),
            behavior: "auto",
        });
    }
    window.sessionStorage.removeItem(STORAGE_KEY);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Browsers implement this property differently.
// In Chrome and Safari, if the browser is not able to connect to a local area network (LAN) or a router, it is offline;
// all other conditions return true. So while you can assume that the browser is offline when it returns a false value,
// you cannot assume that a true value necessarily means that the browser can access the internet.
const updateOnlineStatus = () => {
    store.dispatch(setOnlineStatus(navigator.onLine));
};

window.addEventListener("online", updateOnlineStatus);
window.addEventListener("offline", updateOnlineStatus);
