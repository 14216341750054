import { useAppSelector } from "store/hooks";
import { selectCurrentLocale, selectIsLoggedIn, selectIsUserEcostern } from "store/slices/userSlice";
import merge from "deepmerge";

type AnalyticsEventPayload = {
    pageview: {
        props: {
            timing_page: string;
            timing_duration: string;
        };
    };
    not_found: EmptyPayload;
    search: {
        props: {
            search_query: string;
        };
    };
    filter: {
        props: {
            filter_category_id: string;
            filter_category_name: string;
        };
    };
    sort: {
        props: {
            sort_type: string;
        };
    };
    order: OrderPayload;
    campaign_view: EmptyPayload;
    campaign_click: EmptyPayload;
};

type OrderPayload = {
    revenue: {
        amount: number;
        currency: "EUR";
    };
};

type EmptyPayload = Record<string, never> | undefined;

declare global {
    interface Window {
        plausible?: (event: string, props: Record<string, unknown>) => void | undefined;
        ecostern?: {
            analytics: {
                enabled: boolean;
            };
        };
    }
    interface Navigator {
        standalone?: boolean;
    }
}

const PLAUSIBLE_EVENT: { [key in keyof AnalyticsEventPayload]?: string } = {
    not_found: "404 - Not Found",
    order: "Order",
    campaign_view: "Campaign View",
    campaign_click: "Campaign Click",
    filter: "Filter",
    sort: "Sort",
    search: "Search",
};

const getDisplayMode = () => {
    const mqStandAlone = "(display-mode: standalone)";

    // Returns a boolean indicating whether the browser is running in standalone mode. Available on Apple's iOS Safari only.
    // See more @ https://developer.mozilla.org/en-US/docs/Web/API/Navigator
    const is_iOSPWA = window.navigator.standalone;
    if (is_iOSPWA || window.matchMedia(mqStandAlone).matches) {
        return "standalone";
    }
    return "browser";
};

export const useAnalytics = () => {
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const isEcostern = useAppSelector(selectIsUserEcostern);
    const locale = useAppSelector(selectCurrentLocale);
    const displayMode = getDisplayMode();

    return {
        track: <TEvent extends keyof AnalyticsEventPayload>(
            event: TEvent,
            ...args: AnalyticsEventPayload[TEvent] extends Record<string, unknown> ? [data: AnalyticsEventPayload[TEvent]] : []
        ) => {
            const [data = {}] = args;
            const eventName = PLAUSIBLE_EVENT[event] || event;

            const defaultData = {
                props: {
                    application_name: "pood.ecostern.ee",
                    user_auth_status: isLoggedIn ? "logged in" : "logged out",
                    user_type: isEcostern ? "employee" : "regular",
                    user_locale: locale,
                    application_display_mode: displayMode,
                    application_version: process.env.REACT_APP_GIT_SHA,
                },
            };

            const _data = merge(defaultData, data);

            if (window.ecostern?.analytics) {
                console.log(`[Analytics] ${event}`, _data);
            }

            if (!window.plausible) return;
            window.plausible(eventName, _data);
        },
    };
};
