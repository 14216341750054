import { useScrollDirection } from "../../hooks/use-scroll-direction";
import { selectIsMenuOpen } from "../../store/slices/uiSlice";
import { ProductModal } from "../modals/product-modal";
import { Navigation } from "./navigation";
import { TopBar } from "./top-bar-nav";
import { useAppSelector } from "../../store/hooks";
import { useEffect, useRef, useState } from "react";

export const Header = () => {
    const scrollDirection = useScrollDirection();
    const isMenuOpen = useAppSelector(selectIsMenuOpen);
    const disclaimer = process.env.REACT_APP_TOP_DISCLAIMER;
    const headerRef = useRef<HTMLElement>(null);
    const [isKeepVisible, setIsKeepVisible] = useState(true);

    const onScroll = () => {
        if (!headerRef.current) return;
        if (window.scrollY > headerRef.current.clientHeight * 0.65) {
            setIsKeepVisible(false);
        } else {
            setIsKeepVisible(true);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", onScroll);

        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    return (
        <>
            <header
                ref={headerRef}
                className={`shadow-sm ${scrollDirection} ${isMenuOpen ? "menu-open" : ""} ${isKeepVisible ? "keep-visible" : ""} ${
                    disclaimer ? "disclaimer" : ""
                }`}
            >
                <TopBar />
                <Navigation />
            </header>
            <ProductModal />
        </>
    );
};
