import { useEffect, useState } from "react";
import { Container, Row, Col, Pagination, Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PageTitleBlock } from "../components/layout/page-title-block";
import { getPageItems } from "../components/pagination/helpers";
import { PageBottomPagination } from "../components/pagination/page-bottom-pagination";
import { IClient } from "../models/api/IClient";
import { IPageableState } from "../models/content/pagination/IPageableState";
import { routes } from "../router";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { useGetClientsQuery } from "../api/ecosternApi";
import { selectIsUserEcostern, setSelectedClient } from "../store/slices/userSlice";
import { searchItems } from "../utils/utils";
import { useSearch } from "components/header/navigation/search-bar";
import { useAnalytics } from "hooks/use-analytics";

// A-Z charcode range 65-90
const letters: string[] = [...Array(25)]
    .fill(null)
    .map((_, i) => i + 65)
    .map((a) => String.fromCharCode(a));

type LetterFilterOption = "All" | "#" | "Other" | string;

const alphabet: LetterFilterOption[] = ["#", ...letters];

interface IClientViewState extends IPageableState {
    data: IClient[];
    selectedLetter: LetterFilterOption;
}

export const ClientsView = () => {
    const RETURN_PATH_PARAM = "r";
    const { data: clientsData = [], isLoading } = useGetClientsQuery();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { query } = useSearch();
    const isEcostern = useAppSelector(selectIsUserEcostern);
    const searchProps: (keyof IClient)[] = ["name"];
    const [searchParams] = useSearchParams();
    const { track } = useAnalytics();

    const [state, setState] = useState<IClientViewState>({
        data: [],
        totalItems: 0,
        currentPage: 1,
        pageSize: 2000,
        selectedLetter: "All",
    });

    useEffect(() => {
        let mounted = true;

        let letterData = clientsData;
        if (state.selectedLetter === "#") {
            letterData = letterData.filter((client) => !isNaN(+client.name[0]));
        } else if (state.selectedLetter === "Other") {
            letterData = letterData.filter((client) => !/[A-Z0-9]/.test(client.name[0]));
        } else if (state.selectedLetter !== "All") {
            letterData = letterData.filter((client) => client.name[0].toLocaleUpperCase() === state.selectedLetter);
        }

        const data = searchItems<IClient>(searchProps, letterData, query);

        const totalItems = data.length;
        if (!isLoading && mounted) {
            // When user has only one client
            if (clientsData.length === 1) {
                dispatch(setSelectedClient(clientsData[0]));
                directToStore();
                return;
            }
            setState({ ...state, totalItems, data, currentPage: 1 });
        }

        return () => {
            mounted = false;
        };
    }, [isLoading, query, state.selectedLetter]);

    useEffect(() => {
        if (!query) return;
        track("search", { props: { search_query: query } });
    }, [query]);

    const directToStore = () => {
        const returnUrl = searchParams.get(RETURN_PATH_PARAM);
        if (returnUrl && !returnUrl.startsWith(routes.clients.path)) {
            navigate(returnUrl);
        } else {
            navigate(routes.home.path);
        }
    };

    const onClientClick = (client: IClient) => {
        dispatch(setSelectedClient(client));
        directToStore();
    };

    const onPageClick = (pageNumber: number) => {
        setState({ ...state, currentPage: pageNumber });
    };

    const onSelectLetter = (letter: LetterFilterOption) => {
        setState({ ...state, selectedLetter: letter });
    };

    if (isLoading)
        return (
            <Container as={"section"} className={"p-5 d-flex justify-content-center flex-grow-1 align-items-center"}>
                <Spinner animation={"border"} />
            </Container>
        );

    return (
        <>
            <PageTitleBlock routeName={"clients"} />
            <Container className={"pb-5 mb-2 mt-4 mb-md-4"}>
                <Row>
                    <Col as={"section"}>
                        {isEcostern ? (
                            <Row>
                                <Col as={"section"} className={"d-flex justify-content-center align-items-center w-100 mb-3 position-relative py-3"}>
                                    <div className={"gradient-overlay"}></div>
                                    <Pagination className={"overflow-scroll hide-scrollbar letter-pagination"}>
                                        <Pagination.Item active={state.selectedLetter === "All"} onClick={() => onSelectLetter("All")}>
                                            <FormattedMessage id={"clients.filter.all"} />
                                        </Pagination.Item>
                                        {alphabet.map((letter: string) => (
                                            <Pagination.Item key={letter} active={letter === state.selectedLetter} onClick={() => onSelectLetter(letter)}>
                                                {letter}
                                            </Pagination.Item>
                                        ))}
                                        <Pagination.Item active={state.selectedLetter === "Other"} onClick={() => onSelectLetter("Other")}>
                                            <FormattedMessage id={"clients.filter.other"} />
                                        </Pagination.Item>
                                    </Pagination>
                                    <div className={"gradient-overlay next"}></div>
                                </Col>
                            </Row>
                        ) : null}
                        <Row className={"mx-n2"}>
                            <Col>
                                <Row className={"mb-3 align-items-center fw-bold"}>
                                    <Col xs={1}>#</Col>
                                    <Col>
                                        <FormattedMessage id={"locations.client"} />
                                    </Col>
                                </Row>
                                {getPageItems(
                                    [...state.data].sort(function (a, b) {
                                        if (a.name > b.name) {
                                            return 1;
                                        }
                                        if (a.name < b.name) {
                                            return -1;
                                        }
                                        return 0;
                                    }),
                                    state.currentPage,
                                    state.pageSize
                                ).map((c, i) => (
                                    <Row key={c.id} className={"align-items-center flex-nowrap w-100 my-4 cursor-pointer"} onClick={() => onClientClick(c)}>
                                        <Col xs={1}>{(state.currentPage - 1) * state.pageSize + i + 1}</Col>
                                        <Col>{c.name}</Col>
                                    </Row>
                                ))}
                            </Col>
                        </Row>
                        <hr className="my-3" />
                        <PageBottomPagination
                            totalItems={state.totalItems}
                            pageSize={state.pageSize}
                            currentPage={state.currentPage}
                            onPageClick={onPageClick}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
};
