import { ITranslationItem } from "models/api/ITranslationItem";
import { IUserClientRemove } from "./../models/api/IUserClientRemove";
import { IUserClient } from "models/api/IUserClient";
import { IUserWithAppRole } from "models/api/IUserWithAppRole";
import { ecosternApi } from "./ecosternApi";
import { IUserClientAdd } from "models/api/IUserClientAdd";
import { TranslationType } from "models/api/types/TranslationType";
import { ITranslation } from "models/api/ITranslation";
import { ITranslationProduct } from "models/api/ITranslationProduct";
import { IAddCampaign, ICampaign, IUpdateCampaign } from "models/api/ICampaign";
import { IMinimalClient } from "models/api/IMinimalClient";
import { ITranslationMessage } from "models/api/ITranslationMessage";

const adminApi = ecosternApi.injectEndpoints({
    endpoints: (build) => ({
        getAllUsers: build.query<IUserWithAppRole[], number[] | void>({
            query: (clientIds) => ({
                url: `/users`,
                method: "GET",
                params: {
                    clientIds: clientIds?.join(","),
                },
            }),
            providesTags: (result, error, arg) => (result ? [{ type: "User", clientIds: arg }] : []),
        }),
        putUser: build.mutation<void, IUserWithAppRole>({
            query: (user) => ({
                url: `/users/${user.webId}`,
                method: "PUT",
                body: user,
            }),
            invalidatesTags: ["User"],
        }),
        getAllClients: build.query<IMinimalClient[], void>({
            query: () => ({
                url: `/users/clients`,
                method: "GET",
            }),
            keepUnusedDataFor: 60, // seconds
            providesTags: ["MinimalClient"],
        }),
        getAllUserClients: build.query<IUserClient[], string>({
            query: (userId) => ({
                url: `/users/${userId}/clients`,
                method: "GET",
            }),
            keepUnusedDataFor: 60, // seconds
            providesTags: (result, error, arg) => (result ? [{ type: "UserClient", id: arg }] : []),
        }),
        addUserClient: build.mutation<void, { userId: string } & { userClients: IUserClientAdd[] }>({
            query: ({ userId, userClients }) => ({
                url: `/users/${userId}/clients`,
                method: "POST",
                body: userClients,
            }),
            invalidatesTags: (result, error, arg) => [{ type: "UserClient", id: arg.userId }],
        }),
        removeUserClient: build.mutation<void, { userId: string } & { userClients: IUserClientRemove[] }>({
            query: ({ userId, userClients }) => ({
                url: `/users/${userId}/clients`,
                method: "DELETE",
                body: userClients,
            }),
            invalidatesTags: (result, error, arg) => [{ type: "UserClient", id: arg.userId }],
        }),
        getTranslationProducts: build.query<ITranslationProduct[], void>({
            query: () => ({
                url: `/translations/products`,
                method: "GET",
            }),
            providesTags: ["TranslationProduct"],
        }),
        getTranslationCategories: build.query<ITranslationItem[], void>({
            query: () => ({
                url: `/translations/categories`,
                method: "GET",
            }),
            providesTags: ["TranslationCategory"],
        }),
        getTranslationMessages: build.query<ITranslationMessage[], void>({
            query: () => ({
                url: `/translations/messages`,
                method: "GET",
            }),
            providesTags: ["TranslationMessage"],
        }),
        getTranslation: build.query<ITranslation[], { type: TranslationType; sourceRef: string }>({
            query: ({ type, sourceRef }) => ({
                url: `/translations/${type}/${sourceRef}`,
                method: "GET",
            }),
            providesTags: (result, error, arg) => [{ type: "Translation", ref: arg.sourceRef, translationType: arg.type }],
        }),
        putTranslation: build.mutation<void, ITranslation>({
            query: (translation) => ({
                url: `/translations/${translation.type.toLowerCase()}/${translation.sourceRef}`,
                method: "POST",
                body: translation,
            }),
            invalidatesTags: (result, error, arg) => [{ type: "Translation", ref: arg.sourceRef, translationType: arg.type }],
        }),
        getCampaigns: build.query<ICampaign[], void>({
            query: () => ({
                url: `/campaigns`,
                method: "GET",
            }),
            providesTags: ["Campaign"],
        }),
        postCampaign: build.mutation<void, IAddCampaign>({
            query: (campaign) => ({
                url: `/campaigns`,
                method: "POST",
                body: campaign,
            }),
            invalidatesTags: ["Campaign"],
        }),
        putCampaign: build.mutation<void, IUpdateCampaign>({
            query: (campaign) => ({
                url: `/campaigns/${campaign.id}`,
                method: "PUT",
                body: campaign,
            }),
            invalidatesTags: ["Campaign"],
        }),
        postCampaignImage: build.mutation<void, { campaignId: string; formData: FormData }>({
            query: ({ campaignId, formData }) => ({
                url: `/campaigns/${campaignId}/images/`,
                method: "POST",
                body: formData,
            }),
            invalidatesTags: ["Campaign"],
        }),
        deleteCampaignImage: build.mutation<void, { campaignId: string; imageId: string }>({
            query: ({ campaignId, imageId }) => ({
                url: `/campaigns/${campaignId}/images/${imageId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Campaign"],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetAllClientsQuery,
    useGetAllUsersQuery,
    useGetAllUserClientsQuery,
    useAddUserClientMutation,
    useRemoveUserClientMutation,
    usePutUserMutation,
    useGetTranslationQuery,
    useGetTranslationProductsQuery,
    useGetTranslationCategoriesQuery,
    useGetTranslationMessagesQuery,
    usePutTranslationMutation,
    useGetCampaignsQuery,
    usePostCampaignMutation,
    usePutCampaignMutation,
    usePostCampaignImageMutation,
    useDeleteCampaignImageMutation,
} = adminApi;
