import { Navigate, Outlet, useLocation } from "react-router-dom";
import { selectCurrentClientId } from "../../store/slices/userSlice";
import { useAppSelector } from "../../store/hooks";
import { routes } from "../../router";

export const ClientRoutes = () => {
    const currentClientId = useAppSelector(selectCurrentClientId);
    const location = useLocation();

    const params = new URLSearchParams();
    params.set("r", location.pathname + location.search);

    if (!currentClientId) {
        return <Navigate to={`${routes.clients.path}?${params.toString()}`} />;
    } else {
        return <Outlet />;
    }
};
