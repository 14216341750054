import { Button, Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useAppSelector } from "../../store/hooks";
import { selectCurrentClient } from "../../store/slices/userSlice";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";
import { CartzillaIcon, CartzillaIconName } from "components/ui/icons/cartzilla-icon";
import { useState } from "react";

export const Footer = () => {
    const currentClient = useAppSelector(selectCurrentClient);
    const [isUpdating, setIsUpdating] = useState(false);

    const updateVersion = () => {
        setIsUpdating(true);
        const now = Date.now();
        serviceWorkerRegistration.update({
            onUpdate: () => {
                // Ensure that the animation is visible for at least 1 second
                const timeDiff = Date.now() - now;
                if (timeDiff > 1000) {
                    setIsUpdating(false);
                } else {
                    setTimeout(() => setIsUpdating(false), 1000 - timeDiff);
                }
            },
        });
    };

    return (
        <footer className="bg-dark py-5 pb-md-3">
            <div className="container">
                <div className="row pb-2">
                    <div className="col-md-4">
                        <div className="widget widget-links widget-light pb-2 mb-4">
                            <h3 className="widget-title text-light">Ecostern OÜ</h3>
                            <ul className="widget-list text-light opacity-50">
                                <li className="widget-list-item">
                                    Põikmäe 2/4, Tänassilma <br /> 76406 Saku vald, Harjumaa
                                </li>
                                <li className="widget-list-item">+372 648 0000</li>
                                <li className="widget-list-item">info@ecostern.ee</li>
                            </ul>
                        </div>
                    </div>
                    {currentClient && (
                        <>
                            <div className="col-md-4">
                                <div className="widget widget-links widget-light pb-2 mb-4">
                                    <h3 className="widget-title text-light">
                                        <FormattedMessage id={"contact.contact"} />
                                    </h3>
                                    <ul className="widget-list text-light opacity-50">
                                        <li className="widget-list-item">{currentClient.contactName}</li>
                                        <li className="widget-list-item">{currentClient.contactPhone}</li>
                                        <li className="widget-list-item">{currentClient.contactEmail}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4">
                                {currentClient.name && (
                                    <div className="widget widget-links widget-light pb-2 mb-4">
                                        <h3 className="widget-title text-light">
                                            <FormattedMessage id={"footer.client"} />
                                        </h3>
                                        <ul className="widget-list text-light opacity-50">
                                            <li className="widget-list-item">{currentClient.name}</li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
                <Row className={"pt-2 pb-4 pb-md-0 justify-content-between align-items-center"}>
                    <Col xs={"auto"} className={"fs-xs text-light opacity-50"}>
                        © Kõik õigused kaitstud.
                    </Col>
                    <Col xs={"auto"} className={"fs-xs text-light opacity-50"}>
                        <Button variant={"solid-link"} onClick={updateVersion} size={"sm"} className={"text-light flex align-items-center fs-xs px-2 py-1"}>
                            <span className={"me-2"}>v. {process.env.REACT_APP_GIT_SHA}</span>
                            {isUpdating && <CartzillaIcon icon={CartzillaIconName.Loading} className={"animate-spin"} />}
                        </Button>
                    </Col>
                </Row>
            </div>
        </footer>
    );
};
