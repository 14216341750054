import { Navbar } from "react-bootstrap";
import logo from "./logo-ecostern.svg";
import "./logo.scss";
import { routes } from "../../../../router";
import { Link } from "react-router-dom";
import { LogoIcon } from "./logo-icon";

export const Logo = () => {
    return (
        <Link to={routes.home.path} className={"d-flex"}>
            <Navbar.Brand className={"flex-shrink-0"}>
                <img src={logo} className={"d-none d-lg-block"} />
                <LogoIcon className={"d-display d-lg-none logo-mobile"} />
            </Navbar.Brand>
        </Link>
    );
};
