import { skipToken, SkipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { selectCartProductsCount, selectCartProductsSum } from "store/slices/cartSlice";
import { useGetCartQuery } from "api/ecosternApi";
import { selectCurrentClientId } from "store/slices/userSlice";
import { CartWidget } from "components/cart/cart-widget";
import { CartzillaIcon, CartzillaIconName } from "components/ui/icons/cartzilla-icon";

export const CartNav = () => {
    const clientId = useAppSelector(selectCurrentClientId);
    const location = useLocation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const cartQuery = useGetCartQuery((clientId ?? skipToken) as SkipToken);
    const cartProductsCount = useAppSelector(selectCartProductsCount);
    const cartProductsSum = useAppSelector(selectCartProductsSum);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [openDistance, setScrollDistance] = useState<number>(0);
    const menuAutoCloseDistance = 300;

    useEffect(() => {
        setIsOpen(false);
    }, [location]);

    useEffect(() => {
        if (isOpen) {
            window.addEventListener("scroll", closeMenuOnScrollListener);
            setScrollDistance(window.scrollY);
        }
        return () => {
            window.removeEventListener("scroll", closeMenuOnScrollListener);
        };
    });

    const closeMenuOnScrollListener = () => {
        if (window.scrollY > openDistance + menuAutoCloseDistance) {
            setIsOpen(false);
        }
    };

    if (!clientId) {
        return <></>;
    }

    return (
        <Dropdown className={"navbar-tool"} align={{ lg: "start" }} show={isOpen} onToggle={() => setIsOpen(!isOpen)}>
            <Dropdown.Toggle as={"div"} className={"navbar-tool-icon-box bg-secondary"}>
                {cartProductsCount > 0 && <span className="navbar-tool-label">{cartProductsCount}</span>}
                <CartzillaIcon icon={CartzillaIconName.Cart} className={"navbar-tool-icon w-100"} />
            </Dropdown.Toggle>
            <div className="navbar-tool-text">
                <small>
                    <FormattedMessage id={"nav.cart"} />
                </small>
                <FormattedNumber value={cartProductsSum} style={"currency"} currency={"EUR"} />
            </div>
            <Dropdown.Menu renderOnMount={true}>
                <CartWidget />
            </Dropdown.Menu>
        </Dropdown>
    );
};
