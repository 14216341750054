import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Container, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import { PageTitleBlock } from "../components/layout/page-title-block";
import { routes } from "../router";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { useGetPendingInvoicesQuery } from "../api/ecosternApi";
import { logOut, selectCurrentClientId } from "../store/slices/userSlice";

export const CheckoutView = () => {
    const selectedClientId = useAppSelector(selectCurrentClientId);
    const { data: pendingInvoices = [] } = useGetPendingInvoicesQuery(selectedClientId ?? skipToken);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onLogOutClick = () => {
        dispatch(logOut());
        navigate(routes.home.path);
    };

    return (
        <>
            <PageTitleBlock routeName={"products"} />
            <Container className={"py-5 text-center"}>
                <h4 className={"pb-3"}>
                    <FormattedMessage id={"submit.heading"} />
                </h4>
                {pendingInvoices.length > 0 && (
                    <div className={"mb-2 text-primary"}>
                        <FormattedMessage id={"pendingInvoiceWarning.message"} />
                    </div>
                )}

                <div className={"fs-sm mb-2 mt-3"}>
                    <Link to={routes.products.path} className={"me-1"}>
                        <Button variant={"secondary"} style={{ minWidth: "8rem" }} size="sm">
                            <FormattedMessage id={"nav.backToStore"} />
                        </Button>
                    </Link>
                    <Button variant={"secondary"} onClick={onLogOutClick} className={"ms-1"} style={{ minWidth: "8rem" }} size="sm">
                        <FormattedMessage id={"nav.logout"} />
                    </Button>
                </div>
            </Container>
        </>
    );
};
