import { selectIsLoginModalOpen, toggleLoginModal } from "../../store/slices/uiSlice";
import React, { useState } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { ILoginRequest } from "../../models/api/ILoginRequest";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { useGetTokenMutation, useGetUserInfoQuery } from "../../api/ecosternApi";
import { useLocation, useNavigate } from "react-router-dom";

interface ILoginModalState {
    modalType: "login" | "signup"; // Signup needed?
    formData: {
        userName: string;
        password: string;
        repeatPassword: string;
    };
    isPasswordVisible: boolean;
    doRememberUser: boolean;
    errorMessageKey: "login.error" | "error.errorText" | null;
}

export const LoginModal = () => {
    const [getToken, { isSuccess }] = useGetTokenMutation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data } = useGetUserInfoQuery(undefined, { skip: !isSuccess });
    const isLoginModalOpen = useAppSelector(selectIsLoginModalOpen);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState<ILoginModalState>({
        modalType: "login",
        formData: {
            userName: "",
            password: "",
            repeatPassword: "",
        },
        isPasswordVisible: false,
        doRememberUser: false,
        errorMessageKey: null,
    });

    const onFormInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fieldId = event.currentTarget.id;
        const value = event.currentTarget.value;
        const newFormData = state.formData;

        if (fieldId === "userName") newFormData.userName = value;
        if (fieldId === "password") newFormData.password = value;

        setState({ ...state, formData: newFormData });
    };

    const togglePasswordVisibility = () => {
        setState({ ...state, isPasswordVisible: !state.isPasswordVisible });
    };

    const login = async () => {
        if (!state.formData.userName || !state.formData.password) return;
        const loginCredentials: ILoginRequest = {
            userName: state.formData.userName,
            password: state.formData.password,
        };

        try {
            const res = await getToken(loginCredentials).unwrap();
            if (res) {
                setState({ ...state, errorMessageKey: null });
                dispatch(toggleLoginModal());
                navigate(`${location.pathname}${location.search}`);
            }
        } catch (err) {
            const status = (err as { status: number }).status;
            if (status === 401) {
                setState({ ...state, errorMessageKey: "login.error" });
            } else {
                setState({ ...state, errorMessageKey: "error.errorText" });
            }
        }
    };

    const closeModal = () => {
        setState({ ...state, errorMessageKey: null });
        dispatch(toggleLoginModal());
    };

    return (
        <Modal show={isLoginModalOpen} onHide={closeModal}>
            <Modal.Header closeButton className={"bg-secondary"}>
                <Modal.Title>
                    <FormattedMessage id={"login.login"} />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {state.errorMessageKey && (
                    <Alert variant={"primary"}>
                        <FormattedMessage id={state.errorMessageKey} />
                    </Alert>
                )}
                <Form>
                    <Form.Group className={"mb-3"} controlId={"userName"}>
                        <Form.Label>
                            <FormattedMessage id={"login.user"} />
                        </Form.Label>
                        <Form.Control type="email" onChange={onFormInputChange} value={state.formData.userName} />
                    </Form.Group>
                    <Form.Group className={"mb-3"} controlId={"password"}>
                        <Form.Label>
                            <FormattedMessage id={"login.password"} />
                        </Form.Label>
                        <div className={"password-toggle"}>
                            <Form.Control type={state.isPasswordVisible ? "text" : "password"} onChange={onFormInputChange} value={state.formData.password} />
                            <Form.Label className={"password-toggle-btn"} onClick={togglePasswordVisibility}>
                                <Form.Check type={"checkbox"} className={"password-toggle-check"} />
                                <span className="password-toggle-indicator"></span>
                            </Form.Label>
                        </div>
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" className={"w-100 btn-shadow"} onClick={login}>
                    <FormattedMessage id={"login.login"} />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
