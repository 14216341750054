import { useToast } from "hooks/use-toast";
import { useEffect } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { selectToasts } from "store/slices/uiSlice";

import "./toasts.scss";
import { CartzillaIcon, CartzillaIconName } from "components/ui/icons/cartzilla-icon";

export const Toasts = () => {
    const toasts = useAppSelector(selectToasts);
    const location = useLocation();
    const { removeToast, clearToasts } = useToast();

    useEffect(() => {
        clearToasts();
    }, [location.pathname]);

    if (!toasts.length) return null;
    return (
        <div className={"toasts-wrapper p-3"}>
            <ToastContainer>
                {toasts.map((toast) => (
                    <Toast key={toast.id} onClose={() => removeToast(toast)}>
                        <Toast.Header className={`bg-${toast.type} text-white`}>
                            <CartzillaIcon icon={CartzillaIconName.Announcement} />
                            {toast.title}
                        </Toast.Header>
                        <Toast.Body className={`text-${toast.type}`}>{toast.text}</Toast.Body>
                    </Toast>
                ))}
            </ToastContainer>
        </div>
    );
};
