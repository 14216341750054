import { Form, Row, Col } from "react-bootstrap";

export const SettingsUserProfile = () => {
    return (
        <>
            <div className="d-none d-lg-flex justify-content-between align-items-center pt-lg-3 pb-4 pb-lg-5 mb-lg-3">
                <h4 className="fs-base text-light mb-0">Andmed</h4>
            </div>
            <Form>
                <Row className={"gx-4 gy-3"}>
                    <Form.Group as={Col} controlId="formEmail" sm={6}>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={"info@callista.ee"} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formPhoneNumber" sm={6}>
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formPassword" sm={6}>
                        <Form.Label>Parool</Form.Label>
                        <Form.Control type="password" placeholder="Password" />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formConfirmPassword" sm={6}>
                        <Form.Label>Kinnita parool</Form.Label>
                        <Form.Control type="password" placeholder="Password" />
                    </Form.Group>
                </Row>
            </Form>
        </>
    );
};
