import React, { useRef, useEffect } from "react";

export const useForwardedRef = <T,>(ref: React.ForwardedRef<T>): [React.MutableRefObject<T | null>, (element: T) => void] => {
    const innerRef = useRef<T | null>(null);
    const refCallback = (element: T) => {
        innerRef.current = element;
    }

    useEffect(() => {
        if (!ref) return;
        if (typeof ref === "function") {
            ref(innerRef.current);
        } else {
            ref.current = innerRef.current;
        }
    });

    return [innerRef, refCallback];
};