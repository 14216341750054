import { Pagination } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { CartzillaIcon, CartzillaIconName } from "../ui/icons/cartzilla-icon";
import { IPaginationProps, onNextPageClick, onPreviousPageClick, onSelectPageClick } from "./helpers";

export interface IPageBottomPaginationProps extends IPaginationProps {
    doScrollTop?: boolean;
}

export const PageBottomPagination = ({ doScrollTop = true, ...props }: IPageBottomPaginationProps) => {
    const maxPage: number = Math.floor(props.totalItems / props.pageSize) + (props.totalItems % props.pageSize !== 0 ? 1 : 0);

    const getPaginationNumberList = () => {
        let list = [1, 12345, props.currentPage - 2, props.currentPage - 1, props.currentPage, props.currentPage + 1, props.currentPage + 2, 12346, maxPage];

        if (props.currentPage >= maxPage - 3) list = [1, 12345, maxPage - 4, maxPage - 3, maxPage - 2, maxPage - 1, maxPage];
        if (props.currentPage <= 3) {
            list = [1, 2, 3, 4, 5, 12345, maxPage];
        }
        if (maxPage < 6) {
            list = [1, 2, 3, 4, 5];
        }
        return list.filter(function (item, pos) {
            return list.indexOf(item) == pos;
        });
    };

    const onSelectPage = (move: "next" | "previous" | "select", p?: number) => {
        switch (move) {
            case "previous":
                onPreviousPageClick(props.currentPage, props.onPageClick);
                break;
            case "next":
                onNextPageClick(props.currentPage, props.totalItems, props.pageSize, props.onPageClick);
                break;
            case "select":
                if (!p) {
                    throw new Error(`No page number given.`);
                }
                onSelectPageClick(p, props.currentPage, props.totalItems, props.pageSize, props.onPageClick);
                break;
            default:
                throw new Error(`No pagination move '${move}' found!`);
        }

        scrollTo();
    };

    const scrollTo = () => {
        if (!doScrollTop) return;

        window.scroll({
            top: 0,
            behavior: "auto",
        });
    };

    if (typeof props.isVisible === "boolean" && !props.isVisible) return <></>;
    if (maxPage <= 1) return <></>;

    return (
        <nav className={"d-flex justify-content-between pt-2 w-100"}>
            <Pagination>
                <Pagination.Prev onClick={() => onSelectPage("previous")} disabled={props.currentPage === 1}>
                    <CartzillaIcon icon={CartzillaIconName.ArrowLeft} className={"me-2"} />
                    <span className={"d-none d-sm-inline-block"}>
                        <FormattedMessage id={"products.previous"} />
                    </span>
                </Pagination.Prev>
            </Pagination>
            <Pagination>
                {getPaginationNumberList().map((p) => {
                    if (p == props.currentPage)
                        return (
                            <Pagination.Item onClick={() => onSelectPage("select", p)} key={p} active>
                                {p}
                            </Pagination.Item>
                        );
                    if (p > 0 && p <= maxPage)
                        return (
                            <Pagination.Item onClick={() => onSelectPage("select", p)} key={p}>
                                {p}
                            </Pagination.Item>
                        );
                    else if (p > 10000) return <Pagination.Item key={p}>...</Pagination.Item>;
                })}
            </Pagination>
            <Pagination>
                <Pagination.Next onClick={() => onSelectPage("next")} disabled={props.currentPage === maxPage}>
                    <span className={"d-none d-sm-inline-block"}>
                        <FormattedMessage id={"products.next"} />
                    </span>
                    <CartzillaIcon icon={CartzillaIconName.ArrowRight} className={"ms-2"} />
                </Pagination.Next>
            </Pagination>
        </nav>
    );
};
