import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError, FetchBaseQueryMeta } from "@reduxjs/toolkit/query/react";
import { ICartProduct, ICartProductAdd } from "../models/api/ICartProduct";
import { IOrder } from "../models/api/IOrder";
import { IExistingOrderToCartRequestItems } from "../models/api/IExistingOrderToCartRequestItems";
import { IStoreProduct } from "../models/api/IProduct";
import { ILoginRequest } from "../models/api/ILoginRequest";
import { ILoginResponse } from "../models/api/ILoginResponse";
import { IUser } from "../models/api/IUser";
import { RootState } from "../store";
import { IClient } from "../models/api/IClient";
import { IAppliancesResponse } from "../models/api/IAppliancesResponse";
import { IInvoice } from "../models/api/IInvoice";
import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { IBaseOrderRequest, IOrderRequest } from "../models/api/IOrderRequest";
import { ICart } from "../models/api/ICart";
import { IPublicProductsResponse } from "../models/api/IPublicProductsResponse";
import { IBaseCategory } from "../models/api/ICategory";
import { defaultLocale } from "i18n";
import { IPublicCampaign } from "models/api/IPublicCampaign";
import { ICartUpdate } from "models/api/ICartUpdate";

const customBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta> = async (args, api, extraOptions) => {
    const state = api.getState() as RootState;

    // New endpoints pass client id information as path segment instead of sending it in the body
    // E.g. api/v1/client/:clientId/some/endpoint
    let baseUrl = `${process.env.REACT_APP_API_HOST}/api/v1/`;

    const clientIdAsSegmentEndpoints = ["getCart", "addCartProduct", "removeCartProduct", "clearCart", "addExistingOrderToCart", "updateCart"];

    if (clientIdAsSegmentEndpoints.includes(api.endpoint)) {
        const clientId = state.user.selectedClient?.id;
        const apiHost = process.env.REACT_APP_USE_MOCK_API ? process.env.REACT_APP_MOCK_API_HOST : process.env.REACT_APP_API_HOST;
        baseUrl = `${apiHost}/api/v1/client/${clientId}`;
    }

    const prepareHeaders = (headers: Headers, { getState, endpoint }: Pick<BaseQueryApi, "getState" | "extra" | "endpoint" | "type" | "forced">) => {
        // If we have a token set in state, let's assume that we should be passing it.'
        const token = (getState() as RootState).user.token;
        if (token && endpoint !== "getToken") {
            headers.set("X-Authentication-Token", `${token}`);
        }

        const acceptLanguage = (getState() as RootState).user.locale ?? defaultLocale;
        headers.set("Accept-Language", acceptLanguage);
        headers.set("Cache-Control", "no-cache, no-store");

        return headers;
    };

    const rawBaseQuery = fetchBaseQuery({ baseUrl, prepareHeaders });
    return rawBaseQuery(args, api, extraOptions);
};

export const ecosternApi = createApi({
    baseQuery: customBaseQuery,
    reducerPath: "ecostern/api",
    tagTypes: [
        "Product",
        "Category",
        "Cart",
        "RelatedProduct",
        "Client",
        "Order",
        "OrderTry",
        "User",
        "MinimalClient",
        "UserClient",
        "Translation",
        "TranslationProduct",
        "Campaign",
        "TranslationCategory",
        "TranslationMessage",
        "PublicCampaign",
        "UserInfo",
    ],
    endpoints: (build) => ({
        getProducts: build.query<IStoreProduct[], number>({
            query: (clientId) => ({
                url: `/products/`,
                method: "POST",
                body: { clientId: clientId },
            }),
            providesTags: ["Product"],
        }),
        getRelatedProducts: build.query<number[], { productId: number; clientId: number }>({
            query: ({ productId, clientId }) => ({
                url: `/similar_products/${productId}`,
                method: "POST",
                body: {
                    clientId,
                },
            }),
            keepUnusedDataFor: 600,
        }),
        getPublicProducts: build.query<IPublicProductsResponse, void>({
            query: () => ({
                url: `/public/products`,
                method: "GET",
            }),
            keepUnusedDataFor: 600,
        }),
        getSelectedProducts: build.query<IStoreProduct[], number>({
            query: (clientId) => ({
                url: `/selected_products/`,
                method: "POST",
                body: { clientId: clientId },
            }),
            keepUnusedDataFor: 600,
        }),
        getCategories: build.query<IBaseCategory[], number>({
            query: (clientId) => ({
                url: `/categories/`,
                method: "POST",
                body: { clientId: clientId },
            }),
            keepUnusedDataFor: 600,
            providesTags: ["Category"],
        }),
        getOrderHistory: build.query<IOrder[], number>({
            query: (clientId) => ({
                url: `/order_history/`,
                method: "POST",
                body: { clientId: clientId },
            }),
            keepUnusedDataFor: 600,
            providesTags: ["Order"],
        }),
        getClients: build.query<IClient[], void>({
            query: () => ({
                url: `/clients/`,
                method: "POST",
            }),
            providesTags: ["Client"],
            transformResponse: (response: IClient[]) => {
                return response.sort((a, b) => a.name.localeCompare(b.name));
            },
        }),
        getAppliances: build.query<IAppliancesResponse, number>({
            query: (clientId) => ({
                url: `/appliances/`,
                method: "POST",
                body: { clientId: clientId },
            }),
        }),
        getPendingInvoices: build.query<IInvoice[], number>({
            query: (clientId) => ({
                url: `/pending_invoices/`,
                method: "POST",
                body: { clientId: clientId },
            }),
        }),
        getCart: build.query<ICart, void>({
            query: () => `/cart`,
            providesTags: ["Cart"],
        }),
        addCartProduct: build.mutation<ICart, ICartProductAdd>({
            query: (body) => ({
                url: `/cart`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["Cart"],
        }),
        updateCart: build.mutation<ICart, ICartUpdate>({
            query: (data) => ({
                url: `/cart`,
                method: "PUT",
                body: data,
            }),
        }),
        removeCartProduct: build.mutation<ICart, ICartProduct["productId"]>({
            query: (productId) => ({
                url: `/cart/product/${productId}`,
                method: "DELETE",
            }),
        }),
        addExistingOrderToCart: build.mutation<ICart, IExistingOrderToCartRequestItems>({
            query: ({ orderId, action }) => ({
                url: `/cart/order/${orderId}`,
                method: "POST",
                body: {
                    action,
                },
            }),
            invalidatesTags: ["Cart"],
        }),
        clearCart: build.mutation<ICart, void>({
            query: () => ({
                url: "/cart",
                method: "DELETE",
            }),
        }),
        getTryOrder: build.query<{ outOfStockProducts: number[] }, IBaseOrderRequest>({
            query: (body) => ({
                url: `/try_order`,
                method: "POST",
                body,
            }),
            providesTags: ["OrderTry"],
        }),
        addOrder: build.mutation<void, IOrderRequest>({
            query: (body) => ({
                url: `/submit_order`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["Cart", "Order", "OrderTry"],
        }),
        getToken: build.mutation<ILoginResponse, ILoginRequest>({
            query: ({ userName, password }) => ({
                url: `/token`,
                method: "POST",
                headers: {
                    "X-Authentication-Username": userName,
                    "X-Authentication-Password": password,
                },
            }),
            transformResponse: (response, meta) => {
                return { token: meta?.response?.headers.get("X-Authentication-Token") || "" } as ILoginResponse;
            },
        }),
        getUserInfo: build.query<IUser, void>({
            query: () => ({
                url: `/authenticate`,
                method: "GET",
            }),
            providesTags: ["UserInfo"],
        }),
        getPublicCampaigns: build.query<IPublicCampaign[], void>({
            query: () => ({
                url: `/public/campaigns`,
                method: "GET",
            }),
            providesTags: ["PublicCampaign"],
        }),
    }),
});

export const {
    useGetProductsQuery,
    useGetRelatedProductsQuery,
    useGetPublicProductsQuery,
    useGetSelectedProductsQuery,
    useGetCategoriesQuery,
    useGetOrderHistoryQuery,
    useGetClientsQuery,
    useGetAppliancesQuery,
    useGetPendingInvoicesQuery,
    useGetCartQuery,
    useUpdateCartMutation,
    useAddExistingOrderToCartMutation,
    useAddCartProductMutation,
    useRemoveCartProductMutation,
    useClearCartMutation,
    useGetTryOrderQuery,
    useAddOrderMutation,
    useGetTokenMutation,
    useGetUserInfoQuery,
    useGetPublicCampaignsQuery,
} = ecosternApi;
