import { BrowserRouter } from "react-router-dom";
import { Footer } from "./components/footer/footer";
import { Header } from "./components/header/header";
import { Main } from "./components/layout/main";
import { defaultLocale, fallbackLocale, locales } from "./i18n";
import Router from "./router";
import { useAppSelector } from "./store/hooks";
import { selectUserLocale } from "./store/slices/userSlice";
import { IntlProvider } from "react-intl";
import { Toasts } from "components/toasts";

function App() {
    const userLocale = useAppSelector(selectUserLocale);
    const localeMessages = Object.assign({}, locales[fallbackLocale], locales[userLocale]);

    return (
        <>
            <IntlProvider messages={localeMessages} locale={userLocale} defaultLocale={defaultLocale}>
                <BrowserRouter>
                    <Toasts />
                    <Header />
                    <Main>
                        <Router />
                    </Main>
                    <Footer />
                </BrowserRouter>
            </IntlProvider>
        </>
    );
}

export default App;
