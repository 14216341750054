import { useEffect, useMemo, useState } from "react";

export const useIsSeen = (ref: React.RefObject<HTMLElement>, callback?: () => void) => {
    const [isSeen, setIsSeen] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                if (!entry.isIntersecting) return;
                callback?.();
                setIsSeen(true);
                observer.disconnect();
            }),
        [ref]
    );

    useEffect(() => {
        if (!ref.current) return;
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, []);

    return isSeen;
};
