import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { ecosternApi } from "../../api/ecosternApi";
import { ICart } from "../../models/api/ICart";
import { ICartProduct } from "../../models/api/ICartProduct";
import { logOut } from "./userSlice";

interface ICartState extends ICart {}

const initialState: ICartState = {
    products: [],
    totalSum: 0,
    totalProductCount: 0,
    transportSum: 0,
    comment: null,
};

const cartSlice = createSlice({
    name: "ecostern/cart",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(logOut, () => {
            return initialState;
        });
        builder.addMatcher(ecosternApi.endpoints.removeCartProduct.matchFulfilled, (state, action) => {
            return action.payload;
        });
        builder.addMatcher(ecosternApi.endpoints.addCartProduct.matchFulfilled, (state, action) => {
            return action.payload;
        });
        builder.addMatcher(ecosternApi.endpoints.getCart.matchFulfilled, (state, action) => {
            return action.payload;
        });
        builder.addMatcher(ecosternApi.endpoints.updateCart.matchFulfilled, (state, action) => {
            return action.payload;
        });
        builder.addMatcher(ecosternApi.endpoints.clearCart.matchFulfilled, () => {
            return initialState;
        });
    },
});

export const selectCartProducts = (state: RootState) => state.cart.products;
export const selectCartProductsSum = (state: RootState) => state.cart.totalSum ?? 0;
export const selectCartProductsCount = (state: RootState) => state.cart.totalProductCount ?? 0;
export const selectCartProduct = (id: ICartProduct["productId"]) => (state: RootState) => state.cart.products.find((cp) => cp.productId == id);
export const selectCartTransportSum = (state: RootState) => state.cart.transportSum;
export const selectCartComment = (state: RootState) => state.cart.comment ?? "";

export default cartSlice.reducer;
