import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    selectProductModalProduct,
    selectProductModalIsOpen,
    toggleProductModal,
    selectProductModalPrevProduct,
    prevModalProduct,
    selectProductModalMessage,
} from "../../store/slices/uiSlice";
import { ProductCard } from "../product-card/product-card";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetProductsQuery } from "api";
import { useIntl } from "react-intl";
import { selectCurrentClientId } from "store/slices/userSlice";
import { ProductModalMessage } from "types/ProductModalItem";

const PRODUCT_QUERY_KEY = "pid";

export const ProductModal = () => {
    const isVisible = useAppSelector(selectProductModalIsOpen);
    const modalProduct = useAppSelector(selectProductModalProduct);
    const modalMessage = useAppSelector(selectProductModalMessage);
    const modalPrevProduct = useAppSelector(selectProductModalPrevProduct);
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (!ready) {
            setReady(true);
            return;
        }

        const params = new URLSearchParams(searchParams);
        params.delete(PRODUCT_QUERY_KEY);
        if (modalProduct) {
            params.append(PRODUCT_QUERY_KEY, modalProduct.id + "");
        }
        setSearchParams(params, { replace: true });
    }, [modalProduct]);

    return (
        <Modal show={isVisible} onHide={() => dispatch(toggleProductModal())}>
            <Modal.Header closeButton className={"bg-secondary"}></Modal.Header>
            <Modal.Body className={"p-2 p-md-4"}>
                {modalMessage && <div className={`${modalMessage.type === "error" ? "text-primary" : ""} text-center py-5`}>{modalMessage.text}</div>}
                {modalProduct && <ProductCard product={modalProduct} col={"auto"} hoverBehaviour={"card-static"} />}
                {modalPrevProduct && (
                    <div className={"d-flex justify-content-end"}>
                        <Button variant={"outline-primary"} onClick={() => dispatch(prevModalProduct())}>
                            <FormattedMessage id={"product.productBack"} />
                        </Button>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

export const useProductInQuery = () => {
    const selectedClientId = useAppSelector(selectCurrentClientId);
    const { data = [] } = useGetProductsQuery(selectedClientId ?? skipToken);
    const dispatch = useAppDispatch();
    const modalProduct = useAppSelector(selectProductModalProduct);
    const intl = useIntl();
    const [searchParams] = useSearchParams();

    const handleProductIdInQuery = () => {
        const pid = searchParams.get(PRODUCT_QUERY_KEY);
        if (modalProduct || !pid) return;

        const productId = parseInt(pid);
        const pidProduct = productId ? data.find((p) => p.id == productId) : null;
        if (pidProduct) {
            dispatch(toggleProductModal(pidProduct));
        } else {
            const message: ProductModalMessage = {
                text: intl.formatMessage({ id: "products.productNotFound" }),
                type: "error",
            };
            dispatch(toggleProductModal({ message }));
        }
    };

    return { handleProductIdInQuery };
};
