import { configureStore, isRejected, Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import rootReducer from "./slices";
import { ecosternApi } from "../api/ecosternApi";
import { IUserState, logOut, setSelectedClient, setUserLocale } from "./slices/userSlice";

const LOCALSTORAGE_USER_STATE_KEY = "ecostern/u";
const SESSIONSTORAGE_CLIENT_STATE_KEY = "ecostern/c";
const VERSION = process.env.REACT_APP_GIT_SHA;
const isForceLogoutOnVersionChange = process.env.REACT_APP_FORCE_LOGOUT_ON_VERSION_CHANGE === "true";

const userStateStorageMiddleware: Middleware = (api: MiddlewareAPI<AppDispatch, RootState>) => (next) => (action) => {
    const result = next(action);
    const updatedState = api.getState() as RootState;

    const isGetUserInfoAction = ecosternApi.endpoints.getUserInfo.matchFulfilled(action);
    const isSetSelectedClientAction = setSelectedClient.match(action);
    const isSetUserLocaleAction = setUserLocale.match(action);
    const isLogOutAction = logOut.match(action);

    if (isSetUserLocaleAction) {
        setStoredUserState(updatedState.user);
        api.dispatch(ecosternApi.endpoints.getUserInfo.initiate()); // Ensure user info is always refreshed when locale is changed
        api.dispatch(ecosternApi.util.invalidateTags(["UserInfo", "Product", "Category", "PublicCampaign", "RelatedProduct"]));
    }

    if (isGetUserInfoAction) {
        setStoredUserState(updatedState.user);
    }

    if (isSetSelectedClientAction) {
        sessionStorage.setItem(
            SESSIONSTORAGE_CLIENT_STATE_KEY,
            JSON.stringify({
                client: updatedState.user.selectedClient,
                v: VERSION,
            })
        );
        api.dispatch(ecosternApi.endpoints.getCart.initiate());
    }

    if (isLogOutAction) {
        localStorage.removeItem(LOCALSTORAGE_USER_STATE_KEY);
        sessionStorage.removeItem(SESSIONSTORAGE_CLIENT_STATE_KEY);
        api.dispatch(ecosternApi.util.resetApiState());
    }

    return result;
};

const setStoredUserState = (user: IUserState) => {
    const { userInfo, token, locale } = user;
    localStorage.setItem(
        LOCALSTORAGE_USER_STATE_KEY,
        JSON.stringify({
            user: {
                userInfo,
                token,
                locale,
            },
            v: VERSION,
        })
    );
};

const loadStoredState = () => {
    const storedUserState = localStorage.getItem(LOCALSTORAGE_USER_STATE_KEY);
    const storedClientState = sessionStorage.getItem(SESSIONSTORAGE_CLIENT_STATE_KEY);

    const state = {} as { [key: string]: object };

    if (!storedUserState) return state;

    const { v, user } = JSON.parse(storedUserState);
    if (!v || (v !== VERSION && isForceLogoutOnVersionChange)) {
        localStorage.removeItem(LOCALSTORAGE_USER_STATE_KEY);
        sessionStorage.removeItem(SESSIONSTORAGE_CLIENT_STATE_KEY);
        return state;
    }
    state.user = user;

    if (!storedClientState) return state;

    const { client } = JSON.parse(storedClientState);
    (state.user as { selectedClient: object }).selectedClient = client;

    return state;
};

const notAuthorizedInterceptor: Middleware = (api) => (next) => (action) => {
    // console.log("notAuthorizedInterceptor", action);

    const catchStatuses = [401];
    if (isRejected(action) && action.payload && catchStatuses.includes(action.payload.status)) {
        api.dispatch(logOut());
    } else {
        return next(action);
    }
};

export const store = configureStore({
    reducer: rootReducer,
    preloadedState: loadStoredState(),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([ecosternApi.middleware, userStateStorageMiddleware, notAuthorizedInterceptor]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {user: UserState}
export type AppDispatch = typeof store.dispatch;
