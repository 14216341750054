import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { FunctionComponent } from "react";
import { useEffect, useState } from "react";
import { Accordion, AccordionProps, Button, Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { ICategory } from "../../models/content/category/ICategory";
import { useAppSelector } from "../../store/hooks";
import { useGetCategoriesQuery } from "../../api/ecosternApi";
import { selectCurrentClientId } from "../../store/slices/userSlice";
import { CategoryToggle } from "./category-toggle";
import { AccordionItemProps } from "react-bootstrap/esm/AccordionItem";
import { AccordionBodyProps } from "react-bootstrap/esm/AccordionBody";

interface ICategoryTreeState {
    categories: ICategory[];
    isLoading: boolean;
}

interface ICategoryTreeProps {
    handler: (category?: ICategory) => void;
    selected: ICategory | null;
}

export const CategoryTree = (props: ICategoryTreeProps) => {
    const selectedClientId = useAppSelector(selectCurrentClientId);
    const { data = [], isLoading, isError } = useGetCategoriesQuery(selectedClientId ?? skipToken);

    const [state, setState] = useState<ICategoryTreeState>({
        categories: [],
        isLoading: true,
    });

    useEffect(() => {
        if (!isLoading && data.length > 0) {
            const categories: ICategory[] = JSON.parse(JSON.stringify(data));
            let root = [];
            for (let i = 0; i < categories.length; i++) {
                const child = categories[i];
                child.subCategories = [];
                let bestParent = null;
                let bestDistance = 100000;
                for (let e = 0; e < categories.length; e++) {
                    if (i !== e) {
                        const parent = categories[e];
                        if (child.id.startsWith(parent.id)) {
                            if (child.id.length - parent.id.length < bestDistance) {
                                bestDistance = child.id.length - parent.id.length;
                                bestParent = parent;
                            }
                        }
                    }
                }
                if (bestParent !== null && child.parent == null) {
                    child.parent = bestParent;
                    if (!child.parent.subCategories) child.parent.subCategories = [];
                    child.parent.subCategories.push(child);
                    if (root.indexOf(bestParent) === -1) {
                        root.push(bestParent);
                    }
                } else {
                    root.push(child);
                }
            }

            root = root.filter(function (node) {
                if (node.parent) {
                    node.parent = null;
                    return false;
                }
                return true;
            });

            // console.log("tree", root);
            setState({ categories: root as ICategory[], isLoading: false });
        }
        return () => {
            //
        };
    }, [data, isLoading]);

    const getCategoryAccordion = (categories: ICategory[], level = 0) => {
        const levelClass = level > 0 ? "ps-3" : "";
        const accordionItems: React.ReactNode[] = [];

        for (const category of categories) {
            const hasSubCategories = category.subCategories.length !== 0;
            const headerClasses = `${hasSubCategories ? "" : "final-level"} ${props.selected && props.selected.id == category.id ? "selected" : ""}`;
            // const accordionHeader = React.createElement(CategoryToggle, { className: headerClasses, onClick: () => { props.handler(category) }, key: `${category.id}-header` }, category.name);
            // We use a custom toggle in order to have a separate onClick handler that only expands category but does not select it.
            const accordionHeader = (
                <CategoryToggle eventKey={category.id} className={headerClasses} set={() => props.handler(category)} key={`${category.id}-header`}>
                    {category.name}
                </CategoryToggle>
            );

            if (!hasSubCategories) {
                const accordionItem = React.createElement(
                    Accordion.Item as FunctionComponent<AccordionItemProps>,
                    { eventKey: category.id, key: category.id },
                    [accordionHeader]
                );
                accordionItems.push(accordionItem);
            } else {
                const accordionBody = React.createElement(Accordion.Body as FunctionComponent<AccordionBodyProps>, { key: `${category.id}-body` }, [
                    getCategoryAccordion(category.subCategories, level + 1),
                ]);
                const accordionItem = React.createElement(
                    Accordion.Item as FunctionComponent<AccordionItemProps>,
                    { eventKey: category.id, key: category.id },
                    [accordionHeader, accordionBody]
                );
                accordionItems.push(accordionItem);
            }
        }

        return React.createElement(Accordion as FunctionComponent<AccordionProps>, { className: levelClass, key: level }, accordionItems);
    };

    return (
        <div className={"widget widget-categories mb-4 pb-4"}>
            <h3 className={"widget-title mb-2 d-flex justify-content-between align-items-center"}>
                <FormattedMessage id={"products.categories"} />
                <Button size={"sm"} variant={"secondary border badge"} onClick={() => props.handler()}>
                    <FormattedMessage id={"nav.clearFilters"} />
                </Button>
            </h3>
            <div className={"fs-sm mb-3"}>
                {props.selected ? (
                    <>
                        <span className={"text-muted"}>
                            <FormattedMessage id={"nav.selectedFilters"} />
                            :&nbsp;
                        </span>
                        {props.selected?.name}
                    </>
                ) : (
                    <FormattedMessage id={"products.all"} />
                )}
            </div>
            {state.isLoading && !isError ? (
                <div className={"d-flex justify-content-center p-5"}>
                    <Spinner animation={"border"} />
                </div>
            ) : (
                getCategoryAccordion(state.categories)
            )}

            {isError && (
                <div className={"d-flex align-items-center"}>
                    <FormattedMessage id={"error.errorText"} />
                </div>
            )}
        </div>
    );
};
