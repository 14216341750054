import { Col } from "react-bootstrap";
import { IProduct } from "../../models/api/IProduct";
import { useAppDispatch } from "../../store/hooks";
import { toggleProductModal } from "../../store/slices/uiSlice";
import { ProductImage } from "../ui/images/product-image/product-image";

interface IProps {
    product: IProduct;
}

export const RelatedProductCard = ({ product }: IProps) => {
    const dispatch = useAppDispatch();
    return (
        <Col xs={12} onClick={() => dispatch(toggleProductModal(product))} className={"cursor-pointer d-flex gap-1 align-items-center"}>
            <ProductImage id={product.id} item={product} size={"md"} useZoom={false} />
            <div>{product.name}</div>
        </Col>
    );
};
