import React, { useState } from "react";
import { Badge, Button, Card, Col, Form, Row } from "react-bootstrap";
import { IProduct, isStoreProduct, IStoreProduct } from "../../models/api/IProduct";
import { useAddCartProductMutation } from "../../api/ecosternApi";
import { ICartProductAdd } from "../../models/api/ICartProduct";
import { selectCurrentClientId, selectIsUserEcostern } from "../../store/slices/userSlice";
import { selectCartProduct } from "../../store/slices/cartSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { CartzillaIcon, CartzillaIconName } from "../ui/icons/cartzilla-icon";
import { LoadingSpinnerButton } from "../ui/buttons/loading-spinner-button";
import { getProductCodesInReminder, getUIAttributes, temp__getItemsInPackage } from "../../utils/product.utils";
import { RelatedProducts } from "./related-products";
import { ProductImage } from "../ui/images/product-image/product-image";
import { toggleProductModal } from "../../store/slices/uiSlice";
import { useToast } from "hooks/use-toast";
import { UIAttributes } from "models/content/product/UIProduct";
import { ECampaignType } from "models/api/enums/ECampaignType";

interface IProps {
    product: IStoreProduct | IProduct;
    col?: number | "auto";
    hoverBehaviour?: "closed" | "card-static" | "";
    showCampaign?: boolean;
    height?: "auto" | "16rem";
    variant?: "default" | "transparent";
    getProduct?: (code: string) => IStoreProduct | IProduct | null | undefined;
    uiAttributes?: UIAttributes;
}

interface IProductCardState {
    quantity: number;
}

export const ProductCard = ({
    height = "auto",
    hoverBehaviour = "",
    variant = "default",
    showCampaign = true,
    product,
    getProduct,
    uiAttributes,
    ...props
}: IProps) => {
    const [postAddToCart, addResult] = useAddCartProductMutation();
    const currentClientId = useAppSelector(selectCurrentClientId);
    const existingCartProduct = useAppSelector(selectCartProduct(product.id));
    const isUserEcostern = useAppSelector(selectIsUserEcostern);
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const { addErrorToast } = useToast();
    const _uiAttributes = uiAttributes ?? getUIAttributes(product, intl);
    const ecoTag = _uiAttributes.badges.find((badge) => badge.name === "eco");
    const newTag = _uiAttributes.badges.find((badge) => badge.name === "new");

    const [state, setState] = useState<IProductCardState>({
        quantity: 1,
    });

    const toggleCard = () => {
        dispatch(toggleProductModal(product));
    };

    const onProductQuantityClick = (items: number) => {
        const newQuantity = state.quantity + items;
        if (newQuantity < 0) return;

        setState({ ...state, quantity: newQuantity });
    };

    const onProductQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newQuantity = parseInt(e.currentTarget.value);
        if (newQuantity < 0) return;
        setState({ ...state, quantity: newQuantity });
    };

    const onAddProductToCart = async () => {
        if (state.quantity <= 0) return;
        if (!currentClientId) return;

        const newQuantity = state.quantity + (existingCartProduct?.quantity ?? 0);

        const cartProduct: ICartProductAdd = {
            productId: product.id,
            quantity: newQuantity,
        };

        try {
            await postAddToCart(cartProduct).unwrap();
        } catch (err) {
            addErrorToast(intl.formatMessage({ id: "error.errorText" }));
            console.error(err);
        }
    };

    const getCampaignTag = () => {
        if (!_uiAttributes.campaign) return <></>;

        return (
            <span className={`badge ${_uiAttributes.campaign.type === ECampaignType.RegularCampaign ? "bg-yellow text-dark" : "bg-danger"} badge-shadow`}>
                <FormattedMessage id={`product.campaign.${_uiAttributes.campaign.type}`} />
            </span>
        );
    };

    const getProductReminderWithLinks = (product: IStoreProduct) => {
        if (!product.reminder) return null;

        const potentialCodesInReminder = getProductCodesInReminder(product);
        if (!getProduct || !potentialCodesInReminder || potentialCodesInReminder.length === 0) return product.reminder;

        const regex = new RegExp(`(${potentialCodesInReminder.join("|")})`, "g");
        const reminder: React.ReactNode[] = product.reminder.split(regex);

        potentialCodesInReminder?.forEach((code) => {
            const product = getProduct(code);
            if (!product) return null;
            const link = (
                <a role={"button"} tabIndex={0} className={"text-warning text-decoration-underline"} onClick={() => dispatch(toggleProductModal(product))}>
                    {code}
                </a>
            );
            reminder[reminder.indexOf(code)] = link;
        });

        return React.createElement(React.Fragment, null, ...reminder);
    };

    return (
        <Card className={`product-card p-2 p-md-3 col-12 col-md-${props.col ?? 6} ${hoverBehaviour} product-card-${variant}`}>
            {product.hasSpecificPrice && isUserEcostern && (
                <span className="btn-wishlist btn-sm">
                    <CartzillaIcon icon={CartzillaIconName.Lable} />
                </span>
            )}
            {showCampaign && getCampaignTag()}
            <Card.Body className={"d-flex flex-column align-items-center mh-100"} style={{ height }}>
                <Row className={"w-100 flex-grow-1"}>
                    <Col xs={3} className={"p-2 d-flex justify-content-center"}>
                        <ProductImage id={product.id} item={product} size={"auto"} hasBorder={false} />
                    </Col>
                    <Col xs={9} className={"d-flex flex-column justify-content-center cursor-pointer"} onClick={toggleCard}>
                        <div className={"product-meta fs-xs pb-1 d-flex align-items-center"}>
                            <span className={"product-code"}># {product.code}</span>
                            {isStoreProduct(product) && product.isSelected && (
                                <Badge bg={"secondary"} className={"selected-product ms-2"}>
                                    Valitud toode
                                </Badge>
                            )}
                            {!!ecoTag && (
                                <Badge bg={"green"} className={"selected-product ms-2"}>
                                    {ecoTag.label}
                                </Badge>
                            )}
                            {!!newTag && (
                                <Badge bg={"blue"} className={"selected-product ms-2"}>
                                    {newTag.label}
                                </Badge>
                            )}
                        </div>
                        <h3 className={"product-title fs-sm"}>{_uiAttributes?.productName || product.name}</h3>
                        <div className={"d-flex align-items-center"}>
                            <span className="text-accent">
                                <FormattedNumber value={product.price} currency={"EUR"} style={"currency"} />
                            </span>
                            {isStoreProduct(product) && isUserEcostern && (
                                <span className="product-meta fs-xs ms-3">{Number.isInteger(product.inStock) ? `${product.inStock} tk` : ""}</span>
                            )}
                            <span className={"product-meta fs-xs ms-3"}>{temp__getItemsInPackage(product)}</span>
                        </div>
                    </Col>
                </Row>
                {currentClientId && (
                    <Row className={"mt-2 justify-content-start w-100 mx-0"}>
                        <Col xs={{ offset: 3, span: 9 }} className={"d-flex justify-content-center justify-content-sm-start"}>
                            <Button variant={"secondary"} className={"me-1"} size={"sm"} onClick={() => onProductQuantityClick(-1)}>
                                -
                            </Button>
                            <Form.Control
                                type={"number"}
                                className={"me-1 flex-grow-1 flex-sm-grow-0"}
                                style={{ width: "4rem" }}
                                size={"sm"}
                                value={state.quantity.toString()}
                                onChange={onProductQuantityChange}
                            />
                            <Button variant={"secondary"} size={"sm"} className={"me-1"} onClick={() => onProductQuantityClick(1)}>
                                +
                            </Button>
                            <LoadingSpinnerButton
                                isLoading={!(addResult.isUninitialized || (!addResult.isUninitialized && !addResult.isLoading))}
                                variant={"primary"}
                                size={"sm"}
                                onClick={onAddProductToCart}
                                icon={<CartzillaIcon icon={CartzillaIconName.Cart} className={"fs-sm"} />}
                            />
                        </Col>
                    </Row>
                )}

                <Row className={"mt-2 justify-content-start w-100 mx-0"} style={{ minHeight: "1.15rem" }}>
                    {isStoreProduct(product) && product.reminder ? (
                        <Col xs={{ offset: 3, span: 9 }} className={"d-flex justify-content-start align-items-center text-warning "}>
                            <CartzillaIcon icon={CartzillaIconName.Announcement} className={"fs-sm"} />
                            <div className={"fs-xs ms-2"}>{getProductReminderWithLinks(product)}</div>
                        </Col>
                    ) : null}
                </Row>
            </Card.Body>
            <Card.Body className={`${hoverBehaviour !== "card-static" ? "card-body-hidden" : ""}`}>
                <div className={"overflow-auto-y overflow-hidden-x mh-100"}>
                    <Row className={"justify-content-end px-3 fs-sm"}>
                        <Col>{product.info}</Col>
                    </Row>
                    {!!ecoTag && (
                        <Row className={"justify-content-end px-3 fs-sm mt-3"}>
                            <Col>
                                <FormattedMessage id={"product.eco.description"} />
                            </Col>
                        </Row>
                    )}
                    {product.infoUrl && (
                        <Row className={"justify-content-end px-3 fs-sm mt-3"}>
                            <Col>
                                <a
                                    href={product.infoUrl.replace("http://", "https://")}
                                    target={"_blank"}
                                    rel={"noopener noreferrer"}
                                    className={"d-flex align-items-center flex-nowrap text-nowrap"}
                                >
                                    <FormattedMessage id={"product.productCard"} />
                                    &nbsp;
                                    <CartzillaIcon icon={CartzillaIconName.CloudDownload} className={"fs-sm me-1"} />
                                </a>
                            </Col>
                        </Row>
                    )}
                    {product.pdfUrl && (
                        <Row className={"justify-content-end px-3 fs-sm mt-2"}>
                            <Col>
                                <a
                                    href={product.pdfUrl.replace("http://", "https://")}
                                    target={"_blank"}
                                    rel={"noopener noreferrer"}
                                    className={"d-flex align-items-center flex-nowrap text-nowrap"}
                                >
                                    <FormattedMessage id={"product.safetyCard"} />
                                    &nbsp;
                                    <CartzillaIcon icon={CartzillaIconName.CloudDownload} className={"fs-sm me-1"} />
                                </a>
                            </Col>
                        </Row>
                    )}
                    {isStoreProduct(product) && <RelatedProducts relatedProducts={product.relatedProducts} />}
                </div>
            </Card.Body>
        </Card>
    );
};
