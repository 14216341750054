import { useState } from "react";
import { useAppSelector } from "store/hooks";
import { selectToken } from "store/slices/userSlice";

type ProgressState<TReturnData = void, TError = unknown> = {
    payload?: FormData;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    progress: number;
    data?: TReturnData;
    error?: {
        status: number;
        data?: TError;
    };
};

export const useFileUploadMutation = <TReturnData, TError>(endpoint: string): [(data: FormData) => Promise<void>, ProgressState<TReturnData, TError>] => {
    const apiHost = process.env.REACT_APP_API_HOST;
    const token = useAppSelector(selectToken);

    const [progress, setProgress] = useState<ProgressState<TReturnData, TError>>({
        isLoading: false,
        isError: false,
        isSuccess: false,
        progress: 0,
    });

    const upload = async (data: FormData) => {
        /**
         * Use native XHR request in order to be able to track upload progress.
         */
        new Promise<void>((resolve, reject) => {
            const req = new XMLHttpRequest();
            req.open("POST", `${apiHost}/api/v1/${endpoint}`);

            // Headers
            req.setRequestHeader("X-Authentication-Token", `${token}`);

            // Listen to upload progress
            req.upload.addEventListener("progress", (e) => {
                const percentComplete = (e.loaded / e.total) * 100;
                setProgress((prevState) => Object.assign({}, prevState, { progress: percentComplete, isLoading: true }));
            });

            req.addEventListener("load", () => {
                if (req.status >= 200 && req.status < 300) {
                    setProgress({
                        ...progress,
                        isError: false,
                        isLoading: false,
                        isSuccess: true,
                        data: req.response,
                    });
                    resolve();
                } else {
                    setProgress({
                        ...progress,
                        isError: true,
                        isLoading: false,
                        isSuccess: false,
                        error: {
                            status: req.status,
                            data: req.response,
                        },
                    });
                    reject();
                }
            });
            req.addEventListener("error", () => {
                setProgress({
                    ...progress,
                    isError: true,
                    isLoading: false,
                    isSuccess: false,
                    error: {
                        status: req.status,
                        data: req.response,
                    },
                });
                reject();
            });
            req.send(data);
            setProgress({ ...progress, payload: data, isLoading: true, isError: false, isSuccess: false, progress: 0 });
        });
    };

    return [upload, progress];
};
