import { Dropdown } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { routes } from "router";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { selectIsMenuOpen, toggleLoginModal, toggleMenu } from "store/slices/uiSlice";
import { logOut, selectCurrentClientName, selectIsLoggedIn, selectUserHasBackOfficeAccess, selectUserName } from "store/slices/userSlice";
import { LoginModal } from "components/modals/login-modal";
import { CartzillaIcon, CartzillaIconName } from "components/ui/icons/cartzilla-icon";

export const UserNav = () => {
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const userName = useAppSelector(selectUserName);
    const selectedClientName = useAppSelector(selectCurrentClientName);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const userHasBackOfficeAccess = useAppSelector(selectUserHasBackOfficeAccess);
    const isMenuOpen = useAppSelector(selectIsMenuOpen);

    const onLogOutBtnClick = () => {
        dispatch(logOut());
        navigate("/");
    };

    const onToggle = (isOpen: boolean) => {
        if (isOpen && isMenuOpen) {
            dispatch(toggleMenu());
        }
    };

    if (isLoggedIn && userName) {
        return (
            <Dropdown className={"navbar-tool"} onToggle={onToggle}>
                <Dropdown.Toggle as={"div"} className={"navbar-tool-icon-box"}>
                    <CartzillaIcon icon={CartzillaIconName.User} className={"navbar-tool-icon"} />
                </Dropdown.Toggle>
                <div className="navbar-tool-text ms-n3">
                    <div className="font-weight-bold">{userName}</div>
                    {selectedClientName ? (
                        selectedClientName.split("/").map((namePart) => <small key={namePart}>{namePart}</small>)
                    ) : (
                        <small className={"font-italic text-primary"}>
                            <FormattedMessage id={"locations.chooseClient"} />
                        </small>
                    )}
                </div>
                <Dropdown.Menu renderOnMount={true}>
                    <Dropdown.Item href={routes.appliances.path}>
                        <CartzillaIcon icon={CartzillaIconName.Lable} className={"me-1"} />
                        <FormattedMessage id={"nav.appliances"} />
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    {userHasBackOfficeAccess && (
                        <Dropdown.Item href={routes.settings.path}>
                            <CartzillaIcon icon={CartzillaIconName.Settings} className={"me-1"} />
                            <FormattedMessage id={"nav.settings"} />
                        </Dropdown.Item>
                    )}
                    <Dropdown.Item href={routes.clients.path} className={!selectedClientName ? "text-primary" : ""}>
                        <CartzillaIcon icon={CartzillaIconName.User} className={"me-1"} />
                        <FormattedMessage id={"nav.clients"} />
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={onLogOutBtnClick}>
                        <CartzillaIcon icon={CartzillaIconName.SignOut} className={"me-1"} />
                        <FormattedMessage id={"nav.logout"} />
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
    return (
        <>
            <div className="navbar-tool" onClick={() => dispatch(toggleLoginModal())}>
                <div className="navbar-tool-icon-box">
                    <CartzillaIcon icon={CartzillaIconName.User} className={"navbar-tool-icon"} />
                </div>
                <div className="navbar-tool-text ms-n3">
                    <small>
                        <FormattedMessage id={"nav.loginGreeting"} />
                    </small>
                    <FormattedMessage id={"nav.myAccount"} />
                </div>
            </div>
            <LoginModal />
        </>
    );
};
