import React from "react";
import { Row } from "react-bootstrap";

interface IProps {
    children: React.ReactNode;
    className?: string;
}

export const TableHeaderRow = ({ children, className = "" }: IProps) => {
    return (
        <Row className={`my-3 align-items-center fw-bold ${className}`}>{children}</Row>
    );
}