import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { defaultLocale, Locale } from "../../i18n";
import { IClient } from "../../models/api/IClient";
import { IUser } from "../../models/api/IUser";
import { ecosternApi } from "../../api/ecosternApi";

export interface IUserState {
    userInfo: IUser | null;
    locale: Locale;
    token: string | null;
    selectedClient: IClient | null;
}

const initialState: IUserState = {
    userInfo: null,
    locale: defaultLocale,
    token: null,
    selectedClient: null,
};

const userSlice = createSlice({
    name: "ecostern/user",
    initialState: initialState,
    reducers: {
        logIn: (_state: IUserState, action: PayloadAction<IUserState>) => action.payload,
        logOut: () => initialState,
        setSelectedClient: (_state: IUserState, action: PayloadAction<IClient>) => {
            _state.selectedClient = action.payload;
        },
        setUserLocale: (_state: IUserState, action: PayloadAction<Locale>) => {
            _state.locale = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(ecosternApi.endpoints.getToken.matchFulfilled, (state, action) => {
            state.token = action.payload.token;
        }),
            builder.addMatcher(ecosternApi.endpoints.getUserInfo.matchFulfilled, (state, action) => {
                state.userInfo = action.payload;
            });
    },
});

export const { logIn, logOut, setSelectedClient, setUserLocale } = userSlice.actions;

export const selectIsLoggedIn = (state: RootState) => Boolean(state.user.token);
export const selectUserName = (state: RootState) => state.user.userInfo?.username;
export const selectUserWelcomeMessage = (state: RootState) => state.user.userInfo?.welcomeText;
export const selectCurrentClient = (state: RootState) => state.user.selectedClient;
export const selectCurrentClientId = (state: RootState) => state.user.selectedClient?.id;
export const selectCurrentClientName = (state: RootState) => state.user.selectedClient?.name;
export const selectCurrentClientInstructions = (state: RootState) => state.user.selectedClient?.shipmentInstructions || "";
export const selectUserLocale = (state: RootState) => state.user.locale;
export const selectIsShowOnlyFavoriteProducts = (state: RootState) => state.user.userInfo?.showOnlyFavouriteProducts || false;
export const selectIsUserEcostern = (state: RootState) => state.user.userInfo?.role !== "REGULAR_USER";
export const selectUserRole = (state: RootState) => state.user.userInfo?.role;
export const selectIsAppAdmin = (state: RootState) => state.user.userInfo?.role === "ADMIN";
export const selectToken = (state: RootState) => state.user.token;
export const selectUserHasBackOfficeAccess = (state: RootState) => ["ADMIN", "ECOSTERN_BO_USER"].includes(state.user.userInfo?.role || "");
export const selectCurrentLocale = (state: RootState) => state.user.locale;

export default userSlice.reducer;
