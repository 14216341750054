import { useGetCampaignsQuery, usePostCampaignMutation, usePutCampaignMutation } from "api/adminApi";
import { LoadingWrapper } from "components/layout/loading-wrapper";
import { LoadingSpinnerButton } from "components/ui/buttons/loading-spinner-button";
import { CartzillaIcon, CartzillaIconName } from "components/ui/icons/cartzilla-icon";
import { InputSearch } from "components/ui/input/input-search";
import { IAddCampaign, ICampaign } from "models/api/ICampaign";
import { useEffect, useMemo, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { FormattedDate, FormattedMessage } from "react-intl";
import { searchItems } from "utils/utils";
import { DateRange, DayPicker } from "react-day-picker";
import { formatISO, parseISO } from "date-fns";
import * as yup from "yup";
import { useFormik } from "formik";

import "react-day-picker/dist/style.css";
import "./day-picker.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "router";
import { localeCodes } from "i18n";
import { CMSCampaignImages } from "components/cms-campaign-images";
import { useAppSelector } from "store/hooks";
import { selectCurrentLocale } from "store/slices/userSlice";

interface ISettingsCampaignState {
    campaignSearchQuery: string;
    viewCampaigns: ICampaign[];
    currentCampaign: ICampaign | null;
    isNew: boolean;
}

export const SettingsCampaigns = () => {
    const campaignSearchProps: (keyof ICampaign)[] = ["name", "description"];
    const campaigns = useGetCampaignsQuery();
    const [addCampaign, addData] = usePostCampaignMutation();
    const [editCampaign, updateData] = usePutCampaignMutation();
    const navigate = useNavigate();
    const location = useLocation();
    const currentLocale = useAppSelector(selectCurrentLocale);

    const [state, setState] = useState<ISettingsCampaignState>({
        campaignSearchQuery: "",
        viewCampaigns: [],
        currentCampaign: (location.state as { campaign: ICampaign | undefined })?.campaign ?? null,
        isNew: false,
    });

    const onCampaignSearch = (value: string) => {
        setState({ ...state, campaignSearchQuery: value });
    };

    const onCampaignClick = (campaign: ICampaign) => {
        if (state.currentCampaign?.id === campaign.id) return;
        setState({ ...state, currentCampaign: campaign });
        navigate(".", { state: { campaign } });
    };

    const onNewClick = () => {
        setState({ ...state, isNew: true, currentCampaign: null });
    };

    const initialValues = useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { images, ...rest } = state.currentCampaign ?? ({} as ICampaign);
        const defaultState = { name: "", description: "", startDate: formatISO(new Date()), endDate: null, active: true };
        return { ...defaultState, ...rest };
    }, [state.currentCampaign]);

    const validationSchema = yup.object<IAddCampaign>({
        name: yup.string().required(),
        description: yup.string().nullable(),
        startDate: yup.string().required(),
        endDate: yup.string().nullable(),
        active: yup.boolean().required(),
    });

    const formik = useFormik<IAddCampaign>({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            if (!state.currentCampaign) {
                await addCampaign(values);
            } else {
                await editCampaign({ ...values, id: state.currentCampaign.id });
            }
        },
    });

    const onDateRangeChange = (range: DateRange | undefined) => {
        const startDate = range?.from ?? new Date();
        const endDate = range?.to ?? null;

        formik.setFieldValue("startDate", startDate.toJSON());
        formik.setFieldValue("endDate", endDate ? endDate.toJSON() : null);
    };

    const range = {
        from: parseISO(formik.values.startDate),
        to: formik.values.endDate ? parseISO(formik.values.endDate) : undefined,
    };

    const getTimeperiod = () => {
        let footer = <p>Palun vali alguskuupäev.</p>;
        if (range?.from) {
            if (!range.to) {
                footer = (
                    <div>
                        <FormattedDate value={range.from} />
                    </div>
                );
            } else if (range.to) {
                footer = (
                    <p>
                        <FormattedDate value={range.from} />
                        {" - "}
                        <FormattedDate value={range.to} />
                    </p>
                );
            }
        }
        return footer;
    };

    const getError = (isError: boolean, error: typeof addData.error) => {
        if (!isError) return null;
        return (
            <div>
                {isError && (
                    <div className={"mt-3 text-primary d-flex align-items-center"}>
                        <CartzillaIcon icon={CartzillaIconName.Announcement} className={"me-2 fs-base align-middle"} />
                        <FormattedMessage id={error && "status" in error && error.status === 403 ? "nav.unauthorized" : "error.errorText"} />
                    </div>
                )}
            </div>
        );
    };

    const getFilteredData = () => {
        const data = campaigns.data ?? [];
        return searchItems<ICampaign>(campaignSearchProps, data, state.campaignSearchQuery);
    };

    const showPreview = () => {
        navigate(routes.home.path, { state: { campaign: state.currentCampaign } });
    };

    useEffect(() => {
        const viewCampaigns = getFilteredData();

        let currentCampaign = null;
        if (state.currentCampaign) {
            currentCampaign = campaigns.data?.find((c) => c.id === state.currentCampaign?.id) ?? null;
            navigate(".", { state: { campaign: currentCampaign } });
        }
        if (state.isNew) {
            currentCampaign = campaigns.data?.[0] ?? null;
        }
        setState({ ...state, viewCampaigns, currentCampaign, isNew: false });
    }, [campaigns.data]);

    useEffect(() => {
        const viewCampaigns = getFilteredData();
        setState({ ...state, viewCampaigns });
    }, [state.campaignSearchQuery]);

    return (
        <>
            <div className="d-none d-lg-flex justify-content-between align-items-center pt-lg-3 pb-4 pb-lg-5 mb-lg-3">
                <h4 className="fs-base text-light mb-0">Toodete tõlked</h4>
            </div>
            <LoadingWrapper isLoading={campaigns.isLoading} isError={campaigns.isError}>
                <Row className={"d-flex flex-column flex-md-row gap-3 g-0 flex-nowrap"}>
                    <Col xs={12} md={4} className={"d-flex flex-column rounded border"} style={{ height: "60vh" }}>
                        <div className={"border-bottom p-2 d-flex align-items-center gap-4"}>
                            <InputSearch value={state.campaignSearchQuery} onChange={onCampaignSearch} onClear={() => onCampaignSearch("")} />
                            <Button size={"sm"} onClick={onNewClick}>
                                Lisa
                            </Button>
                        </div>
                        <div className={"bg-secondary border-right flex-grow-1 h-100 overflow-scroll-y"}>
                            {state.viewCampaigns.map((c) => (
                                <div
                                    key={c.id}
                                    className={`p-2 border-bottom cursor-pointer ${
                                        state.currentCampaign?.id === c.id ? "border-primary bg-light" : "bg-secondary"
                                    }`}
                                    onClick={() => onCampaignClick(c)}
                                >
                                    <div className={`${state.currentCampaign?.id === c.id ? "fw-bold" : ""}`}>{c.name}</div>
                                    <div className={"fs-sm text-muted"}>
                                        <FormattedDate value={c.startDate} />
                                        {" - "}
                                        {c.endDate ? <FormattedDate value={c.endDate} /> : "..."}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Col>
                    <Col xs={12} md={7} className={"d-flex flex-column rounded border flex-grow-1"}>
                        <div className={"px-3 border-bottom m-0 d-flex justify-content-between align-items-center gap-5"}>
                            <h5 className={"m-0 py-3"}>Kampaania</h5>
                            {state.currentCampaign && state.currentCampaign.images.filter((i) => i.locale === currentLocale.replace("-", "_")).length > 0 && (
                                <Button size={"sm"} onClick={showPreview}>
                                    Eelvaade
                                </Button>
                            )}
                        </div>
                        {state.currentCampaign || state.isNew ? (
                            <LoadingWrapper isLoading={campaigns.isLoading} isError={campaigns.isError}>
                                <Form className={"d-flex flex-column flex-grow-1"} onSubmit={formik.handleSubmit}>
                                    <div className={"p-3 d-flex flex-column gap-3 bg-secondary flex-grow-1"}>
                                        <Row>
                                            <Col xs={12} md={7} className={"gap-2 d-flex flex-column"}>
                                                <Row>
                                                    <Form.Group as={Col} controlId="campaignName">
                                                        <Form.Label>Kampaania nimi</Form.Label>
                                                        <Form.Control
                                                            name="name"
                                                            type="text"
                                                            placeholder="Kampaania nimi..."
                                                            value={formik.values.name}
                                                            onChange={formik.handleChange}
                                                            isInvalid={formik.touched.name && !!formik.errors.name}
                                                        />
                                                        <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                                <Row>
                                                    <Form.Group as={Col} controlId="campaignDescription">
                                                        <Form.Label>Kampaania kirjeldus</Form.Label>
                                                        <Form.Control
                                                            name="description"
                                                            as="textarea"
                                                            placeholder="Kampaania kirjeldus..."
                                                            value={formik.values.description ?? ""}
                                                            rows={3}
                                                            onChange={formik.handleChange}
                                                            isInvalid={formik.touched.description && !!formik.errors.description}
                                                        />
                                                        <Form.Control.Feedback type="invalid">{formik.errors.description}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                                <Row>
                                                    <Form.Group as={Col} xs={8}>
                                                        <Form.Label>Periood</Form.Label>
                                                        {getTimeperiod()}
                                                        <Form.Control.Feedback type="invalid">{formik.errors.startDate}</Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">{formik.errors.endDate}</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group as={Col} xs={4} controlId={"isCampaignActive"}>
                                                        <Form.Label>Staatus</Form.Label>
                                                        <Form.Check
                                                            name="active"
                                                            label="Aktiivne"
                                                            checked={formik.values.active}
                                                            onChange={formik.handleChange}
                                                            isInvalid={formik.touched.active && !!formik.errors.active}
                                                        />
                                                        <Form.Control.Feedback type="invalid">{formik.errors.active}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                            </Col>
                                            <Col xs={12} md={5}>
                                                <DayPicker
                                                    id="test"
                                                    mode="range"
                                                    defaultMonth={new Date()}
                                                    selected={range}
                                                    onSelect={onDateRangeChange}
                                                    showOutsideDays
                                                    showWeekNumber
                                                    weekStartsOn={1}
                                                />
                                            </Col>
                                        </Row>
                                        {state.currentCampaign
                                            ? localeCodes.map((locale) => (
                                                  <CMSCampaignImages
                                                      key={locale}
                                                      locale={locale}
                                                      campaign={state.currentCampaign as ICampaign}
                                                      onChange={() => campaigns.refetch()}
                                                  />
                                              ))
                                            : null}
                                    </div>

                                    <div className={"d-flex justify-content-end gap-2 p-3 border-top"}>
                                        <LoadingSpinnerButton
                                            size={"sm"}
                                            icon={<CartzillaIcon icon={CartzillaIconName.AddDocument} className={"me-2"} />}
                                            isLoading={formik.isSubmitting}
                                            type={"submit"}
                                            disabled={!(formik.isValid && formik.dirty)}
                                        >
                                            Save changes
                                        </LoadingSpinnerButton>
                                    </div>
                                    {getError(addData.isError, addData.error)}
                                    {getError(updateData.isError, updateData.error)}
                                </Form>
                            </LoadingWrapper>
                        ) : (
                            <div className={"p-3 d-flex flex-column justify-content-center align-items-center flex-grow-1"}>Palun vali kampaania</div>
                        )}
                    </Col>
                </Row>
            </LoadingWrapper>
        </>
    );
};
