import { IProduct } from "../../../../models/api/IProduct";
import { ArchivedProductIndicator } from "./archived-product-icon";
import "./product-image.scss";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { NoImage, NoImageVariant } from "../no-image-placeholder/no-image";
import { IAppliance } from "models/api/IAppliance";
import { useState } from "react";

interface IProps {
    id: number | string;
    item?: IProduct | IAppliance | null | undefined;
    size: "sm" | "md" | "lg" | "auto";
    useZoom?: boolean;
    hasBorder?: boolean;
    fallbackVariant?: NoImageVariant;
    path?: "public/pictures" | "public/files";
    objectFit?: "contain" | "cover";
}

enum ImageState {
    Loading,
    Error,
    Success,
}

const isProduct = (item: IProduct | IAppliance): item is IProduct => (item as IProduct).code !== undefined;

export const ProductImage = ({
    id,
    item,
    size,
    useZoom = true,
    hasBorder = true,
    fallbackVariant,
    path = "public/pictures",
    objectFit = "contain",
}: IProps) => {
    const [state, setState] = useState<ImageState>(ImageState.Loading);

    const getImage = () => {
        return (
            <img
                src={`${process.env.REACT_APP_API_HOST}/api/v1/${path}/${id}`}
                className={"rounded w-100 h-100"}
                alt={"Card image"}
                onError={() => setState(ImageState.Error)}
                onLoad={() => setState(ImageState.Success)}
            />
        );
    };

    if ((item && !item.hasImage) || state === ImageState.Error) {
        return <NoImage variant={fallbackVariant} size={size} />;
    }

    return (
        <div
            key={id}
            className={`${
                hasBorder ? "border border-secondary" : ""
            } bg-opacity-25 rounded me-1 product-img product-img-${size} position-relative product-img-${objectFit} flex-shrink-0`}
        >
            {useZoom ? <Zoom>{getImage()}</Zoom> : getImage()}
            {item && isProduct(item) && !item.isAllowedForWeb ? <ArchivedProductIndicator /> : null}
        </div>
    );
};
