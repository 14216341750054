import en_GB from "./locales/en_GB.json";
import et_EE from "./locales/et_EE.json";
import fi_FI from "./locales/fi_FI.json";
import ru_RU from "./locales/ru_RU.json";

export type Locale = keyof typeof locales;

export const locales = {
    "en-GB": en_GB,
    "et-EE": et_EE,
    "fi-FI": fi_FI,
    "ru-RU": ru_RU,
};

export const localeCodes = Object.keys(locales) as Locale[];

export const fallbackLocale: Locale = "en-GB";
export const defaultLocale: Locale = "et-EE";
