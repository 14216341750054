import { Outlet } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectIsLoggedIn } from "store/slices/userSlice";
import { Button, Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { toggleLoginModal } from "store/slices/uiSlice";

export const ProtectedRoutes = () => {
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const dispatch = useAppDispatch();

    if (!isLoggedIn) {
        return (
            <Container as={"section"} className={"p-5 d-flex flex-column justify-content-center flex-grow-1 align-items-center"}>
                <span>
                    <FormattedMessage id={"nav.pleaseLogin"} />
                </span>
                <div className={"mt-4"}>
                    <Button size="sm" variant={"ghost"} onClick={() => dispatch(toggleLoginModal())}>
                        <span style={{ textDecoration: "underline" }}>
                            <FormattedMessage id={"nav.enter"} />
                        </span>
                    </Button>
                </div>
            </Container>
        );
    } else {
        return <Outlet />;
    }
};
