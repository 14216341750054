import { Container } from "react-bootstrap";
import { OfflineStatusAlert } from "./offline-status-alert";
import { LanguageSelector } from "./language-selector";
import { useAppSelector } from "../../../store/hooks";
import { selectCurrentClient } from "../../../store/slices/userSlice";
import { routes } from "../../../router";
import { Link } from "react-router-dom";

export const TopBar = () => {
    const currentClient = useAppSelector(selectCurrentClient);
    const disclaimer = process.env.REACT_APP_TOP_DISCLAIMER;

    return (
        <div className={`topbar topbar-dark bg-dark d-flex flex-column justify-content-center`}>
            {disclaimer && <div className={"text-primary fs-bold py-2"}>{disclaimer}</div>}
            <Container className={"justify-content-between align-items-center fs-xs"}>
                <Link to={routes.clients.path} className={"topbar-text"}>
                    {currentClient?.name}
                </Link>
                <div className={"ms-2 flex-shrink-0 d-flex flex-nowrap align-items-center"}>
                    <OfflineStatusAlert />
                    <LanguageSelector />
                </div>
            </Container>
        </div>
    );
};
