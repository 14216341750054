import { InputGroup, Form } from "react-bootstrap";
import { CartzillaIcon, CartzillaIconName } from "../icons/cartzilla-icon";
import { useIntl } from "react-intl";

type Props = {
    value: string;
    size?: "sm" | "lg";
    onChange: (value: string) => void;
    onClear?: () => void;
};
export const InputSearch = ({ onClear, onChange, size = "sm", value = "" }: Props) => {
    const intl = useIntl();
    const iconClasses = `position-absolute top-50 end-0 translate-middle-y text-muted fs-${size} me-3 cursor-pointer`;

    return (
        <InputGroup>
            <Form.Control
                placeholder={intl.formatMessage({ id: "products.search" })}
                size={size}
                onChange={(e) => onChange(e.currentTarget.value)}
                value={value}
                className={"rounded"}
            />
            {value && onClear ? (
                <CartzillaIcon icon={CartzillaIconName.Close} className={iconClasses} onClick={() => onClear()} />
            ) : (
                <CartzillaIcon icon={CartzillaIconName.Search} className={iconClasses} />
            )}
        </InputGroup>
    );
};
