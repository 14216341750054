import React, { useContext } from "react";
import { AccordionContext, useAccordionButton } from "react-bootstrap";

interface IProps {
    children: React.ReactNode;
    eventKey: string;
    set: () => void;
    className: string;
}

export const CategoryToggle = ({ children, eventKey, set, className: extraClasses }: IProps & React.HTMLProps<HTMLElement>) => {
    const { activeEventKey } = useContext(AccordionContext);
  
    const toggleDropdown = useAccordionButton(eventKey, undefined);
  
    const isCurrentEventKey = activeEventKey === eventKey;
  
    return (
        <h2 className={`accordion-header ${extraClasses} cursor-none`}>
            <button
                type="button"
                className={`accordion-button ${!isCurrentEventKey ? 'collapsed' : ''} d-flex justify-content-between`}
            >
                <div onClick={set}>{children}</div>
                <span className={"accordion-button-icon"} onClick={toggleDropdown}></span>
            </button>
      </h2>
      
    );
  }