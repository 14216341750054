import { Container, Row, Col, Accordion } from "react-bootstrap";
import { PageTitleBlock } from "../components/layout/page-title-block";
import { useEffect, useState } from "react";
import { IProduct } from "../models/api/IProduct";
import { IOrder } from "../models/api/IOrder";
import { useGetOrderHistoryQuery } from "../api/ecosternApi";
import { useAppSelector } from "../store/hooks";
import { selectCurrentClientId } from "../store/slices/userSlice";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { IPageableState } from "../models/content/pagination/IPageableState";
import { PageBottomPagination } from "../components/pagination/page-bottom-pagination";
import { getPageItems } from "../components/pagination/helpers";
import { LoadingWrapper } from "../components/layout/loading-wrapper";
import { OrderAccordionItem } from "../components/order/order-accordion-item";
import { searchItems } from "../utils/utils";
import { WithPrefix } from "../types/TWithPrefix";
import { CartzillaIcon, CartzillaIconName } from "../components/ui/icons/cartzilla-icon";
import { FormattedMessage } from "react-intl";
import { useSearch } from "components/header/navigation/search-bar";
import { useAnalytics } from "hooks/use-analytics";

interface IOrdersViewState extends IPageableState {
    orders: IOrder[];
}

type OrderRowSearchProps = keyof WithPrefix<IProduct, "rows.product.">;

export const OrdersView = () => {
    const selectedClientId = useAppSelector(selectCurrentClientId);
    const { data: orderHistoryResult, isLoading: isOrdersLoading, isError: isOrdersError } = useGetOrderHistoryQuery(selectedClientId ?? skipToken);
    const { query } = useSearch();
    const searchOptions = {
        threshold: 0.2,
    };
    const searchProps: (keyof IOrder | OrderRowSearchProps)[] = ["id", "date", "rows.product.name"];
    const { track } = useAnalytics();
    const [state, setState] = useState<IOrdersViewState>({
        orders: [],
        totalItems: 0,
        currentPage: 1,
        pageSize: 30,
    });

    useEffect(() => {
        let mounted = true;

        if (orderHistoryResult && mounted) {
            const orders = searchItems<IOrder, OrderRowSearchProps>(searchProps, orderHistoryResult, query, searchOptions);

            setState({ ...state, orders, totalItems: orders.length, currentPage: 1 });
        }

        return () => {
            mounted = false;
        };
    }, [orderHistoryResult, query]);

    useEffect(() => {
        if (!query) return;
        track("search", { props: { search_query: query } });
    }, [query]);

    const onPageClick = (pageNumber: number) => {
        setState({ ...state, currentPage: pageNumber });
    };

    return (
        <>
            <PageTitleBlock routeName={"orders"} />
            <LoadingWrapper isLoading={isOrdersLoading} isError={isOrdersError}>
                <Container className={"pb-5 mb-2 mt-4 mb-md-4"}>
                    <Row>
                        <Col as={"section"}>
                            <Row className={"mx-n2"}>
                                <Col>
                                    <Row className={"mb-3 align-items-center ps-3 fs-sm fs-md-normal"} style={{ paddingRight: "3.375rem" }}>
                                        <Col xs={3} md={2}>
                                            <FormattedMessage id={"orderContents.code"} />
                                        </Col>
                                        <Col xs={3} md={2}>
                                            <CartzillaIcon icon={CartzillaIconName.Time} className={"me-2 d-md-none"} />
                                            <div className={"d-none d-md-inline"}>
                                                <FormattedMessage id={"history.date"} />
                                            </div>
                                        </Col>
                                        <Col md={4} className={"d-none d-md-flex"}></Col>
                                        <Col xs={2} md={1}>
                                            #
                                        </Col>
                                        <Col xs={4} md={2}>
                                            <FormattedMessage id={"history.total"} />
                                        </Col>
                                    </Row>

                                    <Accordion>
                                        {getPageItems(state.orders, state.currentPage, state.pageSize).map((o) => (
                                            <OrderAccordionItem order={o} key={o.id} />
                                        ))}
                                    </Accordion>
                                </Col>
                            </Row>
                            <hr className="my-3" />
                            <PageBottomPagination
                                totalItems={state.totalItems}
                                currentPage={state.currentPage}
                                pageSize={state.pageSize}
                                onPageClick={onPageClick}
                            />
                        </Col>
                    </Row>
                </Container>
            </LoadingWrapper>
        </>
    );
};
