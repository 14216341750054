import { Accordion, Row, Col, Badge } from "react-bootstrap";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";
import { IOrder } from "../../models/api/IOrder";
import { TableHeaderRow } from "../ui/table/table-header-row";
import { ProductImage } from "../ui/images/product-image/product-image";
import { useAddExistingOrderToCartMutation } from "../../api/ecosternApi";
import { LoadingSpinnerButton } from "../ui/buttons/loading-spinner-button";
import { IExistingOrderToCartRequestItems } from "../../models/api/IExistingOrderToCartRequestItems";
import { selectCartProducts } from "../../store/slices/cartSlice";
import { useAppSelector } from "../../store/hooks";
import { OrderAccordionRow } from "./order-accordion-row";
import { selectIsUserEcostern } from "store/slices/userSlice";

interface IProps {
    order: IOrder;
}

export const OrderAccordionItem = ({ order }: IProps) => {
    const [addExistingOrderItemsToCart, { isLoading }] = useAddExistingOrderToCartMutation();
    const cartProducts = useAppSelector(selectCartProducts);
    const isUserEcostern = useAppSelector(selectIsUserEcostern);

    const onOrderAction = (orderAction: IExistingOrderToCartRequestItems["action"]) => {
        const existingOrderToCartItems: IExistingOrderToCartRequestItems = {
            orderId: order.id,
            action: orderAction,
        };

        addExistingOrderItemsToCart(existingOrderToCartItems);
    };

    const getOrderSum = () => {
        return Math.round(order.rows.reduce((prev, current) => prev + current.price * current.quantity, 0) * 100) / 100;
    };

    const getOrderCopiesInCartBadge = () => {
        let orderProductsInCart = 0;
        let orderProductsInOrder = 0;
        const noTransportRows = order.rows.filter((or) => or.productId !== -4);

        for (const row of noTransportRows) {
            const cartProduct = cartProducts.find((p) => p.productId == row.productId);
            // If a product doesn't exist no full copy of the order is possible
            if (!cartProduct) return <></>;

            // If product cart quantity and product row quantity are not divisible,
            // then they are not exact copies.
            const reminder = cartProduct.quantity % row.quantity;
            if (reminder !== 0) return <></>;

            orderProductsInCart += cartProduct.quantity;
            orderProductsInOrder += row.quantity;
        }

        const cartReminder = orderProductsInCart % orderProductsInOrder;
        if (cartReminder !== 0) return <></>;

        const countOfOrderCopiesInCart = orderProductsInCart / orderProductsInOrder;

        if (countOfOrderCopiesInCart == 0) return <></>;
        return (
            <Badge pill bg={"secondary"} className={"ms-2 "}>
                <FormattedMessage id={"history.addContentToCartCopiesCount"} values={{ countOfOrderCopiesInCart }} />
            </Badge>
        );
    };

    const getOrderComment = () => {
        if (!order.comment) return <></>;

        // Updated delivery instructions and comment information are stored in the same field
        // and separated by pipe '|' when both are provided with the order.
        // E.g. Tarnejuhise muudatus: xyz | Kommentaar: abc
        const instructionsLabel = "Tarnejuhise muudatus:";
        const commentLabel = "Kommentaar:";
        const separator = "|";

        const hasInstructionsLabel = order.comment.includes(instructionsLabel);
        const hasCommentLabel = order.comment.includes(commentLabel);
        const instructions = hasInstructionsLabel ? order.comment.split(separator)[0].replace(instructionsLabel, "").trim() : "";
        const comment = hasInstructionsLabel && hasCommentLabel ? order.comment.split(separator)[1].replace(commentLabel, "").trim() : order.comment;

        return (
            <>
                {instructions && (
                    <div className={"py-2"}>
                        <div className={"fw-bold me-2"}>
                            <FormattedMessage id={"orderContents.instructionsChange"} />
                        </div>
                        <div>{instructions}</div>
                    </div>
                )}
                {comment && (
                    <div className={"py-2"}>
                        <div className={"fw-bold me-2"}>
                            <FormattedMessage id={"orderContents.comment"} />
                        </div>
                        <div>{comment}</div>
                    </div>
                )}
            </>
        );
    };

    const onEntered = (node: HTMLElement /* , _isAppearing: boolean */) => {
        const headerHeight = document.querySelector("header")?.offsetHeight ?? 0;
        const accordionHeaderHeight = (node.previousElementSibling as HTMLElement | undefined)?.offsetHeight ?? 0;
        window.scroll({
            top: node.offsetTop - headerHeight - accordionHeaderHeight,
            behavior: "smooth",
        });
    };

    return (
        <Accordion.Item eventKey={order.id}>
            <Accordion.Header>
                <Row className={"align-items-center flex-nowrap w-100 fs-sm"}>
                    <Col xs={3} md={2}>
                        {order.id}
                    </Col>
                    <Col xs={3} md={2}>
                        <FormattedDate value={order.date} year={"2-digit"} month={"2-digit"} day={"2-digit"} />
                    </Col>
                    <Col xs={0} md={4} className={"d-none d-md-flex"}>
                        <div className={"d-flex"}>
                            {order.rows
                                .filter((p) => p.productId !== -4)
                                .slice(0, 10)
                                .map((row, i) => (
                                    <ProductImage key={`${row.productId}-${i}`} id={row.productId} item={row.product} size={"sm"} useZoom={false} />
                                ))}
                        </div>
                    </Col>
                    <Col xs={2} md={1}>
                        {order.rows.length}
                    </Col>
                    <Col xs={4} md={2}>
                        <FormattedNumber value={getOrderSum()} style={"currency"} currency={"EUR"} />
                    </Col>
                </Row>
            </Accordion.Header>
            <Accordion.Body onEntered={onEntered}>
                <>
                    <TableHeaderRow key={"header"}>
                        <Col md={5}>
                            <Row>
                                <Col className={"d-none d-md-block"} md={{ span: "auto", offset: 4 }}>
                                    <FormattedMessage id={"orderContents.name"} />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col className={"d-none d-md-block"}>
                                    <FormattedMessage id={"orderContents.code"} />
                                </Col>
                                <Col className={"d-none d-md-block"}>
                                    <FormattedMessage id={"orderContents.quantity"} />
                                </Col>
                                <Col className={"d-none d-md-block"}>
                                    <FormattedMessage id={"orderContents.price"} />
                                </Col>
                                <Col className={"d-none d-md-block"}>
                                    <FormattedMessage id={"orderContents.total"} />
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={1}></Col>
                    </TableHeaderRow>
                    {order.rows.map((r) => (
                        <OrderAccordionRow key={r.productId} row={r} />
                    ))}
                    <hr />
                    <TableHeaderRow key={"totals"}>
                        <Col md={5}></Col>
                        <Col md={6}>
                            <Row>
                                <Col>
                                    <FormattedMessage id={"history.total"} />
                                </Col>
                                <Col className={"d-none d-md-block"}>{order.rows.reduce((prev, current) => prev + current.quantity, 0)}</Col>
                                <Col></Col>
                                <Col>
                                    <FormattedNumber value={getOrderSum()} style={"currency"} currency={"EUR"} />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={1}></Col>
                    </TableHeaderRow>
                    <Row className={"my-4"}>
                        <Col xs={12}>
                            {isUserEcostern && order.comment ? getOrderComment() : null}
                            {order.orderedBy ? (
                                <div className={"py-2"}>
                                    <div className={"fw-bold me-2"}>
                                        <FormattedMessage id={"orderContents.orderedBy"} />
                                    </div>
                                    <div>{order.orderedBy}</div>
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                    <Row className={"mt-4 justify-content-end"}>
                        <Col xs={12} md={4}>
                            <LoadingSpinnerButton
                                isLoading={isLoading}
                                size={"sm"}
                                className={"me-md-2 mb-2 mb-md-0 w-100 w-md-auto"}
                                onClick={() => onOrderAction("add")}
                            >
                                <FormattedMessage id={"history.addContentToCart"} />
                                {getOrderCopiesInCartBadge()}
                            </LoadingSpinnerButton>
                        </Col>
                        <Col xs={12} md={4}>
                            <LoadingSpinnerButton isLoading={isLoading} size={"sm"} className={"w-100 w-md-auto"} onClick={() => onOrderAction("replace")}>
                                <FormattedMessage id={"history.replaceCart"} />
                            </LoadingSpinnerButton>
                        </Col>
                    </Row>
                </>
            </Accordion.Body>
        </Accordion.Item>
    );
};
