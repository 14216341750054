// See possible icons at

import React from "react";

// https://cartzilla.createx.studio/components/icon-font.html
export enum CartzillaIconName {
    CloudDownload = "ci-cloud-download",
    Cart = "ci-cart",
    Lable = "ci-lable",
    Home = "ci-home",
    Close = "ci-close",
    CloseCircle = "ci-close-circle",
    Announcement = "ci-announcement",
    Card = "ci-card",
    FilterAlt = "ci-filter-alt",
    Search = "ci-search",
    Menu = "ci-menu",
    User = "ci-user",
    Settings = "ci-settings",
    SignOut = "ci-sign-out",
    AddUser = "ci-add-user",
    ArrowLeft = "ci-arrow-left",
    ArrowRight = "ci-arrow-right",
    ArrowUp = "ci-arrow-up",
    ArrowDown = "ci-arrow-down",
    List = "ci-list",
    Location = "ci-location",
    Delivery = "ci-delivery",
    Time = "ci-time",
    Image = "ci-image",
    FullScreen = "ci-full-screen",
    Truck = "ci-truck",
    Check = "ci-check",
    Share = "ci-share",
    Book = "ci-book",
    Add = "ci-add",
    ArrowUpCircle = "ci-arrow-up-circle",
    ArrowDownCircle = "ci-arrow-down-circle",
    AddDocument = "ci-add-document",
    CloudCheck = "ci-cloud-check",
    Loading = "ci-loading",
    Store = "ci-store",
    Basket = "ci-basket",
}

interface IProps {
    icon: CartzillaIconName;
    className?: string;
}

export const CartzillaIcon = ({ icon, className: classes, ...props }: IProps & React.HTMLProps<HTMLElement>) => {
    return <i className={`${icon} ${classes || ""}`} {...props}></i>;
};
