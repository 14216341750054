import { useState, useCallback } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { ICartProduct } from "../../models/api/ICartProduct";
import { useAppSelector } from "../../store/hooks";
import { selectCartProducts } from "../../store/slices/cartSlice";
import { useAddCartProductMutation, useRemoveCartProductMutation } from "../../api/ecosternApi";
import { getTotalSum } from "../../utils/product.utils";
import { debounce } from "../../utils/utils";
import { LoadingSpinnerButton } from "../ui/buttons/loading-spinner-button";
import { CartzillaIcon, CartzillaIconName } from "../ui/icons/cartzilla-icon";
import { ArchivedProductIndicator } from "../ui/images/product-image/archived-product-icon";
import { ProductImage } from "../ui/images/product-image/product-image";

interface IProps {
    cartProduct: ICartProduct;
    isOutOfStock?: boolean;
}

interface ICartProductState {
    quantity: string | null;
}

export const CartProduct = ({ cartProduct, isOutOfStock }: IProps) => {
    const [removeCartProduct, { isLoading: isRemoveLoading }] = useRemoveCartProductMutation();
    const [addCartProduct, { isLoading: isAddCartProductLoading }] = useAddCartProductMutation();
    const cartProducts = useAppSelector(selectCartProducts);
    const intl = useIntl();

    const [state, setState] = useState<ICartProductState>({
        quantity: null,
    });

    const debouncedUpdateProductQuantity = useCallback(
        debounce((val: string) => {
            const newQuantity = parseInt(val);
            if (isNaN(newQuantity)) return;

            const product = cartProducts.filter((p) => p.productId == cartProduct.productId)[0];
            if (!product) return;

            const newCartProduct = { ...product };
            newCartProduct.quantity = newQuantity > 0 ? newQuantity : 0;
            addCartProduct(newCartProduct);
        }, 500),
        []
    );

    const onProductQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value;
        setState({ ...state, quantity: val });
        debouncedUpdateProductQuantity(val);
    };

    const onProductQuantityClick = (i: -1 | 1) => {
        const currentQuantity = typeof state.quantity === "string" ? parseInt(state.quantity) : cartProduct.quantity;
        const quantity = currentQuantity + i;
        if (isNaN(quantity) || (currentQuantity <= 1 && quantity < currentQuantity)) return;
        setState({ ...state, quantity: quantity.toString() });
        debouncedUpdateProductQuantity(quantity.toString());
    };

    return (
        <Row className={"align-items-start pb-5"}>
            <Col xs={3} md={"auto"}>
                <ProductImage item={cartProduct.product} id={cartProduct.productId} size={"lg"} />
            </Col>
            <Col xs={8} md={4} className={"flex-grow-1"}>
                <div>
                    <h3 className={"product-title fs-base mb-1"}>{cartProduct.product?.name}</h3>
                    <div className={"fs-sm"}>
                        <span className={"text-muted me-2"}>Kood:</span>
                        <span>{cartProduct.product?.code}</span>
                    </div>
                    <div className={"fs-sm pb-1"}>
                        <span className={"text-muted me-2"}>Hind:</span>
                        <FormattedNumber value={cartProduct.product?.price || 0} style={"currency"} currency={"EUR"} />
                    </div>
                    <div className={"d-flex d-md-none pt-2 d-flex align-items-center"}>
                        <span className={"text-muted fs-sm me-2"}>Summa</span>
                        <div className={"text-accent"}>
                            <FormattedNumber value={getTotalSum(cartProduct.quantity, cartProduct.product?.price)} style={"currency"} currency={"EUR"} />
                        </div>
                    </div>
                    {isOutOfStock && !(!cartProduct.product || !cartProduct.product.isAllowedForWeb) ? (
                        <div className={"text-muted fs-sm d-flex align-items-center"}>
                            <CartzillaIcon icon={CartzillaIconName.Announcement} />
                            <span className={"ms-1"}>
                                &nbsp; <FormattedMessage id={"orderContents.stockSmallerThanOrderAmount"} />
                            </span>
                        </div>
                    ) : null}
                    {!cartProduct.product ||
                        (!cartProduct.product.isAllowedForWeb && (
                            <ArchivedProductIndicator message={intl.formatMessage({ id: "orderContents.productArchived" })} variant={"text"} />
                        ))}
                </div>
            </Col>
            <Col md={2} className={"d-none d-md-flex flex-md-column"}>
                <h3 className={"product-title fs-base pt-2 pt-md-0 me-2"}>Summa</h3>
                <div className={"fs-lg text-accent mt-1"}>
                    <FormattedNumber value={getTotalSum(cartProduct.quantity, cartProduct.product?.price)} style={"currency"} currency={"EUR"} />
                </div>
            </Col>
            <Col
                xs={{ span: 5, offset: 3 }}
                md={{ span: 2, offset: 0 }}
                className={"d-flex flex-md-column align-items-center justify-content-start mt-2 mt-md-0"}
            >
                <h3 className={"product-title fs-base me-2 d-none d-md-flex"}>Kogus</h3>
                <div className={"d-flex"}>
                    <LoadingSpinnerButton
                        variant={"secondary"}
                        className={"me-1"}
                        size={"sm"}
                        onClick={() => onProductQuantityClick(-1)}
                        isLoading={isAddCartProductLoading}
                        disabled={(typeof state.quantity === "string" ? parseInt(state.quantity) : cartProduct.quantity) <= 1}
                    >
                        {!isAddCartProductLoading && "-"}
                    </LoadingSpinnerButton>
                    <Form.Control
                        type={"number"}
                        style={{ width: "3rem" }}
                        min={1}
                        size={"sm"}
                        value={typeof state.quantity === "string" ? state.quantity : cartProduct.quantity}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onProductQuantityChange(e)}
                    />
                    <LoadingSpinnerButton
                        variant={"secondary"}
                        size={"sm"}
                        className={"ms-1"}
                        onClick={() => onProductQuantityClick(1)}
                        isLoading={isAddCartProductLoading}
                    >
                        {!isAddCartProductLoading && "+"}
                    </LoadingSpinnerButton>
                </div>
            </Col>
            <Col xs={4} md={2} className={"align-self-start d-flex justify-content-end align-items-center mt-1 mt-md-4"}>
                <LoadingSpinnerButton
                    isLoading={isRemoveLoading}
                    icon={<CartzillaIcon icon={CartzillaIconName.CloseCircle} />}
                    onClick={() => removeCartProduct(cartProduct.productId)}
                    variant={"link"}
                    className={"px-0"}
                >
                    <span className="fs-sm">
                        <FormattedMessage id={"product.productRemove"} />
                    </span>
                </LoadingSpinnerButton>
            </Col>
        </Row>
    );
};
