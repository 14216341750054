import useIsOnScreen from "hooks/use-is-on-screen";
import { useEffect, useRef } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { Link } from "react-router-dom";
import { routes } from "router";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectCartProductsCount, selectCartProductsSum } from "store/slices/cartSlice";
import { selectHasFilterCategory, setIsHandheldToolbarVisible, toggleMenu, toggleStoreFilters } from "store/slices/uiSlice";
import { selectCurrentClientId } from "store/slices/userSlice";
import { CartzillaIcon, CartzillaIconName } from "components/ui/icons/cartzilla-icon";
import { Searchable } from "components/header/navigation/search-bar";

interface IProps {
    onSearch?: () => void;
}

export const HandheldToolbar = ({ onSearch }: IProps) => {
    const clientId = useAppSelector(selectCurrentClientId);
    const cartProductsCount = useAppSelector(selectCartProductsCount);
    const cartProductsSum = useAppSelector(selectCartProductsSum);
    const hasFilter = useAppSelector(selectHasFilterCategory);
    const toolbarRef = useRef<HTMLDivElement>(null);
    const isHandheldToolbarVisible = useIsOnScreen(toolbarRef);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setIsHandheldToolbarVisible(isHandheldToolbarVisible));
    }, [isHandheldToolbarVisible]);

    return (
        <div className={"handheld-toolbar"} ref={toolbarRef}>
            <div className="d-table table-layout-fixed w-100">
                {location.pathname === routes.products.path && clientId && (
                    <a className="d-table-cell handheld-toolbar-item" onClick={() => dispatch(toggleStoreFilters())}>
                        <span className="handheld-toolbar-icon">
                            <CartzillaIcon icon={CartzillaIconName.FilterAlt} />
                            {hasFilter && (
                                <span className="bg-primary ms-1 d-inline-block" style={{ width: "0.5rem", height: "0.5rem", borderRadius: "100%" }} />
                            )}
                        </span>
                        <span className="handheld-toolbar-label">
                            <FormattedMessage id={"nav.filters"} />
                        </span>
                    </a>
                )}
                {[routes.home.path, routes.cart.path, routes.orders.path, routes.invoices.path].includes(location.pathname) && clientId && (
                    <Link to={routes.products.path} className="d-table-cell handheld-toolbar-item">
                        <span className="handheld-toolbar-icon">
                            <CartzillaIcon icon={CartzillaIconName.Basket} />
                        </span>
                        <span className="handheld-toolbar-label">
                            <FormattedMessage id={"nav.products"} />
                        </span>
                    </Link>
                )}
                <Searchable>
                    <a className="d-table-cell handheld-toolbar-item" onClick={() => onSearch?.()}>
                        <span className="handheld-toolbar-icon">
                            <CartzillaIcon icon={CartzillaIconName.Search} />
                        </span>
                        <span className="handheld-toolbar-label">
                            <FormattedMessage id={"products.search"} />
                        </span>
                    </a>
                </Searchable>
                <a onClick={() => dispatch(toggleMenu())} className="d-table-cell handheld-toolbar-item collapsed">
                    <span className="handheld-toolbar-icon">
                        <CartzillaIcon icon={CartzillaIconName.Menu} />
                    </span>
                    <span className="handheld-toolbar-label">
                        <FormattedMessage id={"nav.menu"} />
                    </span>
                </a>
                {clientId && (
                    <Link to={routes.cart.path} className="d-table-cell handheld-toolbar-item">
                        <span className="handheld-toolbar-icon">
                            <CartzillaIcon icon={CartzillaIconName.Cart} />
                            {cartProductsCount > 0 && <span className="badge bg-primary rounded-pill ms-1">{cartProductsCount}</span>}
                        </span>
                        <span className="handheld-toolbar-label">
                            <FormattedNumber value={cartProductsSum} style={"currency"} currency={"EUR"} />
                        </span>
                    </Link>
                )}
            </div>
        </div>
    );
};
