import { IntlShape } from "react-intl";
import { ECampaignType } from "../models/api/enums/ECampaignType";
import { ICampaignAttributes } from "../models/api/ICampaignAttributes";
import { IProduct, IStoreProduct } from "../models/api/IProduct";
import { UIAttributes } from "models/content/product/UIProduct";

export const getTotalSum = (quantity = 1, price = 0) => {
    return Math.round(price * quantity * 100) / 100;
};

export const getProductCodesInReminder = (product: IStoreProduct) => {
    if (!product.reminder) return null;
    const r1 = product.reminder?.match(/\S*\d{4,}\S*|\S\w*-\S*/g);
    return r1;
};

export const getUIAttributes = (product: IProduct, intl: IntlShape): UIAttributes => {
    const campaignAttributes = temp__getCampaignAttributes(product);
    const badges = [];
    let campaign;

    if (product.attributes.includes("eco")) {
        badges.push({ name: "eco", label: intl.formatMessage({ id: "product.eco.label" }) });
    }

    if (product.tag?.includes("uus")) {
        badges.push({ name: "new", label: intl.formatMessage({ id: "product.new.label" }) });
    }

    if (campaignAttributes) {
        campaign = {
            type: campaignAttributes.type,
            label: intl.formatMessage({ id: `product.campaign.${campaignAttributes.type}` }),
        };
    }

    return {
        campaign,
        productName: campaignAttributes != null ? campaignAttributes.productName : undefined,
        badges,
    };
};

/**
 * The 'temp__' prefixed methods are used to parse product info for
 * additional contextual details. The 'temp__' prefix hereby indicates that
 * these methods are not sustainable, will likely break and are meant as temporary
 * solutions until backend provides necessary information.
 */
export const temp__getCampaignAttributes = (product: IProduct): ICampaignAttributes | null => {
    const endOfLifecycleSale = temp__getEndOfLifecycleSale(product);

    if (endOfLifecycleSale) return endOfLifecycleSale;

    const preOrderOnly = temp__getPreOrderOnly(product);
    if (preOrderOnly) return preOrderOnly;

    const regularCampaign = temp__getCampaign(product);
    if (regularCampaign) return regularCampaign;

    return null;
};

const temp__getEndOfLifecycleSale = (product: IProduct): ICampaignAttributes | null => {
    // Atm product end of lifecycle sale is indicated in product name.
    const productWillBeArchivedKeywords = ["LÕPUMÜÜK!", "LÕPUMÜÜK", "- LM"];

    return temp__getCampaignFromName(product, productWillBeArchivedKeywords, ECampaignType.EndOfLifeCycleSale);
};

const temp__getPreOrderOnly = (product: IProduct): ICampaignAttributes | null => {
    const badgeKeyWords = ["ETTE TELLIMISEGA!", "ETTE TELLIMISEGA", "ETTE TELLIMISEL"];

    return temp__getCampaignFromName(product, badgeKeyWords, ECampaignType.PreOrderOnly);
};

const temp__getCampaign = (product: IProduct): ICampaignAttributes | null => {
    const badgeKeyWords = ["KAMPAANIA!", "KMP!"];

    return temp__getCampaignFromName(product, badgeKeyWords, ECampaignType.RegularCampaign);
};

const temp__getCampaignFromName = (product: IProduct, keywords: string[], type: ECampaignType): ICampaignAttributes | null => {
    for (const keyWord of keywords) {
        if (!product.name.startsWith(keyWord) && !product.name.endsWith(keyWord)) continue;

        return {
            type,
            productName: product.name.replace(keyWord, "").trim(),
        };
    }

    return null;
};

export const temp__getItemsInPackage = (product: IProduct): string => {
    const r1 = product.info?.match(/Kastis: (\d+) ([p|t]k)/i);
    if (r1 && r1.length > 1) return `${r1[1]} ${r1[2]}/kast`;

    const r2 = product.info?.match(/(\d+) rulli kastis/i);
    if (r2 && r2.length > 0) return r2[1] + " rl/kast";

    return "";
};
