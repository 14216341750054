import { Navbar, Container, Dropdown } from "react-bootstrap";
import { UserNav } from "./user-nav";
import { CartNav } from "./cart-nav";
import { PageNav } from "./page-nav";
import { Logo } from "./logo/logo";
import { SearchBar, Searchable } from "./search-bar";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectIsMenuOpen, toggleMenu } from "store/slices/uiSlice";
import { useCallback, useEffect, useRef, useState } from "react";
import { CartzillaIcon, CartzillaIconName } from "components/ui/icons/cartzilla-icon";
import { createPortal } from "react-dom";
import { HandheldToolbar } from "../handheld-toolbar/handheld-toolbar";
import { useMediaQuery } from "hooks/use-media-query";
import { useLocation } from "react-router-dom";
import { useGetPendingInvoicesQuery } from "api";
import { selectCurrentClientId } from "store/slices/userSlice";
import { skipToken } from "@reduxjs/toolkit/dist/query";

interface INavigationState {
    isSearchOpen: boolean;
    prevPath: string;
}

export const Navigation = () => {
    const location = useLocation();
    const [state, setState] = useState<INavigationState>({ isSearchOpen: false, prevPath: location.pathname });
    const isMenuOpen = useAppSelector(selectIsMenuOpen);
    const dispatch = useAppDispatch();
    const searchRef = useRef<HTMLInputElement | null>(null);
    const navRef = useRef<HTMLDivElement>(null);
    const openDistance = useRef(0);
    const menuAutoCloseDistance = 50;
    const isMobile = useMediaQuery("(max-width: 992px)");
    const isReadonlyWhenInactive = useMediaQuery("(max-width: 768px)");
    const selectedClientId = useAppSelector(selectCurrentClientId);
    const { data: pendingInvoices = [] } = useGetPendingInvoicesQuery(selectedClientId ?? skipToken);
    const pendingInvoicesCount = pendingInvoices.length;

    useEffect(() => {
        return () => {
            window.removeEventListener("scroll", closeMenuOnScrollListener);
        };
    }, [isMenuOpen]);

    useEffect(() => {
        if (state.isSearchOpen) {
            if (searchRef.current) {
                searchRef.current.readOnly = false;
            }
            searchRef.current?.focus();
            window.addEventListener("scroll", closeSearchOnScrollListener);
        }

        return () => {
            window.removeEventListener("scroll", closeSearchOnScrollListener);
        };
    }, [state]);

    useEffect(() => {
        if (state.prevPath === location.pathname) return;
        setState({ ...state, isSearchOpen: false, prevPath: location.pathname });
    }, [location]);

    useEffect(() => {
        if (!isReadonlyWhenInactive && searchRef.current) {
            searchRef.current.readOnly = false;
        }
    }, [isReadonlyWhenInactive]);

    const closeSearchOnScrollListener = useCallback(() => {
        if (window.scrollY > openDistance.current + menuAutoCloseDistance) {
            setState({ ...state, isSearchOpen: false });
            searchRef.current?.blur();
            if (searchRef.current) {
                searchRef.current.readOnly = true;
            }
        }
    }, [state]);

    const closeMenuOnScrollListener = useCallback(() => {
        if (window.scrollY > openDistance.current + menuAutoCloseDistance) {
            dispatch(toggleMenu());
        }
    }, []);

    const onEntered = () => {
        if (isMenuOpen) {
            openDistance.current = window.scrollY;
            window.addEventListener("scroll", closeMenuOnScrollListener);
        }
    };

    const onSearchToggle = () => {
        const isSearchOpen = !state.isSearchOpen;
        openDistance.current = window.scrollY;
        if (searchRef.current) {
            searchRef.current.readOnly = !isSearchOpen;
        }
        setState({ ...state, isSearchOpen });
    };

    return (
        <Navbar bg={"light"} expand={"lg"} expanded={isMenuOpen} onToggle={() => dispatch(toggleMenu())} className={"flex-lg-column pb-lg-1"}>
            <Container className={"flex-wrap"}>
                <div className={"relative d-flex w-100 flex-shrink-0 navbar-header align-items-center gap-1 gap-md-3"}>
                    <Logo />
                    <div className={"d-flex flex-grow-1 align-items-center justify-content-end gap-3"}>
                        <Searchable>
                            <SearchBar ref={searchRef} isMobile={isMobile} className={`d-flex w-${state.isSearchOpen ? "100" : "0"} w-md-50 me-auto`} />
                        </Searchable>
                        <div className={"d-flex justify-content-end navbar-toolbar"}>
                            <Searchable>
                                <div className={"navbar-tool"} onClick={onSearchToggle}>
                                    <Dropdown.Toggle as={"div"} className={"navbar-tool-icon-box d-flex d-md-none dropdown"}>
                                        {state.isSearchOpen ? (
                                            <CartzillaIcon icon={CartzillaIconName.ArrowRight} className={"fs-sm navbar-tool-icon w-100"} />
                                        ) : (
                                            <CartzillaIcon icon={CartzillaIconName.Search} className={"navbar-tool-icon w-100"} />
                                        )}
                                    </Dropdown.Toggle>
                                </div>
                            </Searchable>
                            <div className={`${state.isSearchOpen ? "d-none" : "d-flex"} d-lg-flex`}>
                                <CartNav />
                                <UserNav />
                                <Navbar.Toggle aria-controls={"navigation-toggle"} className={"navbar-tool position-relative"}>
                                    {pendingInvoicesCount > 0 && <span className={"navbar-tool-label"}>{pendingInvoicesCount}</span>}
                                    <span className={"navbar-toggler-icon"}></span>
                                </Navbar.Toggle>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <Navbar.Collapse ref={navRef} id={"navigation"} className={"px-3 w-100 container"} onEntered={onEntered}>
                <PageNav />
            </Navbar.Collapse>
            {createPortal(
                <HandheldToolbar onSearch={onSearchToggle} />,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                document.getElementById("root")!
            )}
        </Navbar>
    );
};
