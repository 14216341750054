import { useEffect, useState } from "react";

export enum ScrollDirection {
    Up = "scroll-up",
    Down = "scroll-down",
    Unknown = "scroll-unknown",
}

/**
 * Code by Codemzy @ https://www.codemzy.com/blog/react-hook-scroll-direction-event-listener
 * Modified for TS
 */
export function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(ScrollDirection.Unknown);

    useEffect(() => {
        let lastScrollY = window.scrollY;
        let lastContentHeight = document.body.clientHeight;
        const updateScrollDirection = () => {
            const scrollY = window.scrollY;
            const contentHeight = document.body.clientHeight;

            // Ignore scroll events caused by content height change
            if (contentHeight !== lastContentHeight) {
                lastScrollY = scrollY;
                lastContentHeight = contentHeight;
                return;
            }

            const direction = scrollY > lastScrollY ? ScrollDirection.Down : ScrollDirection.Up;
            if (direction !== scrollDirection && (scrollY - lastScrollY > 1 || scrollY - lastScrollY < -1)) {
                setScrollDirection(direction);
            }
            lastScrollY = scrollY > 0 ? scrollY : 0;
            lastContentHeight = contentHeight;
        };

        window.addEventListener("scroll", updateScrollDirection); // add event listener

        return () => {
            window.removeEventListener("scroll", updateScrollDirection); // clean up
        };
    }, [scrollDirection]);

    return scrollDirection;
}
