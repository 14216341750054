import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import cartReducer from "./cartSlice";
import uiReducer from "./uiSlice";
import { ecosternApi } from "api";

const rootReducer = combineReducers({
    user: userReducer,
    cart: cartReducer,
    ui: uiReducer,
    [ecosternApi.reducerPath]: ecosternApi.reducer,
});

export default rootReducer;
