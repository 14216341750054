import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useState, useEffect, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { LoadingWrapper } from "../components/layout/loading-wrapper";
import { PageTitleBlock } from "../components/layout/page-title-block";
import { getPageItems } from "../components/pagination/helpers";
import { PageBottomPagination } from "../components/pagination/page-bottom-pagination";
import { ProductImage } from "../components/ui/images/product-image/product-image";
import { TableHeaderRow } from "../components/ui/table/table-header-row";
import { TableRegularRow } from "../components/ui/table/table-regular-row";
import { IAppliance } from "../models/api/IAppliance";
import { IPageableState } from "../models/content/pagination/IPageableState";
import { useAppSelector } from "../store/hooks";
import { useGetAppliancesQuery } from "../api/ecosternApi";
import { selectCurrentClientId } from "../store/slices/userSlice";

export const DevicesView = () => {
    const selectedClientId = useAppSelector(selectCurrentClientId);
    const { data, isLoading, isError } = useGetAppliancesQuery(selectedClientId ?? skipToken);

    // Sum quantities and prices of appliances by id & price.
    const appliances = useMemo(() => {
        const result: { [key: string]: IAppliance } = {};

        data?.appliances.forEach((a) => {
            const key = `${a.id}-${a.price}`;

            if (!result[key]) {
                result[key] = { ...a, quantity: 0, price: 0 };
            }

            result[key].quantity += a.quantity;
            result[key].price += a.price;
        });

        return Object.values(result);
    }, [data?.appliances]);

    const [state, setState] = useState<IPageableState>({
        totalItems: 0,
        currentPage: 1,
        pageSize: 30,
    });

    useEffect(() => {
        let mounted = true;

        if (!isLoading && data && data.appliances && mounted) {
            setState({ ...state, totalItems: data.appliances.length });
        }

        return () => {
            mounted = false;
        };
    }, [isLoading]);

    const onPageClick = (pageNumber: number) => {
        setState({ ...state, currentPage: pageNumber });
    };

    return (
        <>
            <PageTitleBlock routeName={"appliances"} />
            <LoadingWrapper isLoading={isLoading} isError={isError}>
                <Container className={"pb-5 mb-2 mt-4 mb-md-4"}>
                    <Row>
                        <Col as={"section"}>
                            <Row className={"mx-n2"}>
                                <Col>
                                    <TableHeaderRow className={"d-none d-md-flex"}>
                                        <Col md={2}></Col>
                                        <Col md={4}>
                                            <FormattedMessage id={"appliances.name"} />
                                        </Col>
                                        <Col>
                                            <FormattedMessage id={"appliances.factoryNumber"} />
                                        </Col>
                                        <Col>
                                            <FormattedMessage id={"appliances.quantity"} />
                                        </Col>
                                        <Col>
                                            <FormattedMessage id={"appliances.price"} />
                                        </Col>
                                    </TableHeaderRow>
                                    {getPageItems(appliances, state.currentPage, state.pageSize).map((a: IAppliance) => (
                                        <TableRegularRow key={a.id}>
                                            <Col xs={12} md={6}>
                                                <Row className={"align-items-center"}>
                                                    <Col xs={4} md={3}>
                                                        <ProductImage id={a.id} item={a} size={"lg"} />
                                                    </Col>
                                                    <Col xs={8}>{a.name}</Col>
                                                </Row>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Row className={"mt-2 mt-md-0"}>
                                                    <Col>
                                                        <span className={"fw-bold d-block d-md-none"}>
                                                            <FormattedMessage id={"appliances.factoryNumber"} />
                                                        </span>
                                                        {a.factoryNumber}
                                                    </Col>
                                                    <Col>
                                                        <span className={"fw-bold d-block d-md-none"}>
                                                            <FormattedMessage id={"appliances.quantity"} />
                                                        </span>
                                                        {a.quantity}
                                                    </Col>
                                                    <Col>
                                                        <span className={"fw-bold d-block d-md-none"}>
                                                            <FormattedMessage id={"appliances.price"} />
                                                        </span>
                                                        <FormattedNumber value={a.price} style={"currency"} currency={"EUR"} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </TableRegularRow>
                                    ))}
                                    <hr className="my-3" />
                                    {data && state.totalItems > 0 && (
                                        <TableHeaderRow className={"justify-content-end"}>
                                            <Col xs={4} md={2}>
                                                <FormattedMessage id={"appliances.total"} />
                                            </Col>
                                            <Col xs={4} md={2}>
                                                {data?.totalQuantity || 0}
                                            </Col>
                                            <Col xs={4} md={2}>
                                                <FormattedNumber value={data?.totalPrice || 0} style={"currency"} currency={"EUR"} />
                                            </Col>
                                        </TableHeaderRow>
                                    )}
                                </Col>
                            </Row>
                            <hr className="my-3" />
                            <PageBottomPagination
                                totalItems={state.totalItems}
                                pageSize={state.pageSize}
                                currentPage={state.currentPage}
                                onPageClick={onPageClick}
                            />
                        </Col>
                    </Row>
                </Container>
            </LoadingWrapper>
        </>
    );
};
