import React, { useState } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

interface IProps {
    instructions: string;
    isVisible: boolean;
    onClose: () => void;
    onSave: (instructions: string) => void;
}

interface IShipmentInstructionsModalState {
    instructions: string;
    tel: string;
    code: string;
}

export const ShipmentInstructionsModal = (props: IProps) => {
    const telRegex = /^[+?3[0-9][0-9][\W]?]?([0-9]+)[\w-]?/g;
    const codeRegex = /^(\+?3[0-9][0-9]).*/g;
    const instructionsMaxLength = 90;
    const instructonsSplit = props.instructions.split("-");
    const telMatches = props.instructions.match(telRegex);
    const codeMatches = props.instructions.match(codeRegex);
    const [state, setState] = useState<IShipmentInstructionsModalState>({
        instructions: instructonsSplit[instructonsSplit.length - 1],
        tel: telMatches?.length ? telMatches[0] : "",
        code: codeMatches?.length ? codeMatches[0] : "+372",
    });

    const onSaveClick = () => {
        if (!state.tel) return;
        const newInstructions = getInstructions();
        props.onSave(newInstructions);
    };

    const getInstructions = (instructions?: string, tel?: string) => {
        const t = tel || state.tel ? `${state.code} ${tel || state.tel}` : "";
        return `${t}${instructions || state.instructions ? " - " : ""}${instructions || state.instructions}`;
    };

    const onInstructionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const instructions = event.currentTarget.value;
        if (getInstructions(instructions).length > instructionsMaxLength) return;
        setState({ ...state, instructions });
    };

    const onTelephoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const tel = event.currentTarget.value;
        setState({ ...state, tel });
    };

    const onDialingCodeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const code = event.currentTarget.value;
        setState({ ...state, code });
    };

    return (
        <Modal show={props.isVisible} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="order.shipmentInstructionsTitle" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <InputGroup className={"mb-3 has-validation"}>
                    <Form.Select onChange={onDialingCodeChange} className={"flex-grow-0 w-25"}>
                        <option value="+372" selected>
                            +372
                        </option>
                        <option value="+358">+358</option>
                    </Form.Select>
                    <Form.Control type={"tel"} pattern={"[0-9]+"} value={state.tel} isInvalid={!state.tel} onChange={onTelephoneChange} required></Form.Control>
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id={"order.addInstructionsContactNumber"} />
                    </Form.Control.Feedback>
                </InputGroup>

                <Form.Control as="textarea" style={{ height: "6rem" }} value={state.instructions} onChange={onInstructionsChange} />
                <div className={"d-flex justify-content-end"}>
                    <Form.Text muted className={"text-align-right"}>
                        {getInstructions().length - state.tel.length} / {instructionsMaxLength - state.tel.length}
                    </Form.Text>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={props.onClose}>
                    <FormattedMessage id={"order.close"} />
                </Button>
                <Button variant="primary" onClick={onSaveClick} disabled={getInstructions() === props.instructions}>
                    <FormattedMessage id={"order.saveInstructions"} />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
