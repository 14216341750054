import { Col, Row } from "react-bootstrap";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { ICartProductAdd } from "../../models/api/ICartProduct";
import { IOrderRow } from "../../models/api/IOrderRow";
import { useAppSelector } from "../../store/hooks";
import { selectCartProduct } from "../../store/slices/cartSlice";
import { useAddCartProductMutation } from "../../api/ecosternApi";
import { selectCurrentClientId } from "../../store/slices/userSlice";
import { getTotalSum } from "../../utils/product.utils";
import { LoadingSpinnerButton } from "../ui/buttons/loading-spinner-button";
import { CartzillaIcon, CartzillaIconName } from "../ui/icons/cartzilla-icon";
import { NoImage } from "../ui/images/no-image-placeholder/no-image";
import { ArchivedProductIndicator } from "../ui/images/product-image/archived-product-icon";
import { ProductImage } from "../ui/images/product-image/product-image";
import { TableRegularRow } from "../ui/table/table-regular-row";

interface IProps {
    row: IOrderRow;
}

export const OrderAccordionRow = ({ row }: IProps) => {
    const [postAddToCart] = useAddCartProductMutation();
    const currentClientId = useAppSelector(selectCurrentClientId);
    const existingCartProduct = useAppSelector(selectCartProduct(row.productId));
    const intl = useIntl();

    const isTransport = row.productId == -4;

    const onAddProductToCart = () => {
        if (!currentClientId) return;

        const newQuantity = row.quantity + (existingCartProduct?.quantity ?? 0);

        const cartProduct: ICartProductAdd = {
            productId: row.productId,
            quantity: newQuantity,
        };
        postAddToCart(cartProduct);
    };

    return (
        <TableRegularRow key={row.productId}>
            <Col md={5}>
                <Row className={"align-items-center"}>
                    <Col xs={4} md={3}>
                        {!isTransport ? <ProductImage id={row.productId} item={row.product} size={"lg"} /> : <NoImage size={"lg"} />}
                    </Col>
                    <Col xs={8} md={9} className={"d-flex flex-column"}>
                        <div>{row.product?.name}</div>
                        {(!row.product || (!row.product.isAllowedForWeb && !isTransport)) && (
                            <ArchivedProductIndicator message={intl.formatMessage({ id: "orderContents.productArchived" })} variant={"text"} />
                        )}
                    </Col>
                </Row>
            </Col>
            <Col md={6} className={"mt-3 mt-md-0"}>
                <Row className={"align-items-center"}>
                    <Col>
                        <div className={"fw-bold d-md-none"}>
                            <FormattedMessage id={"orderContents.code"} />
                        </div>
                        {row.product?.code || row.productId}
                    </Col>
                    <Col>
                        <div className={"fw-bold d-md-none"}>
                            <FormattedMessage id={"orderContents.quantity"} />
                        </div>
                        {row.quantity}
                    </Col>
                    <Col>
                        <div className={"fw-bold d-md-none"}>
                            <FormattedMessage id={"orderContents.price"} />
                        </div>
                        <FormattedNumber value={Math.round(row.price * 100) / 100} style={"currency"} currency={"EUR"} />
                    </Col>
                    <Col className={"d-none d-md-block"}>
                        <FormattedNumber value={getTotalSum(row.quantity, row.price)} style={"currency"} currency={"EUR"} />
                    </Col>
                </Row>
            </Col>
            <Col sm={12} md={1}>
                {!isTransport && (
                    <LoadingSpinnerButton
                        variant={"secondary"}
                        size={"sm"}
                        isLoading={false}
                        icon={<CartzillaIcon icon={CartzillaIconName.Cart} className={"me-md-n2"} />}
                        className={"border-0 mt-3 mt-md-0"}
                        onClick={onAddProductToCart}
                    >
                        <span className={"d-md-none"}>Lisa</span>
                    </LoadingSpinnerButton>
                )}
            </Col>
        </TableRegularRow>
    );
};
