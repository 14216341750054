import { CartzillaIcon, CartzillaIconName } from "components/ui/icons/cartzilla-icon";
import { ButtonGroup, Button, Col, Form, Row } from "react-bootstrap";
import {
    useGetTranslationCategoriesQuery,
    useGetTranslationMessagesQuery,
    useGetTranslationProductsQuery,
    useGetTranslationQuery,
    usePutTranslationMutation,
} from "api/adminApi";
import { ITranslationProduct } from "models/api/ITranslationProduct";
import { useEffect, useState } from "react";
import { LoadingWrapper } from "components/layout/loading-wrapper";
import { Locale } from "i18n";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ITranslation } from "models/api/ITranslation";
import { searchItems } from "utils/utils";
import { LoadingSpinnerButton } from "components/ui/buttons/loading-spinner-button";
import { InputSearch } from "components/ui/input/input-search";
import { SubTitleBlock } from "components/layout/sub-title-block";
import { CMSCard, CMSCardRow } from "components/cms-card";
import { ITranslationItem } from "models/api/ITranslationItem";
import { TranslationType } from "models/api/types/TranslationType";
import { ITranslationMessage } from "models/api/ITranslationMessage";

type Props = {
    type: TranslationType;
};

interface ISettingsTranslationsState {
    currentItem: ITranslationItem | undefined;
    templateTranslation: ITranslation | undefined;
    currentTranslation: ITranslation | undefined;
    viewItems: ITranslationItem[];
    viewLocale: Locale;
    searchQuery: string;
}

function isTranslationProduct(translation: ITranslationItem | ITranslationProduct): translation is ITranslationProduct {
    return (translation as ITranslationProduct).code !== undefined;
}

function isTranslationMessage(translation: ITranslationItem | ITranslationMessage): translation is ITranslationMessage {
    return (translation as ITranslationMessage).message !== undefined;
}

const searchProps = ["id", "name", "code", "info"] as const;
type SearchProps = typeof searchProps[number];

export const SettingsTranslations = ({ type }: Props) => {
    const usedSearchProps = [...searchProps];
    const getQuery = () => {
        switch (type) {
            case "PRODUCT":
                return useGetTranslationProductsQuery();
            case "CATEGORY":
                return useGetTranslationCategoriesQuery();
            case "MESSAGE":
                return useGetTranslationMessagesQuery();
            default:
                throw new Error(`Not supported translation type '${type}' found.`);
        }
    };

    const items = getQuery();

    const [state, setState] = useState<ISettingsTranslationsState>({
        currentItem: undefined,
        templateTranslation: undefined,
        currentTranslation: undefined,
        viewItems: [],
        viewLocale: "en-GB",
        searchQuery: "",
    });
    const translations = useGetTranslationQuery(state.currentItem ? { sourceRef: state.currentItem.id.toString(), type } : skipToken);
    const [updateTranslation, { isLoading: isUpdateLoading }] = usePutTranslationMutation();

    useEffect(() => {
        const newTranslation: ITranslation = {
            id: "",
            sourceRef: state.currentItem?.id.toString() ?? "",
            type: type,
            locale: state.viewLocale,
            fields: {},
        };
        const templateTranslation = translations.data?.find((t) => t.locale === state.viewLocale.replace("-", "_"));
        const currentTranslation = templateTranslation ?? newTranslation;

        setState({ ...state, currentTranslation, templateTranslation });
    }, [translations.data, state.viewLocale, state.currentItem]);

    useEffect(() => {
        const viewItems = searchItems<ITranslationItem, SearchProps>(usedSearchProps, items.data ?? [], state.searchQuery);

        setState({
            ...state,
            viewItems,
        });
    }, [items.data, state.searchQuery]);

    const onItemClick = (currentItem: ITranslationItem) => {
        setState({ ...state, currentItem, currentTranslation: undefined });
    };

    const onLocaleChange = (locale: Locale) => {
        setState({ ...state, viewLocale: locale, currentTranslation: undefined });
    };

    const onFieldChange = (field: keyof ITranslation["fields"], value: string) => {
        if (!state.currentTranslation) return;
        setState({
            ...state,
            currentTranslation: { ...state.currentTranslation, fields: { ...state.currentTranslation?.fields, [field]: value } },
        });
    };

    const onSearch = (searchQuery: string) => {
        setState({ ...state, searchQuery });
    };

    const onTranslationSave = () => {
        if (!state.currentTranslation) return;
        updateTranslation(state.currentTranslation);
    };

    const getTitle = () => {
        switch (type) {
            case "PRODUCT":
                return "Toodete tõlked";
            case "CATEGORY":
                return "Kategooriate tõlked";
            case "MESSAGE":
                return "Sõnumite tõlked";
            default:
                return "";
        }
    };

    return (
        <>
            <SubTitleBlock title={getTitle()} />
            <LoadingWrapper isLoading={items.isLoading} isError={items.isError}>
                <Row className={"d-flex flex-column flex-md-row gap-3 g-0"}>
                    <CMSCard
                        xs={12}
                        md={4}
                        header={
                            <>
                                <h5 className={"py-3 m-0"}>Tõlke alus</h5>
                                <InputSearch value={state.searchQuery} onChange={onSearch} onClear={() => onSearch("")} />
                            </>
                        }
                    >
                        <div className={"bg-secondary border-right flex-grow-1 h-100 overflow-scroll-y"}>
                            {state.viewItems.map((item) => (
                                <CMSCardRow
                                    key={item.id}
                                    className={state.currentItem?.id === item.id ? "border-primary bg-light" : "bg-secondary"}
                                    onClick={() => onItemClick(item)}
                                >
                                    <div className={`${state.currentItem?.id === item.id ? "fw-bold" : ""}`}>{item.name}</div>
                                    <div className={"fs-sm text-muted"}>kood: {isTranslationProduct(item) ? item.code : item.id}</div>
                                </CMSCardRow>
                            ))}
                        </div>
                    </CMSCard>
                    <CMSCard
                        xs={12}
                        md={7}
                        height={"auto"}
                        header={
                            <>
                                <h5 className={"m-0 py-3"}>Tõlked</h5>
                                <ButtonGroup aria-label="Basic example" size={"sm"}>
                                    <Button variant={state.viewLocale === "en-GB" ? "primary" : "outline-primary"} onClick={() => onLocaleChange("en-GB")}>
                                        eng
                                    </Button>
                                    <Button variant={state.viewLocale === "fi-FI" ? "primary" : "outline-primary"} onClick={() => onLocaleChange("fi-FI")}>
                                        fin
                                    </Button>
                                    <Button variant={state.viewLocale === "ru-RU" ? "primary" : "outline-primary"} onClick={() => onLocaleChange("ru-RU")}>
                                        rus
                                    </Button>
                                </ButtonGroup>
                            </>
                        }
                    >
                        {state.currentItem ? (
                            <LoadingWrapper isLoading={translations.isLoading} isError={translations.isError}>
                                <div>
                                    <Row className={"d-flex align-items-stretch border-bottom cursor-pointer g-0 fs-sm fw-bold py-1"}>
                                        <Col className={"ps-3"}>Alustekst</Col>
                                    </Row>
                                    <div className={"p-3 d-flex flex-column gap-3 bg-secondary"}>
                                        {!isTranslationMessage(state.currentItem) ? (
                                            <Row>
                                                <Form.Group as={Col} controlId="productName">
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter first name" value={state.currentItem?.name} disabled />
                                                </Form.Group>
                                            </Row>
                                        ) : (
                                            <Row>
                                                <Form.Group as={Col} controlId="message">
                                                    <Form.Label>Message</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        placeholder="Enter message"
                                                        value={state.currentItem?.message}
                                                        rows={3}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </Row>
                                        )}
                                        {isTranslationProduct(state.currentItem) && (
                                            <Row>
                                                <Form.Group as={Col} controlId="info">
                                                    <Form.Label>Info</Form.Label>
                                                    <Form.Control as="textarea" placeholder="Info..." value={state.currentItem?.info} rows={4} disabled />
                                                </Form.Group>
                                            </Row>
                                        )}
                                    </div>
                                    <Row className={"d-flex align-items-stretch border-bottom border-top cursor-pointer g-0 fs-sm fw-bold py-1"}>
                                        <Col className={"ps-3"}>Tõlge</Col>
                                    </Row>
                                    <div className={"p-3 d-flex flex-column gap-3"}>
                                        <Row>
                                            {!isTranslationMessage(state.currentItem) ? (
                                                <Form.Group as={Col} controlId="translationName">
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter name..."
                                                        value={state.currentTranslation?.fields.name ?? ""}
                                                        onChange={(e) => onFieldChange("name", e.target.value)}
                                                    />
                                                </Form.Group>
                                            ) : (
                                                <Form.Group as={Col} controlId="translationMessage">
                                                    <Form.Label>Message</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        placeholder="Enter message..."
                                                        value={state.currentTranslation?.fields.message ?? ""}
                                                        onChange={(e) => onFieldChange("message", e.target.value)}
                                                    />
                                                </Form.Group>
                                            )}
                                        </Row>
                                        {isTranslationProduct(state.currentItem) && (
                                            <Row>
                                                <Form.Group as={Col} controlId="translationInfo">
                                                    <Form.Label>Info</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        placeholder="Info..."
                                                        value={state.currentTranslation?.fields.info ?? ""}
                                                        onChange={(e) => onFieldChange("info", e.target.value)}
                                                        rows={4}
                                                    />
                                                </Form.Group>
                                            </Row>
                                        )}
                                    </div>
                                    <div className={"d-flex justify-content-end gap-2 p-3"}>
                                        <LoadingSpinnerButton
                                            size={"sm"}
                                            icon={<CartzillaIcon icon={CartzillaIconName.AddDocument} className={"me-2"} />}
                                            isLoading={isUpdateLoading}
                                            onClick={onTranslationSave}
                                            disabled={JSON.stringify(state.templateTranslation?.fields) === JSON.stringify(state.currentTranslation?.fields)}
                                        >
                                            Save changes
                                        </LoadingSpinnerButton>
                                    </div>
                                </div>
                            </LoadingWrapper>
                        ) : (
                            <div className={"p-3 d-flex flex-column justify-content-center align-items-center flex-grow-1"}>
                                Palun vali {type === "PRODUCT" ? "toode" : "kategooria"}
                            </div>
                        )}
                    </CMSCard>
                </Row>
            </LoadingWrapper>
        </>
    );
};
