import { Dropdown } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectUserLocale, setUserLocale } from "../../../store/slices/userSlice";


export const LanguageSelector = () => {
    const dispatch = useAppDispatch();
    const userLocale = useAppSelector(selectUserLocale);

    return (
        <Dropdown as={'div'} className={"topbar-text"}>
            <Dropdown.Toggle as={'a'} className={"topbar-link"}>
                <FormattedMessage id={`lang.${userLocale}`}/>
            </Dropdown.Toggle>
            <Dropdown.Menu renderOnMount={true}>
                <Dropdown.Item onClick={() => dispatch(setUserLocale('et-EE'))}>
                    <FormattedMessage id={"lang.et-EE"}/>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => dispatch(setUserLocale('ru-RU'))}>
                    <FormattedMessage id={"lang.ru-RU"}/>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => dispatch(setUserLocale('fi-FI'))}>
                    <FormattedMessage id={"lang.fi-FI"}/>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => dispatch(setUserLocale('en-GB'))}>
                    <FormattedMessage id={"lang.en-GB"}/>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}