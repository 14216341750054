import { Outlet } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { selectUserHasBackOfficeAccess } from "store/slices/userSlice";
import { Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export const BackOfficeRoutes = () => {
    const userHasBackOfficeAccess = useAppSelector(selectUserHasBackOfficeAccess);

    if (!userHasBackOfficeAccess) {
        return (
            <Container as={"section"} className={"p-5 d-flex flex-column justify-content-center flex-grow-1 align-items-center"}>
                <FormattedMessage id={"nav.unauthorized"} />
            </Container>
        );
    } else {
        return <Outlet />;
    }
};
