import { CartzillaIcon, CartzillaIconName } from "../ui/icons/cartzilla-icon";
import { IPaginationProps, onNextPageClick, onPreviousPageClick } from "./helpers";


export const PageHeaderPagination = (props: IPaginationProps) => {
    const maxPage = Math.floor(props.totalItems / props.pageSize) + (props.totalItems % props.pageSize !== 0 ? 1 : 0);
    return (
        <div className="d-flex text-nowrap">
            <span className="nav-link-style nav-link-light me-2 cursor-pointer" onClick={() => onPreviousPageClick(props.currentPage, props.onPageClick)}>
                <CartzillaIcon icon={CartzillaIconName.ArrowLeft}/>
            </span>
            <span className="fs-md text-light">{props.currentPage} / { maxPage }</span>
            <span className="nav-link-style nav-link-light ms-2 cursor-pointer"
                    onClick={() => onNextPageClick(props.currentPage, props.totalItems, props.pageSize, props.onPageClick)}>
                <CartzillaIcon icon={CartzillaIconName.ArrowRight}/>
            </span>
        </div>
    );
}