import { useDeleteCampaignImageMutation } from "api/adminApi";
import { CartzillaIcon, CartzillaIconName } from "components/ui/icons/cartzilla-icon";
import { ProductImage } from "components/ui/images/product-image/product-image";
import { FileDropzone } from "components/ui/input";
import { useFileUploadMutation } from "hooks/use-file-upload-mutation";
import { ICampaign } from "models/api/ICampaign";
import { CampaignImageType } from "models/api/types/CampaignType";
import { Row, Col, Badge, ProgressBar } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

type Props = {
    locale: string;
    campaign: ICampaign;
    onChange?: () => void;
};
export const CMSCampaignImages = ({ locale, campaign, onChange }: Props) => {
    const [deleteCampaignImage] = useDeleteCampaignImageMutation();
    const [uploadImage, addCampaignImageData] = useFileUploadMutation(`campaigns/${campaign.id}/images`);

    const _locale = locale.replace("-", "_");
    const desktopImage = campaign.images.find((i) => i.type === "DESKTOP" && i.locale === _locale);
    const mobileImage = campaign.images.find((i) => i.type === "MOBILE" && i.locale === _locale);

    const onFileDrop = async (type: CampaignImageType, files: File[]) => {
        const data = new FormData();
        data.append("file", files[0]);
        data.append("type", type);
        data.append("locale", locale);
        await uploadImage(data);
        setTimeout(() => onChange?.(), 500);
    };

    const onDeleteImage = async (imageId: string) => {
        await deleteCampaignImage({ campaignId: campaign.id, imageId });
    };

    const deleteButton = (imageId: string) => {
        return (
            <span
                className={"icon border rounded text-muted fs-xs d-flex justify-content-center align-items-center bg-light cursor-pointer"}
                onClick={() => onDeleteImage(imageId)}
            >
                <CartzillaIcon icon={CartzillaIconName.Close} />
            </span>
        );
    };

    return (
        <>
            <Row className={"border-top border-bottom bg-light"}>
                <Col xs={12}>
                    <FormattedMessage id={`lang.${locale}`} />
                </Col>
            </Row>
            <Row className={"flex-grow-1 g-0 gap-3"}>
                <Col xs={12} md={7} className={"position-relative flex-grow-1"}>
                    <div className={"position-absolute d-flex w-100 p-2 justify-content-between z-1"}>
                        <Badge>Desktop</Badge>
                        {!!desktopImage && deleteButton(desktopImage.fileId)}
                    </div>
                    {desktopImage ? (
                        <ProductImage id={desktopImage.fileId} size={"auto"} path={"public/files"} />
                    ) : addCampaignImageData.isLoading ? (
                        <div className={"d-flex justify-content-center align-items-center h-100 w-100"}>
                            <ProgressBar now={addCampaignImageData.progress} label={`${addCampaignImageData.progress.toFixed(2)}%`} className={"w-50"} />
                        </div>
                    ) : (
                        <FileDropzone onDrop={(acceptedFiles: File[]) => onFileDrop("DESKTOP", acceptedFiles)} />
                    )}
                </Col>

                <Col xs={12} md={4} className={"position-relative"}>
                    <div className={"position-absolute d-flex w-100 p-2 justify-content-between z-1"}>
                        <Badge>Mobile</Badge>
                        {!!mobileImage && deleteButton(mobileImage.fileId)}
                    </div>
                    {mobileImage ? (
                        <ProductImage id={mobileImage.fileId} size={"auto"} path={"public/files"} />
                    ) : (
                        <FileDropzone onDrop={(acceptedFiles: File[]) => onFileDrop("MOBILE", acceptedFiles)} />
                    )}
                </Col>
            </Row>
        </>
    );
};
