import { IClient } from "models/api/IClient";
import { useEffect, useState } from "react";
import { Container, Row, Col, NavLink, Button, Navbar } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { routes } from "router";
import { useGetClientsQuery } from "api/ecosternApi";
import { PageTitleBlock } from "../components/layout/page-title-block";
import { CartzillaIcon, CartzillaIconName } from "../components/ui/icons/cartzilla-icon";

interface IAccountViewState {
    clients: IClient[];
}

export const SettingsView = () => {
    const { data = [], isSuccess } = useGetClientsQuery();
    const [state, setState] = useState<IAccountViewState>({
        clients: [],
    });

    useEffect(() => {
        if (data.length && isSuccess) {
            const clients = [...data].sort(() => 0.5 - Math.random()).slice(0, 10);
            setState({ ...state, clients });
        }
    }, [isSuccess]);

    return (
        <>
            <PageTitleBlock routeName={"settings"} hasOverlap={true} />
            <Container className={"pb-5 mb-2 mb-md-4"}>
                <Row>
                    <Col lg={3} as={"aside"} className={"pt-4 pt-lg-0 pe-xl-5"}>
                        <Navbar expand={"lg"} collapseOnSelect className={"bg-white w-100 rounded-3 shadow-lg pt-1 mb-5 mb-lg-0 flex-lg-column"}>
                            <div className={"d-md-flex justify-content-between align-items-center text-center text-md-start p-4 w-100"}>
                                <div className={"d-md-flex align-items-center"}>
                                    <h3 className={"fs-base mb-0"}>Haldus</h3>
                                </div>
                                <Navbar.Toggle
                                    as={Button}
                                    variant={"primary"}
                                    aria-controls={"account-menu"}
                                    className={"d-lg-none mb-2 mt-3 mt-md-0"}
                                    bsPrefix={"ecostern"}
                                >
                                    <CartzillaIcon icon={CartzillaIconName.Menu} className={"me-2"} />
                                    Kasutaja menüü
                                </Navbar.Toggle>
                            </div>
                            <Navbar.Collapse className={"w-100"}>
                                <div className={"flex-column w-100"}>
                                    <div className={"bg-secondary px-4 py-3"}>Administraator</div>
                                    <ul className={"list-unstyled mb-0"}>
                                        <li className={"border-bottom mb-0"}>
                                            <NavLink href={routes.settingsUsers.path}>
                                                <CartzillaIcon icon={CartzillaIconName.User} className={"me-2 active opacity-60"} />
                                                Rakenduse kasutajad
                                            </NavLink>
                                        </li>
                                    </ul>
                                    <div className={"bg-secondary px-4 py-3"}>Kliendid</div>
                                    <ul className={"list-unstyled mb-0"}>
                                        <li className={"border-bottom mb-0"}>
                                            <NavLink href={routes.settingsUserClients.path}>
                                                <CartzillaIcon icon={CartzillaIconName.User} className={"me-2 active opacity-60"} />
                                                Kasutaja kliendid
                                            </NavLink>
                                        </li>
                                        {/* <li className={"border-bottom mb-0"}>
                                            <NavLink href={"#"}>
                                                <CartzillaIcon icon={CartzillaIconName.Location} className={"me-2 active opacity-60"} />
                                                Kliendid (placeholder)
                                            </NavLink>
                                        </li> */}
                                    </ul>
                                    {/* <div className={"bg-secondary px-4 py-3"}>Kasutaja</div>
                                    <ul className={"list-unstyled mb-0"}>
                                        <li className={"mb-0"}>
                                            <NavLink href={routes.settingsMyAccount.path}>
                                                <CartzillaIcon icon={CartzillaIconName.User} className={"me-2 active opacity-60"} />
                                                Andmed
                                            </NavLink>
                                        </li>
                                    </ul> */}
                                    <div className={"bg-secondary px-4 py-3"}>Tõlked</div>
                                    <ul className={"list-unstyled mb-0"}>
                                        <li className={"mb-0"}>
                                            <NavLink href={routes.settingsProductTranslations.path}>
                                                <CartzillaIcon icon={CartzillaIconName.Book} className={"me-2 active opacity-60"} />
                                                Tooted
                                            </NavLink>
                                        </li>
                                        <li className={"mb-0"}>
                                            <NavLink href={routes.settingsCategoryTranslations.path}>
                                                <CartzillaIcon icon={CartzillaIconName.Book} className={"me-2 active opacity-60"} />
                                                Kategooriad
                                            </NavLink>
                                        </li>
                                        <li className={"mb-0"}>
                                            <NavLink href={routes.settingsMessageTranslations.path}>
                                                <CartzillaIcon icon={CartzillaIconName.Book} className={"me-2 active opacity-60"} />
                                                Sõnumid
                                            </NavLink>
                                        </li>
                                    </ul>
                                    <div className={"bg-secondary px-4 py-3"}>Sisuhaldus</div>
                                    <ul className={"list-unstyled mb-0"}>
                                        <li className={"mb-0"}>
                                            <NavLink href={routes.settingsCampaigns.path}>
                                                <CartzillaIcon icon={CartzillaIconName.List} className={"me-2 active opacity-60"} />
                                                Kampaaniad
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </Navbar.Collapse>
                        </Navbar>
                    </Col>
                    <Col lg={9} as={"section"}>
                        <Outlet />
                    </Col>
                </Row>
            </Container>
        </>
    );
};
