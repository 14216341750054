import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import { RouteInfo, RouteName, routes } from "../../router";
import { CartzillaIcon, CartzillaIconName } from "../ui/icons/cartzilla-icon";

interface IProps {
    routeName: RouteName;
}

export const Breadcrumbs = ({ routeName }: IProps) => {
    const location = useLocation();

    const getParentRoutes = (routeName: RouteName, parentRoutes: RouteInfo[] = []) => {
        const currentRoute = routes[routeName];

        if (currentRoute.isRoot) return [];

        let allRoutes: RouteInfo[] = [currentRoute].concat(parentRoutes);

        if (currentRoute.parent) {
            allRoutes = getParentRoutes(currentRoute.parent, allRoutes);
        }

        return allRoutes;
    };

    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                <>
                    <li className="breadcrumb-item">
                        <Link to={routes.home.path} className="text-nowrap">
                            <CartzillaIcon icon={CartzillaIconName.Home} />
                            <FormattedMessage id={routes.home.labelKey} />
                        </Link>
                    </li>
                    {getParentRoutes(routeName).map((r) => (
                        <li key={r.path} className={`breadcrumb-item text-nowrap${r.path === location.pathname ? " active" : ""}`} aria-current="page">
                            {r.path !== location.pathname ? (
                                <Link to={r.path}>
                                    <FormattedMessage id={r.labelKey} />
                                </Link>
                            ) : (
                                <FormattedMessage id={r.labelKey} />
                            )}
                        </li>
                    ))}
                </>
            </ol>
        </nav>
    );
};
