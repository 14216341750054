export interface IPaginationProps {
    totalItems: number;
    pageSize: number;
    currentPage: number;
    isVisible?: boolean;
    onPageClick: (index: number) => void;
}

export const onNextPageClick = (currentPage: number, totalItems: number, pageSize: number, onPage: (pageNumber: number) => void) => {
    const maxPage = Math.floor(totalItems / pageSize) + (totalItems % pageSize !== 0 ? 1 : 0);
    const newPageNumber = currentPage + 1;
    if (newPageNumber > maxPage) return;

    onPage(newPageNumber);
};

export const onPreviousPageClick = (currentPage: number, onPage: (pageNumber: number) => void) => {
    const newPageNumber = currentPage - 1;
    if (newPageNumber < 1) return;

    onPage(newPageNumber);
};

export const onSelectPageClick = (newPageNumber: number, currentPage: number, totalItems: number, pageSize: number, onPage: (pageNumber: number) => void) => {
    const maxPage = Math.floor(totalItems / pageSize) + (totalItems % pageSize !== 0 ? 1 : 0);
    if (newPageNumber > maxPage) return;
    if (newPageNumber < 1) return;

    onPage(newPageNumber);
};

export const getPageItems = <T>(items: T[], currentPage: number, pageSize: number) => {
    return items.slice((currentPage - 1) * pageSize, currentPage * pageSize);
};
