import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBaseCategory } from "../../models/api/ICategory";
import { IProduct, IStoreProduct } from "../../models/api/IProduct";
import { isProductModalInput, ProductModalInput, ProductModalItem } from "../../types/ProductModalItem";
import { RootState } from "../index";
import { logIn } from "./userSlice";
import { Toast } from "models/content/toast";

interface IUIState {
    isMenuOpen: boolean;
    isStoreFiltersOpen: boolean;
    filterCategory: IBaseCategory | null;
    isOnline: boolean;
    productModalItem: ProductModalItem | null;
    isLoginModalOpen: boolean;
    isHandheldToolbarVisible: boolean;
    toasts: Toast[];
}

const initialState: IUIState = {
    isMenuOpen: false,
    isStoreFiltersOpen: false,
    filterCategory: null,
    isOnline: true,
    productModalItem: null,
    isLoginModalOpen: false,
    isHandheldToolbarVisible: false,
    toasts: [],
};

const uiSlice = createSlice({
    name: "ecostern/ui",
    initialState,
    reducers: {
        toggleMenu: (state: IUIState) => {
            state.isMenuOpen = !state.isMenuOpen;
        },
        toggleStoreFilters: (state: IUIState) => {
            state.isStoreFiltersOpen = !state.isStoreFiltersOpen;
        },
        toggleFilterCategory: (state: IUIState, action: PayloadAction<IBaseCategory | null | undefined>) => {
            state.filterCategory = action.payload ?? null;
        },
        setOnlineStatus: (state: IUIState, action: PayloadAction<boolean>) => {
            state.isOnline = action.payload;
        },
        toggleProductModal: (state: IUIState, action: PayloadAction<ProductModalInput | IStoreProduct | IProduct | undefined>) => {
            const payload = action.payload;

            if (payload && isProductModalInput(payload)) {
                const products = payload.product ? [payload.product] : [];
                state.productModalItem = {
                    message: payload.message,
                    products,
                };
            } else if (payload) {
                const prevProducts = state.productModalItem?.products || [];
                state.productModalItem = {
                    products: [payload, ...prevProducts],
                };
            } else {
                state.productModalItem = null;
            }
        },
        prevModalProduct: (state: IUIState) => {
            if (state.productModalItem && Array.isArray(state.productModalItem.products)) {
                state.productModalItem.products = state.productModalItem.products.slice(1);
            }
        },
        toggleLoginModal: (state: IUIState) => {
            state.isLoginModalOpen = !state.isLoginModalOpen;
        },
        setIsHandheldToolbarVisible: (state: IUIState, action: PayloadAction<boolean>) => {
            state.isHandheldToolbarVisible = action.payload;
        },
        addToast: (state, action: PayloadAction<Toast>) => {
            const { text, type } = action.payload;

            if (state.toasts.filter((t) => t.text === text && t.type === type).length) {
                return;
            }

            state.toasts = [...state.toasts, action.payload];
        },
        removeToast: (state, action: PayloadAction<Toast>) => {
            state.toasts = state.toasts.filter((toast) => toast.id !== action.payload.id);
        },
        clearToasts: (state) => {
            state.toasts = [];
        },
    },
    extraReducers(builder) {
        builder.addCase(logIn, (state) => {
            state.isLoginModalOpen = false;
        });
    },
});

export const {
    toggleMenu,
    toggleStoreFilters,
    toggleFilterCategory,
    setOnlineStatus,
    toggleProductModal,
    prevModalProduct,
    toggleLoginModal,
    setIsHandheldToolbarVisible,
    addToast,
    removeToast,
    clearToasts,
} = uiSlice.actions;

export const selectIsMenuOpen = (state: RootState) => state.ui.isMenuOpen;
export const selectIsStoreFiltersOpen = (state: RootState) => state.ui.isStoreFiltersOpen;
export const selectHasFilterCategory = (state: RootState) => Boolean(state.ui.filterCategory);
export const selectIsOnline = (state: RootState) => state.ui.isOnline;
export const selectProductModalIsOpen = (state: RootState) => !!state.ui.productModalItem;
export const selectProductModalMessage = (state: RootState) => state.ui.productModalItem?.message;
export const selectProductModalProduct = (state: RootState) =>
    state.ui.productModalItem && state.ui.productModalItem.products.length > 0 ? state.ui.productModalItem.products[0] : null;
export const selectProductModalPrevProduct = (state: RootState) =>
    state.ui.productModalItem && state.ui.productModalItem.products.length > 1 ? state.ui.productModalItem.products[1] : null;
export const selectIsLoginModalOpen = (state: RootState) => state.ui.isLoginModalOpen;
export const selectIsHandheldToolbarVisible = (state: RootState) => state.ui.isHandheldToolbarVisible;
export const selectToasts = (state: RootState) => state.ui.toasts;

export default uiSlice.reducer;
