import Fuse from "fuse.js";

const defaultFuseOptions = {
    // isCaseSensitive: false,
    // includeScore: true,
    // shouldSort: true,
    // includeMatches: true,
    // findAllMatches: false,
    // minMatchCharLength: 1,
    // location: 0,
    threshold: 0.15,
    // distance: 100,
    useExtendedSearch: true,
    ignoreLocation: true,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: ["name"],
};

export const searchItems = <T, K extends string = "">(
    searchProps: (keyof T | K | { name: keyof T; weight: number })[],
    items: T[],
    query: string,
    inputOptions?: Exclude<Fuse.IFuseOptions<T>, "keys">
): T[] => {
    if (!query) return items;

    const options = {
        ...defaultFuseOptions,
        ...inputOptions,
        keys: searchProps.map((p) => p.toString()),
    };

    const fuse = new Fuse(items, options);
    const res = fuse.search<T>(query);

    // console.log({items, options, res});
    return res.map((fs) => fs.item);
};

export function debounce<F extends (...args: Parameters<F>) => ReturnType<F>>(func: F, waitFor: number) {
    let timeout: NodeJS.Timeout;
    const debounced = (...args: Parameters<F>): void => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), waitFor);
    };

    debounced.cancel = (): void => clearTimeout(timeout);
    return debounced;
}
