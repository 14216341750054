type Props = {
    className?: string;
    children?: React.ReactNode;
} & React.ComponentProps<"div">;

export const CMSCardRow = ({ className, children, ...props }: Props) => {
    return (
        <div className={`p-2 border-bottom cursor-pointer ${className}`} {...props}>
            {children}
        </div>
    );
};
