import { useGetAllUsersQuery, usePutUserMutation } from "api/adminApi";
import { LoadingWrapper } from "components/layout/loading-wrapper";
import { CartzillaIcon, CartzillaIconName } from "components/ui/icons/cartzilla-icon";
import { IUserWithAppRole } from "models/api/IUserWithAppRole";
import { Col, Form, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { LoadingSpinnerButton } from "components/ui/buttons/loading-spinner-button";
import { APPLICATION_ROLES } from "models/api/types/ApplicationRole";

export const SettingsEditUser = () => {
    const intl = useIntl();
    const { id } = useParams();
    const users = useGetAllUsersQuery();
    const user = users.data?.find((u) => u.webId === id);
    const [updateUser, { isError: isUpdateError, error: updateError }] = usePutUserMutation();

    const initialState: IUserWithAppRole = {
        id: 0,
        webId: "",
        fullName: "",
        username: "",
        email: "",
        phone: "",
        applicationRole: "REGULAR_USER",
        lastLoginAt: null,
        enabled: true,
    };

    const validationSchema = yup.object({
        fullName: yup.string().required("Full name is required"),
        username: yup.string().required("Username is required"),
        email: yup.string().email().nullable(),
        phone: yup
            .string()
            .matches(/^[+?3[0-9][0-9][\W]?]?([0-9]+)[\w-]?/g, "Phone number is not valid")
            .nullable(),
        applicationRole: yup.string().oneOf(APPLICATION_ROLES).required("Role is required"),
        enabled: yup.boolean().required(),
    });

    const formik = useFormik<IUserWithAppRole>({
        initialValues: user ?? initialState,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            await updateUser(values);
        },
    });

    return (
        <>
            <div className="d-none d-lg-flex justify-content-between align-items-center pt-lg-3 pb-4 pb-lg-5 mb-lg-3">
                <h4 className="fs-base text-light mb-0">Kasutaja andmed</h4>
            </div>
            <LoadingWrapper isLoading={users.isLoading} isError={users.isError || !user} errorMsg={!user && id !== "new" ? "Couldn't find user" : undefined}>
                <Form onSubmit={formik.handleSubmit}>
                    <Row className={"gx-4 gy-3 my-2"}>
                        <Form.Group as={Col} sm={12}>
                            <Form.Label>Fullname</Form.Label>
                            <Form.Control
                                name="fullName"
                                type="text"
                                placeholder="Enter first name"
                                value={formik.values.fullName}
                                onChange={formik.handleChange}
                                // Touched is set by onBlur.
                                // https://github.com/jaredpalmer/formik/pull/737#issuecomment-402869535
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.fullName && !!formik.errors.fullName}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.fullName}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className={"gx-4 gy-3 my-2"}>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                name="username"
                                type="text"
                                placeholder="Enter last name"
                                value={formik.values.username}
                                disabled
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.username && !!formik.errors.username}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.username}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                name="email"
                                placeholder="Enter email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.email && !!formik.errors.email}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className={"gx-4 gy-3 my-2"}>
                        <Form.Group as={Col} sm={6}>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                name={"phone"}
                                placeholder="Enter number"
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.phone && !!formik.errors.phone}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.phone}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} sm={3}>
                            <Form.Label>User role</Form.Label>
                            <Form.Select
                                aria-label="Kasutaja"
                                name={"applicationRole"}
                                value={formik.values.applicationRole}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.applicationRole && !!formik.errors.applicationRole}
                            >
                                {Object.entries(APPLICATION_ROLES).map(([, role]) => (
                                    <option key={role} value={role}>
                                        {intl.formatMessage({ id: `user.role.${role.toLowerCase()}` })}
                                    </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formik.errors.applicationRole}</Form.Control.Feedback>
                        </Form.Group>
                        {/* <Form.Group as={Col} xs={3}>
                            <Form.Label>Staatus</Form.Label>
                            <Form.Check
                                className={"mt-2"}
                                name="enabled"
                                label="Aktiivne"
                                checked={formik.values.enabled}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.enabled && !!formik.errors.enabled}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.enabled}</Form.Control.Feedback>
                        </Form.Group> */}
                    </Row>
                    <div className={"d-flex flex-column justify-content-end gap-2 mt-4 align-items-end"}>
                        <div className={"d-flex gap-3"}>
                            <LoadingSpinnerButton
                                size={"sm"}
                                type={"submit"}
                                isLoading={formik.isSubmitting}
                                disabled={!(formik.isValid && formik.dirty)}
                                icon={<CartzillaIcon icon={CartzillaIconName.AddUser} className={"me-2"} />}
                            >
                                Save changes
                            </LoadingSpinnerButton>
                        </div>
                        <div>
                            {isUpdateError && (
                                <div className={"mt-3 text-primary d-flex align-items-center"}>
                                    <CartzillaIcon icon={CartzillaIconName.Announcement} className={"me-2 fs-base align-middle"} />
                                    <FormattedMessage
                                        id={updateError && "status" in updateError && updateError.status === 403 ? "nav.unauthorized" : "error.errorText"}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </Form>
            </LoadingWrapper>
        </>
    );
};
