import { Col } from "react-bootstrap";

type Props = {
    header?: React.ReactNode;
    children?: React.ReactNode;
    /** 60vh by default */
    height?: "auto" | "60vh";
} & React.ComponentProps<typeof Col>;

export const CMSCard = ({ header, children, height = "60vh", ...props }: Props) => {
    return (
        <Col xs={12} md={7} className={"d-flex flex-column rounded border flex-grow-1 overflow-hidden bg-light"} style={{ height }} {...props}>
            <div className={"px-3 border-bottom m-0 d-flex justify-content-between align-items-center gap-5"}>{header}</div>
            {children}
        </Col>
    );
};
