import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";
import { LoadingWrapper } from "../components/layout/loading-wrapper";
import { PageTitleBlock } from "../components/layout/page-title-block";
import { getPageItems } from "../components/pagination/helpers";
import { PageBottomPagination } from "../components/pagination/page-bottom-pagination";
import { TableHeaderRow } from "../components/ui/table/table-header-row";
import { TableRegularRow } from "../components/ui/table/table-regular-row";
import { IInvoice } from "../models/api/IInvoice";
import { IPageableState } from "../models/content/pagination/IPageableState";
import { useAppSelector } from "../store/hooks";
import { useGetPendingInvoicesQuery } from "../api/ecosternApi";
import { selectCurrentClientId } from "../store/slices/userSlice";
import { searchItems } from "../utils/utils";
import { useSearch } from "components/header/navigation/search-bar";
import { useAnalytics } from "hooks/use-analytics";

interface IInvoicesViewState extends IPageableState {
    data: IInvoice[];
}

export const InvoicesView = () => {
    const selectedClientId = useAppSelector(selectCurrentClientId);
    const { data: invoicesData = [], isLoading } = useGetPendingInvoicesQuery(selectedClientId ?? skipToken);
    const { query } = useSearch();
    const searchProps: (keyof IInvoice)[] = ["clientName", "invoiceNumber", "orderDate", "dueDate"];
    const { track } = useAnalytics();

    const [state, setState] = useState<IInvoicesViewState>({
        data: [],
        totalItems: 0,
        currentPage: 1,
        pageSize: 30,
    });

    useEffect(() => {
        const data = searchItems<IInvoice>(searchProps, invoicesData, query);
        const totalItems = data.length;

        setState({ ...state, data, totalItems, currentPage: 1 });
    }, [!isLoading, query]);

    useEffect(() => {
        if (!query) return;
        track("search", { props: { search_query: query } });
    }, []);

    const onPageClick = (pageNumber: number) => {
        setState({ ...state, currentPage: pageNumber });
    };

    return (
        <>
            <PageTitleBlock routeName={"invoices"} />
            <LoadingWrapper isLoading={isLoading}>
                <Container className={"pb-5 mb-2 mt-4 mb-md-4"}>
                    <Row>
                        <Col as={"section"}>
                            <Row className={"mx-n2"}>
                                <Col>
                                    <TableHeaderRow className={"d-none d-md-flex"}>
                                        <Col md={3}>
                                            <FormattedMessage id={"pendingInvoice.deliveredTo"} />
                                        </Col>
                                        <Col md={2}>
                                            <FormattedMessage id={"pendingInvoice.invoiceNumber"} />
                                        </Col>
                                        <Col md={2}>
                                            <FormattedMessage id={"pendingInvoice.orderDate"} />
                                        </Col>
                                        <Col md={2}>
                                            <FormattedMessage id={"pendingInvoice.dueDate"} />
                                        </Col>
                                        <Col md={1}>
                                            <FormattedMessage id={"pendingInvoice.paidAmount"} />
                                        </Col>
                                        <Col md={1}>
                                            <FormattedMessage id={"pendingInvoice.total"} />
                                        </Col>
                                        <Col md={1}>
                                            <FormattedMessage id={"pendingInvoice.amountDue"} />
                                        </Col>
                                    </TableHeaderRow>
                                    {getPageItems(state.data, state.currentPage, state.pageSize).map((i: IInvoice) => (
                                        <TableRegularRow key={i.documentCode}>
                                            <Col xs={12} md={5}>
                                                <Row>
                                                    <Col xs={8} md={7} className={"fw-bold"}>
                                                        {i.clientName}
                                                    </Col>
                                                    <Col xs={4} md={5} className={"fw-bold"}>
                                                        {i.invoiceNumber}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={12} md={4} className={"mt-2 mt-md-0"}>
                                                <Row className={"justify-content-start"}>
                                                    <Col xs={4} md={6}>
                                                        <span className={"fw-bold d-block d-md-none"}>
                                                            <FormattedMessage id={"pendingInvoice.orderDate"} />
                                                        </span>
                                                        <FormattedDate value={i.orderDate} year={"2-digit"} month={"2-digit"} day={"2-digit"} />
                                                    </Col>
                                                    <Col xs={4} md={6}>
                                                        <span className={"fw-bold d-block d-md-none"}>
                                                            <FormattedMessage id={"pendingInvoice.dueDate"} />
                                                        </span>
                                                        <FormattedDate value={i.dueDate} year={"2-digit"} month={"2-digit"} day={"2-digit"} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={12} md={3} className={"mt-2 mt-md-0"}>
                                                <Row>
                                                    <Col>
                                                        <span className={"fw-bold d-block d-md-none"}>
                                                            <FormattedMessage id={"pendingInvoice.paidAmount"} />
                                                        </span>
                                                        <FormattedNumber value={i.paidAmount} style={"currency"} currency={"EUR"} />
                                                    </Col>
                                                    <Col>
                                                        <span className={"fw-bold d-block d-md-none"}>
                                                            <FormattedMessage id={"pendingInvoice.total"} />
                                                        </span>
                                                        <FormattedNumber value={i.total} style={"currency"} currency={"EUR"} />
                                                    </Col>
                                                    <Col>
                                                        <span className={"fw-bold d-block d-md-none"}>
                                                            <FormattedMessage id={"pendingInvoice.amountDue"} />
                                                        </span>
                                                        <FormattedNumber value={i.total - i.paidAmount} style={"currency"} currency={"EUR"} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </TableRegularRow>
                                    ))}
                                    <hr className="my-3" />
                                    <PageBottomPagination
                                        totalItems={state.totalItems}
                                        pageSize={state.pageSize}
                                        currentPage={state.currentPage}
                                        onPageClick={onPageClick}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </LoadingWrapper>
        </>
    );
};
