import { Toast } from "models/content/toast";
import { useAppDispatch } from "store/hooks";
import { addToast, removeToast, clearToasts } from "store/slices/uiSlice";

const DELAY = 8000;
let toastId = 0;

export const useToast = () => {
    const dispatch = useAppDispatch();

    const add = (text: string, type: Toast["type"], title?: string) => {
        toastId = (toastId + 1) % Number.MAX_SAFE_INTEGER;

        const toast: Toast = {
            id: `toast-${toastId}`,
            title,
            text,
            type,
        };

        // Toasts are always added after render in case they need to be added after redirects etc
        window.setTimeout(() => {
            dispatch(addToast(toast));
        }, 0);

        window.setTimeout(() => {
            dispatch(removeToast(toast));
        }, DELAY);
    };
    const addError = (text: string) => {
        return add(text, "danger");
    };
    const remove = (toast: Toast) => {
        dispatch(removeToast(toast));
    };
    const clear = () => {
        dispatch(clearToasts());
    };
    return {
        addToast: add,
        addErrorToast: addError,
        removeToast: remove,
        clearToasts: clear,
    };
};
