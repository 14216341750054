import { CartzillaIcon, CartzillaIconName } from "components/ui/icons/cartzilla-icon";
import { Container, Row, Button, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { toggleLoginModal } from "../store/slices/uiSlice";
import { selectIsLoggedIn } from "../store/slices/userSlice";
import { ClientRoutes } from "components/authorization/client-routes";

export const HomeView = () => {
    const dispatch = useAppDispatch();
    const isLoggedIn = useAppSelector(selectIsLoggedIn);

    const getMessage = (id: string) => {
        return (
            <FormattedMessage
                id={id}
                values={{
                    u: (chunks) => <u>{chunks}</u>,
                    i: (chunks) => <i>{chunks}</i>,
                    icon: (chunks) => {
                        return <CartzillaIcon icon={CartzillaIconName[chunks[0] as keyof typeof CartzillaIconName]} />;
                    },
                }}
            />
        );
    };

    if (isLoggedIn) {
        return <ClientRoutes />;
    }

    return (
        <div>
            <Container as={"section"} className={"py-5"}>
                <Row className={"d-flex mt-4"}>
                    <Col sm={12} md={6}>
                        <h1>
                            <FormattedMessage id={"nav.home"} />
                        </h1>
                        <p>
                            Oleme klientide tarbeks loonud tellimiskeskuse, mida on lihtne kasutada. Töötab mobiilis kui ka arvutis. Ükski toode ei unune ega
                            jää tellimata – ja see kõik käigult.
                        </p>
                        <p>
                            NB: Sisenemiseks peate olema registreeritud kasutaja. Kasutajatunnuse ja parooli saamiseks palun pöörduge meie esindaja poole –
                            kontaktid leiate <a href={"http://ecostern.ee/vota-uhendust/"}>siit.</a>
                        </p>
                        <div className={"mt-4"}>
                            <Button variant={"primary"} onClick={() => dispatch(toggleLoginModal())}>
                                <FormattedMessage id={"nav.enter"} />
                            </Button>
                        </div>
                    </Col>
                    <Col sm={12} md={6} className={"my-5 my-md-0"}>
                        <h4>
                            <FormattedMessage id={"pwa.instructions.title"} />
                        </h4>
                        <Row>
                            <Col sm={12}>
                                <div className={"h6"}>
                                    <FormattedMessage id={"pwa.instructions.android.title"} />
                                </div>
                                <div>
                                    <div>{getMessage("pwa.instructions.android.step1")}</div>
                                    <div>{getMessage("pwa.instructions.android.step2")}</div>
                                    <div>{getMessage("pwa.instructions.android.step3")}</div>
                                    <div className={"my-2"}>{getMessage("pwa.instructions.android.alternative.title")}</div>
                                    <div>{getMessage("pwa.instructions.android.alternative.step1")}</div>
                                    <div>{getMessage("pwa.instructions.android.alternative.step2")}</div>
                                    <div>{getMessage("pwa.instructions.android.alternative.step3")}</div>
                                </div>
                            </Col>
                            <Col sm={12} className={"mt-3"}>
                                <div className={"h6"}>
                                    <FormattedMessage id={"pwa.instructions.ios.title"} />
                                </div>
                                <div>
                                    <div>{getMessage("pwa.instructions.ios.step1")}</div>
                                    <div>{getMessage("pwa.instructions.ios.step2")}</div>
                                    <div>{getMessage("pwa.instructions.ios.step3")}</div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
